import React, { Fragment, useEffect } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import queryString from 'query-string';

// components
import Layout from "./Layout";

// pages
import Error from "../pages/error";
import Login from "../pages/login";
import Login1 from "../pages/login";
import TrainerLogin from "../pages/logintrainer";
import AdminLogin from "../pages/loginadmin";
import Privacy from "../pages/privacypolicy";
import MeetingView from "../pages/MeetingView";
// context
import { useUserState } from "../context/UserContext";

export default function App() {
  // global
  var { isAuthenticated } = useUserState();

  return (
    <HashRouter>
      <Switch>
        <Fragment>
          {localStorage.getItem('access') == '1' && (

            <Route exact path="/" render={() => <Redirect to="app/student/dashboard" />} />

          )}

          {localStorage.getItem('access') == '2' && (

            <Route exact path="/" render={() => <Redirect to="/app/trainer/dashboard" />} />

          )}
          {/* <Route
                  exact
                  path="/app"
                  render={() => <Redirect to="/app/dashboard" />}
                /> */}
          <PrivateRoute path="/app/admin" component={Layout} />
          <PrivateRoute path="/app/student" component={Layout} />
          <PrivateRoute path="/app/trainer" component={Layout} />
          <Route exact path="/" component={Login} />
          <Route exact path="/e" component={Login1} />
          <Route exact path="/trainerLogin" component={TrainerLogin} />
          <Route exact path="/adminLogin" component={AdminLogin} />
          <Route exact path="/Privacypolicy" component={Privacy} />
          {/* <Route exact path="/MeetingView" component={MeetingView} /> */}

          <Route exact path="/share/student" component={MeetingView} />


          {/* <Route exact path="/share/student?trainer_id=34&meeting_id=47&occ_id=106" component={MeetingView} /> */}
          {/* https://acharya.heptotechnologies.org/share/meeting/student?trainer_id=34&meeting_id=47&occ_id=106 */}
          
          
          {/* <Route component={Error} /> */}
        </Fragment>
      </Switch>
    </HashRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}

        render={props =>
          isAuthenticated

            ? (
              <Redirect
                to={{
                  pathname: "/trainerLogin",
                }}
              />
            ) : (
              React.createElement(component, props)
            )
        }
      />
    );
  }
}
