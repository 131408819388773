import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import FormData from "form-data";
import configurl from "../../config1.json";
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import Countdown, {
  zeroPad,
  calcTimeDelta,
  formatTimeDelta,
} from "react-countdown";

const useStyles1 = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  follow: {
    borderRadius: 50,
    marginTop: theme.spacing(1),
    padding: theme.spacing(0, 2),
  },
  entrolled: {
    borderRadius: 50,
    // marginTop:theme.spacing(1),
    backgroundColor: "#6bbb4c",
    color: "white",
    padding: theme.spacing(0, 2),
    fontSize: "11px",
  },
  join: {
    borderRadius: 50,
    // marginTop:theme.spacing(3),
    backgroundColor: "#6bbb4c",
    color: "white",
    padding: theme.spacing(0, 2),
  },
  last_session: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },
  text_color: {
    color: "#3B5FB7",
  },
  date: {
    color: "#3B5FB7",
    fontSize: "12px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  const baseUrl = configurl.baseUrl[0];
  const [open, setOpen] = React.useState(false);
  const [userID, setUserId] = React.useState([]);
  const [endtime, setEndtime] = React.useState("");
  const [time_count, setTime_count] = React.useState("");
  const [hours, setHours] = React.useState("");
  const [min, setMin] = React.useState("");
  const [seconds, setSeconds] = React.useState("");

  const [Showfollow, setShowFollow] = React.useState(true);


  var lastsession = moment(userID.start_time).format("LTS");

  var classes = useStyles();
  var classes = useStyles1();

  useEffect(() => {
    //setOpen(true);
    setUserId(props.userid);

    datefcn();

  }, []);

  const datefcn = () => {
    var date = time_count + 10000;

    var startdate = moment(userID.occ_start_time).format("yyyy-MM-DD");

    var enddate = moment(startdate + "T" + userID.end_time).format("hh:mm a");

    var time_count = moment(startdate + "T" + userID.end_time).format(
      "hh:mm:ss",
    );

    setEndtime(enddate);

    var starthms = moment(userID.occ_start_time).format("hh:mm:ss");

    var starthour = moment(userID.occ_start_time).format("hh");
    setHours(starthour);
    var startmin = moment(userID.occ_start_time).format("mm");
    setMin(startmin);
    var startss = moment(userID.occ_start_time).format("ss");
    setSeconds(startss);
  };
  const handleClickOpen = () => {
    setOpen(true);
    setUserId(props.userid);
    console.log("props.userid", props.userid);
  };

  const handleClose = () => {
    setOpen(false);
  };


  //followfcn
  const followfcn = (trainer_id) => {
    console.log(trainer_id, "trainer_id")
    var usertoken = localStorage.getItem('user');
    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;
    var bodyFormData = new FormData();
    bodyFormData.append('trainer_id', trainer_id);
    axios({
      method: "post",
      url: baseUrl + "student/follow/trainer",
      data: bodyFormData,
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }
    })
      .then(function (response) {

        //handle success
        if (response.status == '200') {

          console.log("response", response)
        }

      })
      .catch(function (response) {
      });
  };

  return (
    <div className={classes.root}>
      <Button color="primary" onClick={handleClickOpen}>
        <Tooltip title="Quick-View">
          <VisibilityIcon className={classes.view} />
        </Tooltip>
      </Button>

      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle id="alert-dialog-slide-title">hello</DialogTitle  > */}
        <DialogContent>
          <Grid container spacing={0}>
            <Grid lg={2} md={2} sm={2} xs={2}>
              <Avatar
                alt="Remy Sharp"
                src={
                  userID.trainer
                    ? "https://acharya.heptotechnologies.org/storage/" +
                    userID.trainer.image
                    : "/broken-image.jpg"
                }
                className={classes.large}
              />
            </Grid>
            <Grid lg={5} md={5} sm={5} xs={5} className={classes.text_color}>
              <Typography> {userID.topic}</Typography>
              <Typography style={{ fontSize: "11px" }}>
                {userID.trainer ? userID.trainer.name : ""}
              </Typography>
              <Typography style={{ fontSize: "10px" }}>120 session</Typography>
            </Grid>
            <Grid lg={5} md={5} sm={5} xs={4} style={{ textAlign: "end" }}>
              {Showfollow === false && (
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.follow}
                  onClick={() => { followfcn(userID.trainer_id) }}
                >
                  + follow
                </Button>
              )}
              {Showfollow === true && (
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.follow}
                  onClick={() => { followfcn(userID.trainer_id) }}
                >
                  - unfollow
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogContent style={{ backgroundColor: "#e6e4e4" }}>
          <Grid container spacing={0}>
            <Grid lg={8} md={6} sm={6} xs={6} className={classes.text_color}>
              {userID.topic}
            </Grid>

            <Grid lg={4} md={6} sm={6} xs={6} style={{ textAlign: "end" }}>
              {userID.enroll_student && (
                <Button
                  variant="contained"
                  component="span"
                  className={classes.entrolled}
                >
                  enrolled
                </Button>
              )}

              {userID.enroll_student == null && (
                <Button
                  variant="contained"
                  component="span"
                  className={classes.entrolled}
                >
                  enroll
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogContent>
          <Grid container spacing={0}>
            <span style={{ color: "#6bbb4c" }}>Content</span>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ marginTop: "10px", textIndent: "50px" }}
            >
              {userID.content}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogContent style={{ backgroundColor: "#e6e4e4" }}>
          <Grid container spacing={0}>
            <Grid lg={6} md={6} sm={6} xs={6} className={classes.text_color}>
              <DateRangeIcon
                style={{ marginBottom: "-5px", fontSize: "20px" }}
              />{" "}
              {moment(userID.meeting_start_time).format("  DD/MM/yyyy ")}
            </Grid>

            <Grid
              lg={6}
              md={6}
              sm={6}
              xs={6}
              style={{ textAlign: "end" }}
              className={classes.date}
            >
              <Typography>
                {" "}
                <DateRangeIcon
                  style={{ marginBottom: "-5px", fontSize: "20px" }}
                />{" "}
                {moment(userID.meeting_start_time).format(" hh:mm a")} to{" "}
                {moment(userID.meeting_end_time).format(" hh:mm a")}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogContent>
          <Grid container spacing={0}>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ textAlign: "center" }}
            >
              <Typography>Fees:Rs 3500</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <Grid container spacing={0} className={classes.last_session}>
          <Grid lg={6} md={12} sm={12} xs={12}>
            <span>
              {hours}:{min}:{seconds}
            </span>
          </Grid>

          <Grid lg={6} md={12} sm={12} xs={12} style={{ textAlign: "end" }}>
            <Button
              variant="contained"
              component="span"
              className={classes.entrolled}
            >
              Start the session
            </Button>
          </Grid>
        </Grid>

        <DialogActions style={{ backgroundColor: "#e6e4e4" }}>
          {/* <Button onClick={handleClose} color="primary">
            Disagree
          </Button> */}
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
