import { makeStyles } from "@material-ui/styles";
import { isMobile, deviceDetect } from "react-device-detect";

var drawerWidth = 0;
// if(isMobile === true){
//   drawerWidth = "10px";
// }else{
//   drawerWidth = 240;
// }

// const drawerWidth = {
//   width: '240px',
//   '@media(minWidth: 580px)' : {
//     width: '10px',
//     backgroundColor:'#3B5FB7'
//   }
// }



export default makeStyles(theme => ({
  
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor:'#3B5FB7'
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 40,
    [theme.breakpoints.down("sm")]: {
      width: '0px',
    },
    backgroundColor:'#3B5FB7'
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    
  },
  bodystyle:{
    color:'blue'
  },
 
  sidebarList: {
   color:'#ffffff',
   backgroundColor:'#3B5FB7'
   
  },
  // sidebarList: {
  //   "&:hover": {
  //     backgroundColor: 'rgb(7, 177, 77, 0.42)'
  //   }
  // },

  mobileBackButton: {
    marginTop: theme.spacing(0.5),
    marginLeft: 18,
    width:0,
    
    [theme.breakpoints.only("sm")]: {
      marginTop: theme.spacing(0.625),
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  drawerWidth: {
    backgroundColor: 'blue',
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'red',
    },
  },
}));
