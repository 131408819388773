import React, { useState, useEffect } from "react";

import { useTheme } from "@material-ui/styles";

import {
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  AcUnit as MeetingIcon,
  ArrowBack as ArrowBackIcon,
  PeopleAlt as StudentIcon,
  RecordVoiceOver as TrainerIcon
} from "@material-ui/icons";

import ShopTwoIcon from '@material-ui/icons/ShopTwo';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ShareIcon from '@material-ui/icons/Share';
//import Zoom  from '../../../components/zoomNew';
import Country  from '../../../components/popup/Setting-country';
import State  from '../../../components/popup/Setting-State';
import City  from '../../../components/popup/Setting-city';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { Grid,CardContent,Card,TextField,Avatar,FormControl,Select,MenuItem,InputLabel,Typography,Divider } from "@material-ui/core";
// styles
import useStyles from "./styles";
import DividerWithText from '../../../components/DividerWithText';
import "./zstyle.css";
import image  from "../image/images.jfif"

const mainChartData = getMainChartData();

const PieChartData = [
  { name: "Group A", value: 400, color: "primary" },
  { name: "Group B", value: 300, color: "secondary" },
  { name: "Group C", value: 300, color: "warning" },
  { name: "Group D", value: 200, color: "success" },
];

export default function Dashboard(props) {
  var classes = useStyles();
  var theme = useTheme();
  const [showFPTYPE, setShowFPTYPE] = useState(false);
  const [joinMeeting, setjoinMeeting] = useState(false);
  // local
  var [mainChartState, setMainChartState] = useState("monthly");


  useEffect(() => {

    var div = document.getElementById('pageTitle');
    div.innerHTML = "Marabu Nalam - Settings";

    var div = document.getElementById('crumbTitle');
    div.innerHTML = "Settings";

    
  }, []);

  return (
    <>
     <Grid container spacing={2}>
      
           
           
            <Grid item md={3} sm={6} xs={6} lg={2}>
            <Country />  
            </Grid>
            <Grid item md={3} sm={6} xs={6} lg={2}>
            <State /> 
            </Grid>
            <Grid item md={3} sm={6} xs={6} lg={2}>
            <City /> 
            </Grid>
     </Grid>
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
