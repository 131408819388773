import React, { useState } from "react";

import { useTheme } from "@material-ui/styles";
//import { ZoomMtg } from 'zoomus-jssdk';
//import { ZoomMtg } from "@zoomus/websdk";
import Zoom  from '../../components/zoomNew';
import Button from '@material-ui/core/Button';

// styles
import useStyles from "./styles";
// import "./zstyle.css";


const mainChartData = getMainChartData();
const PieChartData = [
  { name: "Group A", value: 400, color: "primary" },
  { name: "Group B", value: 300, color: "secondary" },
  { name: "Group C", value: 300, color: "warning" },
  { name: "Group D", value: 200, color: "success" },
];

export default function Dashboard(props) {
  var classes = useStyles();
  var theme = useTheme();
  const [showFPTYPE, setShowFPTYPE] = useState(false);
  const [joinMeeting, setjoinMeeting] = useState(false);
  // local
  var [mainChartState, setMainChartState] = useState("monthly");

  return (
    <>
    <div>
      {joinMeeting ? (
          <Zoom />
      ):(<Button variant="contained" color="primary" onClick={()=>setjoinMeeting(true)}>join Meeting</Button>)}
      </div>
    
    
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
