import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Card,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import axios from "axios";
import FormData from "form-data";
import configurl from "../../config1.json";
import logo1 from './Untitled.png'
import swal from 'sweetalert';
import FacebookIcon from '@material-ui/icons/Facebook';
import google from "./281764.png";
//import "./zstyle.css";
import Sociallogin from '../../components/TSocialSign';
// styles
import useStyles from "./styles";

// logo
import logo from "./home.png";
import logolog from "./logolog.png";
import OtpInput from 'react-otp-input';
// import google from "../../images/google.svg";

// context
import { useUserDispatch, loginUser } from "../../context/UserContext";
import { isMobile, deviceDetect } from "react-device-detect";
const Login = (props) => {
  //function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  var [nameValue, setNameValue] = useState("");
  var [otp, setOTP] = useState("");
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [cpassword, setcpassword] = useState("");
  var [ccpassword, setccpassword] = useState("");
  var [restData, setRestData] = useState("");
  var [resetotp, setResetotp] = useState("");
  //show / hide varables
  const [PswShow, SetPswShow] = useState(false);
  const [signupf, setSignupf] = useState(true);
  const [showidentifypsw, setShowidentifypsw] = useState(true);
  const [verfy, setVerify] = useState(false);
  const [showpass, setshowpass] = useState(false);
  const [otpshow, setOtpshow] = useState(false);
  const [passwordshow, setPasswordshow] = useState(false);
  const baseUrl = configurl.baseUrl[0];

  const [Mobilenum, setMobilenum] = useState('');




  const SetMobileNum = (e) => {

    // let re = /^[0-9\b]+$/;
    // const value = e.target.value.replace(/\D/g, "");
    //   if (e.target.value === '' || re.test(e.target.value)) {
    //     setMobilenum(e.target.value);
    //     console.log(e.target.value);
    //  }
    // setMobilenum(e.target.value);



    const re = /^[0-9\b]+$/; //rules
    if (e.target.value === "" || re.test(e.target.value)) {
      setLoginValue(e.target.value);
      console.log(e.target.value);
    }
  };



  const OTPhandleChange = (otp) => {
    setOTP(otp);
  };

  useEffect(() => {

    var d = JSON.stringify(deviceDetect());

  }, []);

  const createpassword = () => {

    var bodyFormData = new FormData();
    bodyFormData.append('password', cpassword);
    bodyFormData.append('password_confirmation', ccpassword);
    bodyFormData.append('guard', 'trainer');
    bodyFormData.append('otp', otp);
    bodyFormData.append('mobile', loginValue);
    bodyFormData.append('mcc_id', '2');


    axios({
      method: "post",
      url: baseUrl + "auth/password",
      data: bodyFormData,
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE'
      }
    })
      .then(function (response) {
        //handle success
        if (response.status == '200') {
          swal("", "Created Your Password!", "success");
          let Token = JSON.stringify(response.data.data['access_token']);
          loginUser(userDispatch, props.history, setIsLoading, setError, Token, 'trainer')
        }

      })
      .catch(function (response) {

        console.log(response);
      });
  };
  //Reset Password setOtpshow
  const ResetPws = () => {

    SetPswShow(true);
  };
  const FunSendOTP = () => {

    // console.log(restData);
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let chkvar = re.test(String(restData).toLowerCase());

    var bodyFormData = new FormData();
    if (chkvar) {
      // alert(123);
      bodyFormData.append('email', restData);
      bodyFormData.append('guard', 'trainer');
      //bodyFormData.append('mcc_id', '2');
    } else if (chkvar == false) {
      //  alert(1234);
      bodyFormData.append('mobile', restData);
      bodyFormData.append('guard', 'trainer');
      bodyFormData.append('_method', 'put');
      bodyFormData.append('mcc_id', '2');
    }
    axios({
      method: "post",
      url: baseUrl + "auth/otp",
      data: bodyFormData,
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE'
      }
    })
      .then(function (response) {
        //handle success
        if (response.status == '200') {
          console.log(response.data);
          setShowidentifypsw(false)
          setOtpshow(true)
        }

      })
      .catch(function (Error) {
        //handle error
        debugger;
        if (Error.message) {
          swal("", "Invalid User Data!", "error");
        }
        // console.log(Error.message);
      });
  };

  // change password 

  const resetverifyotp = () => {
    setPasswordshow(true)
    setOtpshow(false)
  }
  const create_pass = () => {

  }
  const createNewpassword = () => {

    // console.log(restData);
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let chkvar = re.test(String(restData).toLowerCase());

    var bodyFormData = new FormData();
    if (chkvar) {
      //alert(123);
      bodyFormData.append('email', restData);
      bodyFormData.append('guard', 'trainer');
      bodyFormData.append('password', ccpassword);
      bodyFormData.append('otp', otp);
      bodyFormData.append('mcc_id', '2');
    } else if (chkvar == false) {
      // alert(1234);
      bodyFormData.append('mobile', restData);
      bodyFormData.append('guard', 'trainer');
      bodyFormData.append('password', ccpassword);
      bodyFormData.append('otp', otp);
      bodyFormData.append('mcc_id', '2');
    }
    axios({
      method: "post",
      url: baseUrl + "auth/reset/password",
      data: bodyFormData,
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE'
      }
    })
      .then(function (response) {
        //handle success
        if (response.status == '200') {
          window.location.reload()
          swal("", "Password update succesfully", "success");
          console.log(response.data);
        }

      })
      .catch(function (Error) {
        //handle error
        debugger;
        if (Error.message) {
          swal("", "Invalid User Data!", "error");
        }
        // console.log(Error.message);
      });
  };

  //reguler Login
  const regularLogin = (mobile, pws, e) => {
    e.preventDefault();
    var bodyFormData = new FormData();
    bodyFormData.append('mobile', mobile);
    bodyFormData.append('password', pws);
    bodyFormData.append('guard', 'trainer');
    bodyFormData.append('mcc_id', '2');


    axios({
      method: "post",
      url: baseUrl + "auth/login",
      data: bodyFormData,
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE'
      }
    })
      .then(function (response) {
        //handle success
        if (response.status == '200') {
          console.log(response.data.data['access_token']);

          let Token = JSON.stringify(response.data.data['access_token']);
          loginUser(userDispatch, props.history, setIsLoading, setError, Token, 'trainer')
        }

      })
      .catch(function (Error) {
        //handle error
        debugger;
        if (Error.message) {
          swal("", "Invalid User Data!", "error");
        }
        // console.log(Error.message);
      });
  };

  const verifyotp = () => {

    var bodyFormData = new FormData();
    bodyFormData.append('name', nameValue);
    bodyFormData.append('guard', 'trainer');
    bodyFormData.append('otp', otp);
    bodyFormData.append('mobile', loginValue);
    bodyFormData.append('mcc_id', '2');


    axios({
      method: "post",
      url: baseUrl + "auth/otp",
      data: bodyFormData,
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE'
      }
    })
      .then(function (response) {
        //handle success
        if (response.status == '200') {
          setSignupf(false);
          setVerify(false);
          setshowpass(true);
          swal("", "your otp verifyed!", "success");
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };


  const userRegister = (username, mailId) => {
    // console.log(username+mailId+pws);

    var bodyFormData = new FormData();
    bodyFormData.append('name', username);
    bodyFormData.append('guard', 'trainer');
    bodyFormData.append('mobile', mailId);
    bodyFormData.append('mcc_id', '2');

    axios({
      method: "post",
      url: baseUrl + "auth/register",
      data: bodyFormData,
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE'
      }
    })
      .then(function (response) {
        //handle success
        console.log(response.status);

        if (response.status == '200') {
          setSignupf(false);
          setshowpass(false);
          setVerify(true);
        }
      })
      .catch(function (error) {
        //handle error
        //console.log(response);
        console.log(error.response.status);
        if (error.response.status == 422) {
          swal("", "Given mobile number is already exist!", "error");
        }

      });

  };


  return (
    <Grid container className={classes.container} style={{ overflow: 'scroll' }}>
      {isMobile === false && (<div style={{ width: '60%', height: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
        <Typography>
          <img src={logo} alt="logo" /*className={classes.logotypeImage}*/ style={{ height: '385px', width: '824px' }} /></Typography>
        <Typography style={{ color: 'rgb(37, 150, 190)', fontSize: '33px' }} className={classes.logotypeText}>Engage, Educate, Empower</Typography>
        <Typography style={{ color: '#10C345' }} className={classes.copyright} >
          © 2021-{new Date().getFullYear()} <a style={{ textDecoration: 'none', color: '#10C345' }} href="#" rel="noopener noreferrer" target="_blank">Acharya</a>, LLC. All rights reserved.
        </Typography>
      </div>)}
      <div className={classes.formContainer} style={{ backgroundColor: '#3B5FB7' }}>

        <div className={classes.form}>
          <Card style={{ padding: "22px", borderRadius: '27px', color: "#3B5FB7" }}>

            <Typography variant="h1" className={classes.greeting} >
              <img src={logolog} alt="logo" style={{ width: '50px' }} /*className={classes.logotypeImage}*/ /><br />

              <img src={logo1} alt="logo" />
            </Typography>
            {PswShow === false && (<> <Tabs
              value={activeTabId}
              onChange={(e, id) => setActiveTabId(id)}
              indicatorColor="primary"
              textColor="primary"
              centered>

              <Tab label="Login" classes={{ root: classes.tab }} />
              <Tab label="Sign up" classes={{ root: classes.tab }} />
            </Tabs>

              {activeTabId === 0 && (
                <React.Fragment>



                  {/* <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  Something is wrong with your login or password :(
                </Typography>
              </Fade> */}
                  <form >
                    <TextField
                      // variant="outlined"
                      id="email"
                      label="Mobile Number"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={loginValue}
                      onChange={e => setLoginValue(e.target.value)}
                      margin="normal"
                      placeholder="Mobile Number"
                      inputProps={{ maxLength: 10 }}
                      pattern="^-?[0-9]\d*\.?\d*$"
                      helperText={loginValue.length < 11 ? "" : "Invalid phone number"}
                      error={loginValue.length < 11 ? "" : "Invalid phone number"}
                      type="text"
                      fullWidth
                      onChange={(e) => SetMobileNum(e)}
                    />
                    <TextField
                      // variant="outlined"
                      label="Password"
                      id="password"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={passwordValue}
                      onChange={e => setPasswordValue(e.target.value)}
                      margin="normal"
                      placeholder="Password"
                      type="password"
                      fullWidth
                    />
                    <div className={classes.formButtons}>
                      {isLoading ? (
                        <CircularProgress size={26} className={classes.loginLoader} />
                      ) : (
                        <Button
                          type='submit'
                          disabled={
                            loginValue.length < 10 || passwordValue.length < 8
                          }
                          onClick={(e) => regularLogin(loginValue, passwordValue, e)}
                          // onClick={() =>
                          //   loginUser(
                          //     userDispatch,
                          //     loginValue,
                          //     passwordValue,
                          //     props.history,
                          //     setIsLoading,
                          //     setError,
                          //   )
                          // }
                          variant="contained"
                          color="primary"
                          size="large"
                        >
                          Login
                        </Button>
                      )}

                      <Button
                        color="primary"
                        size="large"
                        className={classes.forgetButton}
                        onClick={(e) => ResetPws()}
                      >
                        Forgot Password
                      </Button>
                    </div>
                  </form>

                  <Grid style={{ textAlign: "center", marginTop: "10px" }}>
                    Or Sign in using{" "}
                  </Grid>

                </React.Fragment>
              )}</>)}
            {PswShow === true && (
              <>
                {showidentifypsw === true && (
                  <>
                    <Typography class='cardmiddle' >
                      Reset Password
                    </Typography>




                    <TextField style={{ marginTop: "0px" }}
                      InputProps={{
                        classes: {
                          //underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={restData}
                      onChange={e => setRestData(e.target.value)}
                      margin="normal"
                      //  variant="outlined"
                      label="Mail ID / Mobile Number"
                      placeholder="Mail ID / Mobile Number"
                      //type="password"
                      fullWidth
                    />


                    <div className={classes.creatingButtonContainer}>
                      {isLoading ? (
                        <CircularProgress size={26} />
                      ) : (
                        <Button
                          onClick={() => FunSendOTP()}
                          disabled={
                            restData == '' ||
                            restData.length < 8
                          }
                          size="large"
                          variant="contained"
                          color="primary"
                          fullWidth
                          className={classes.createAccountButton}
                        >
                          Submit
                        </Button>
                      )}

                    </div>
                  </>
                )}

                {otpshow === true && (
                  <>
                    <Fade in={error}>
                      <Typography color="secondary" className={classes.errorMessage}>
                        Something is wrong with your login or password :(
                      </Typography>
                    </Fade>
                    <div style={{ margin: "-9px 48px 13px 24px" }} >
                      <OtpInput

                        value={otp}
                        // onChange={(e) => setOTP(e.target.value)}
                        onChange={OTPhandleChange}
                        //containerStyle={true}
                        inputStyle={classes.otpfieldstyle}
                        numInputs={6}
                        isInputNum={true}
                        shouldAutoFocus
                        separator={<span>-</span>}
                      />
                    </div>

                    <div className={classes.creatingButtonContainer}>
                      {isLoading ? (
                        <CircularProgress size={26} />
                      ) : (
                        <Button
                          onClick={() => resetverifyotp()}
                          disabled={
                            otp.length < 6
                          }
                          size="large"
                          variant="contained"
                          color="primary"
                          fullWidth
                          className={classes.createAccountButton}
                        >
                          Verify and Proceed
                        </Button>
                      )}

                    </div>

                  </>
                )}

                {passwordshow === true && (

                  <>

                    <Typography class='cardmiddle' >
                      Create Password
                    </Typography>


                    <TextField style={{ marginTop: "0px" }}

                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={cpassword}
                      onChange={e => setcpassword(e.target.value)}
                      margin="normal"
                      //  variant="outlined"
                      label="New Password"
                      placeholder="New Password"
                      type="password"
                      fullWidth
                    />

                    <TextField
                      // variant="outlined"
                      label="Confirm Password"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={ccpassword}
                      onChange={e => setccpassword(e.target.value)}
                      margin="normal"
                      placeholder="Confirm Password"
                      type="password"
                      fullWidth
                    />
                    <div className={classes.creatingButtonContainer}>
                      {isLoading ? (
                        <CircularProgress size={26} />
                      ) : (
                        <Button
                          onClick={() => createNewpassword()}
                          disabled={
                            cpassword != ccpassword ||
                            cpassword == '' ||
                            ccpassword == '' ||
                            cpassword.length < 8
                          }
                          size="large"
                          variant="contained"
                          color="primary"
                          fullWidth
                          className={classes.createAccountButton}
                        >
                          Submit
                        </Button>
                      )}

                    </div>
                  </>
                )}


              </>
            )}
            {activeTabId === 1 && (

              <React.Fragment>

                {signupf === true && (<>


                  <TextField
                    id="name"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                    }}
                    value={nameValue}
                    onChange={e => setNameValue(e.target.value)}
                    margin="normal"
                    // variant="outlined"
                    label="Name"
                    placeholder="Name"
                    type="text"
                    fullWidth
                  />
                  <TextField
                    id="email"
                    //  variant="outlined"
                    label="Mobile Number"
                    inputProps={{ maxLength: 10 }}
                    helperText={loginValue.length < 11 ? "" : "Invalid phone number"}
                    error={loginValue.length < 11 ? "" : "Invalid phone number"}
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                      maxLength: 10
                    }}
                    value={loginValue}
                    onChange={e => setLoginValue(e.target.value)}
                    margin="normal"
                    placeholder="Mobile Number"
                    type="text"
                    fullWidth
                    onChange={(e) => SetMobileNum(e)}
                  />

                  <div className={classes.creatingButtonContainer}>
                    {isLoading ? (
                      <CircularProgress size={26} />
                    ) : (
                      <Button
                        style={{ backgroundColor: "#3B5FB7", color: "white" }}
                        onClick={() => userRegister(nameValue, loginValue)}
                        disabled={
                          loginValue.length < 10 ||
                          // passwordValue.length === 0 ||
                          nameValue.length === 0
                        }
                        size="large"
                        variant="contained"
                        // color="primary"
                        fullWidth
                        className={classes.createAccountButton}
                      >
                        Continue
                      </Button>
                    )}
                  </div>
                  <Grid className={classes.cardbutton}>
                    <Grid>Or Sign up using </Grid>
                  </Grid>
                  <Grid className={classes.cardFbutton} >
                    <Grid className={classes.cardLbutton}  >
                      <FacebookIcon style={{ marginBottom: "-5px", fontSize: "20px" }} />
                      <span style={{ paddingLeft: "5px" }}>Facebook</span>
                    </Grid>
                    <Grid className={classes.cardRbutton}>
                      {/* <google style={{marginBottom:"-5px",fontSize:"20px"}}/> */}
                      {/* <img src={google} style={{width:"18px",marginBottom:"-5px"}}></img> */}
                      {/* <FacebookIcon  style={{marginBottom:"-5px",fontSize:"20px"}}/>  */}
                      <span style={{ paddingLeft: "0px" }}>  <Sociallogin moveF={props.history} /> </span>
                    </Grid>
                  </Grid>

                </>
                )}

                {verfy === true && (
                  <>
                    <Fade in={error}>
                      <Typography color="secondary" className={classes.errorMessage}>
                        Something is wrong with your login or password :(
                      </Typography>
                    </Fade>
                    <div style={{ margin: "-9px 48px 13px 24px" }} >
                      <OtpInput

                        value={otp}
                        // onChange={(e) => setOTP(e.target.value)}
                        onChange={OTPhandleChange}
                        //containerStyle={true}
                        inputStyle={classes.otpfieldstyle}
                        numInputs={6}
                        isInputNum={true}
                        shouldAutoFocus
                        separator={<span>-</span>}
                      />
                    </div>

                    <div className={classes.creatingButtonContainer}>
                      {isLoading ? (
                        <CircularProgress size={26} />
                      ) : (
                        <Button
                          onClick={() => verifyotp()}
                          disabled={
                            otp.length < 6
                          }
                          size="large"
                          variant="contained"
                          color="primary"
                          fullWidth
                          className={classes.createAccountButton}
                        >
                          Verify and Proceed
                        </Button>
                      )}

                    </div>

                  </>
                )}



                {showpass === true && (
                  <>

                    <Typography class='cardmiddle' >
                      Create Password
                    </Typography>


                    <TextField style={{ marginTop: "0px" }}

                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={cpassword}
                      onChange={e => setcpassword(e.target.value)}
                      margin="normal"
                      //  variant="outlined"
                      label="New Password"
                      placeholder="New Password"
                      type="password"
                      fullWidth
                    />

                    <TextField
                      // variant="outlined"
                      label="Confirm Password"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      value={ccpassword}
                      onChange={e => setccpassword(e.target.value)}
                      margin="normal"
                      placeholder="Confirm Password"
                      type="password"
                      fullWidth
                    />
                    <div className={classes.creatingButtonContainer}>
                      {isLoading ? (
                        <CircularProgress size={26} />
                      ) : (
                        <Button
                          onClick={() => createpassword()}
                          disabled={
                            cpassword != ccpassword ||
                            cpassword == '' ||
                            ccpassword == '' ||
                            cpassword.length < 8
                          }
                          size="large"
                          variant="contained"
                          color="primary"
                          fullWidth
                          className={classes.createAccountButton}
                        >
                          Submit
                        </Button>
                      )}

                    </div>

                  </>
                )}

              </React.Fragment>

            )}
          </Card>
        </div>
        {activeTabId === 1 && (<Typography color="primary" className={classes.copyright} style={{ color: "white" }}>
          I am already a member     <a style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => window.location.reload(false)} rel="noopener noreferrer" ><b>Login</b></a>
          {/* © 2014-{new Date().getFullYear()} <a style={{ textDecoration: 'none', color: 'inherit' }} href="https://flatlogic.com" rel="noopener noreferrer" target="_blank">Flatlogic</a>, LLC. All rights reserved. */}
        </Typography>)}
      </div>
    </Grid>
  );
}

export default withRouter(Login);
