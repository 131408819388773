import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/styles";
import axios from "axios";
import FormData from "form-data";
import configurl from "../../../config1.json";
import TZoom from '../../../components/zoomTrainer';
import MeetingView from "../../../components/popup/Tmeetingview";
import moment from "moment";
//import { ZoomMtg } from 'zoomus-jssdk';

// material-ui
import Pagination from '@material-ui/lab/Pagination';
import Button from '@material-ui/core/Button';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
// import { Redirect } from 'react-router-dom';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import Link from '@material-ui/core/Link';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { TimePicker, MuiPickersUtilsProvider, KeyboardTimePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import swal from 'sweetalert';
import Alert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox';
// icons
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"; // choose your lib
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
// styles
import useStyles from "./styles";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import "./zstyle.css";

const sessiondata = [
  { id: "2", value: 'Single Session' },
  { id: "8", value: 'Multi Session' },
];

const sessiontype = [
  { id: "1", value: 'Daily' },
  { id: "2", value: 'Weekly' },
  { id: "3", value: 'Monthly' },
];

const daysarr = [
  { id: "1", value: 'SUN', status: false },
  { id: "2", value: 'MON', status: false },
  { id: "3", value: 'TUE', status: false },
  { id: "4", value: 'WED', status: false },
  { id: "5", value: 'THU', status: false },
  { id: "6", value: 'FRI', status: false },
  { id: "7", value: 'SAT', status: false },
];

const timecheck = [
  { id: "1", value: 'SUN', status: false },

];

const Monthdayarr = [
  { id: "1", value: '1', status: false },
  { id: "2", value: '2', status: false },
  { id: "3", value: '3', status: false },
  { id: "4", value: '4', status: false },
  { id: "5", value: '5', status: false },
  { id: "6", value: '6', status: false },
  { id: "7", value: '7', status: false },
  { id: "8", value: '8', status: false },
  { id: "9", value: '9', status: false },
  { id: "10", value: '10', status: false },
  { id: "11", value: '11', status: false },
  { id: "12", value: '12', status: false },
  { id: "13", value: '13', status: false },
  { id: "14", value: '14', status: false },
  { id: "15", value: '15', status: false },
  { id: "16", value: '16', status: false },
  { id: "17", value: '17', status: false },
  { id: "18", value: '18', status: false },
  { id: "19", value: '19', status: false },
  { id: "20", value: '20', status: false },
  { id: "21", value: '21', status: false },
  { id: "22", value: '22', status: false },
  { id: "23", value: '23', status: false },
  { id: "24", value: '24', status: false },
  { id: "25", value: '25', status: false },
  { id: "26", value: '26', status: false },
  { id: "27", value: '27', status: false },
  { id: "28", value: '28', status: false },
  { id: "29", value: '29', status: false },
  { id: "30", value: '30', status: false },
  { id: "31", value: '31', status: false },
];

const theme = createMuiTheme({
  overrides: {
    MuiSwitch: {
      track: {
        "$checked$checked + &": {
          opacity: 1.0,
          backgroundColor: "rgb(129, 171, 134)" // Light green, aka #74d77f
        }
      }
    }
  }
});

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 52,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default function Shedulemeeting(props) {
  var classes = useStyles();
  var theme = useTheme();
  var history = useHistory();

  const baseUrl = configurl.baseUrl[0];
  const [curDate, setCurDate] = useState('');
  const [RecType, setRecType] = useState('');
  const [Content, setContent] = useState('');
  //single meeting vaiables
  const [title, setTitle] = useState('');
  const [Roomsize, setRoomsize] = useState('');
  const [type, settype] = useState(8);
  const [sDate, setSDate] = useState('');
  const [EndDate, setEndDate] = useState('');
  const [sTime, setSTime] = useState('');
  const [duration, setDuration] = useState('');
  const [sessStTime, setsessStTime] = useState(null);
  const [sessEndTime, setsessEndTime] = useState(null);
  const [QusStTime, setQusStTime] = useState(null);
  const [QusEndTime, setQusEndTime] = useState(null);
  const [BrkStTime, setBrkStTime] = useState(null);
  const [BrkEndTime, setBrkEndTime] = useState(null);
  const [page, setPage] = useState(1);
  const [totalpages, settotalpages] = useState('');
  const [mailId, setMailID] = useState('');
  const [password, setPassword] = useState('');
  const [agenda, setAgenda] = useState('');
  const [meetingList, setmeetingList] = useState([]);
  const [days, setdays] = useState(daysarr);
  const [timeblock, setTimeblock] = useState(false);
  const [checkblock, setCheckblock] = useState(true);
  const [showtable, setShowtable] = useState(true);
  const [recTypeValue, setrecTypeValue] = useState('');
  const [Monthday, setMonthday] = useState(Monthdayarr);
  const [selectaday, setselectaday] = useState('');
  const [Mtype, setMtype] = useState('all');
  const [Meetingid, setMeetingid] = useState('');
  const [MeetingData, setMeetingData] = useState([]);
  const [emtyarry, setEmtyarry] = useState("");
  const [Sorting, setSorting] = useState(true);

  const [Profilelist, SetProfilelist] = useState(false);
  const [Ubtn, setUbtn] = useState(true);
  const [selectbox, setSelectbox] = useState(false);
  const [showMeeting, setShowMeeting] = useState(false);
  const [sdate, setSdate] = useState(true);


  //time intervel variables
  const [SessEndTimeArr, SetSessEndTimeArr] = useState([]);
  const [SessQStrTimeArr, SetSessQStrTimeArr] = useState([]);
  const [SessQEndTimeArr, SetSessQEndTimeArr] = useState([]);
  const [SessBrStrTimeArr, SetSessBrStrTimeArr] = useState([]);
  const [SessBrEndTimeArr, SetSessBrEndTimeArr] = useState([]);
  //Error text variables
  const [ErrSessTopic, setErrSessTopic] = useState("");
  const [ErrSessstime, setErrErrSessstime] = useState("");
  const [ErrSesseTime, setSesseTime] = useState("");
  const [ErrSessqsTime, setErrSessqsTime] = useState("");
  const [ErrSessqeTime, setErrSessqeTime] = useState("");
  const [ErrSessbsTime, setErrSessbsTime] = useState("");
  const [ErrSessbeTime, setErrSessbeTime] = useState("");
  const [ErrSessdate, setErrSessdate] = useState("");
  const [ErrSesedate, setErrSesedate] = useState("");
  const [Errroom, setErrroom] = useState("");
  const [Errcontent, setErrcontent] = useState("");
  const [Errtype, setErrtype] = useState("");
  const [open, setOpen] = React.useState(false);
  const [Errname, setErrname] = useState("");
  const [Errmobile, setErrErrmobile] = useState("");
  const [Erremail, setErremail] = useState("");
  const [Errdob, setErrdob] = useState("");
  const [Errage, setErrage] = useState("");
  const [Errcity, setErrcity] = useState("");
  const [Errtitle, setErrtitle] = useState("");
  const [Errimage, setErrimage] = useState("");
  const [Room, setRoom] = useState();

  const SetNewRoomSize = (e) => {
    //let re = /^[0-9\b]+$/;
    //const value = e.target.value.replace(/\D/g, "");
    //   if (e.target.value === '' || re.test(e.target.value)) {
    //     setRoomsize(e.target.value);
    //     console.log(e.target.value);
    //  }
    // setRoomsize(e.target.value);
    const re = /^[0-9\b]+$/; //rules
    if (e.target.value === "" || re.test(e.target.value)) {
      setRoomsize(e.target.value);
      console.log(e.target.value);
    }
  };

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });




  useEffect(() => {
    var div = document.getElementById('pageTitle');
    div.innerHTML = "Marabu Nalam - Meeting List";

    var div = document.getElementById('crumbTitle');
    div.innerHTML = " Meeting List";

    let mydate = new Date();

    setCurDate(moment(mydate).format("YYYY-MM-DD HH:MM"));
    getmeetinglist('empty', page);
    getprofile()

  }, []);

  const setValueHandler = (e) => {
    setDuration(e.target.value.replace(/[^0-9]/g, ''));
  }
  //successfully meeting creation message
  const notify = () => toast.success("👋 Meeting Created!", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  //create meeting failer   
  const notifyfailer = (message) => toast.error("👋 " + message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  const createmeeting = () => {

    if (RecType == 2 && timeblock == true) {
      if (RecType && title && Content && Roomsize && sDate && sessStTime && sessEndTime && QusStTime && QusEndTime && BrkStTime && BrkEndTime) {
        createmeeting1();
      } else {
        alert('Please enter all fields');
        if (!title) {
          setErrSessTopic("Enter session topic");
        }
        if (!Content) {
          setErrcontent("Enter course content");
        }
        if (!Roomsize) {
          setErrroom("Enter room size");
        }
        if (!sessStTime) {
          setErrErrSessstime("Enter session start time");
        }
        if (!sessEndTime) {
          setSesseTime("Enter session end time");
        }
        if (!sDate) {
          setErrSessdate("Enter session start date");
        }
        if (!RecType) {
          setErrtype("Enter session type");
        }
        if (!QusStTime) {
          setErrSessqsTime("Enter  question start time");
        }
        if (!QusEndTime) {
          setErrSessqeTime("Enter  question end time");
        }
        if (!BrkStTime) {
          setErrSessbsTime("Enter  Break start time");
        }
        if (!BrkEndTime) {
          setErrSessbeTime("Enter  Break start time");
        }
      }
    }
    else if (RecType == 2 && timeblock == false) {

      if (RecType && title && Content && Roomsize && sDate && sessStTime && sessEndTime) {
        createmeeting1();
      }
      else {
        alert('Please enter all fields');
        if (!title) {
          setErrSessTopic("Enter session topic");
        }
        if (!Content) {
          setErrcontent("Enter course content");
        }
        if (!Roomsize) {
          setErrroom("Enter room size");
        }
        if (!sessStTime) {
          setErrErrSessstime("Enter session start time");
        }
        if (!sessEndTime) {
          setSesseTime("Enter session end time");
        }


        if (!sDate) {
          setErrSessdate("Enter session start date");
        }
        if (!RecType) {
          setErrtype("Enter session type");
        }

      }
    }

    else {
      if (RecType && title && Content && Roomsize && sDate && sessStTime && sessEndTime) {
        createmeeting1();
      }
      else {
        alert('Please enter all fields');
        if (!title) {
          setErrSessTopic("Enter session topic");
        }
        if (!Content) {
          setErrcontent("Enter course content");
        }
        if (!Roomsize) {
          setErrroom("Enter room size");
        }
        if (!sessStTime) {
          setErrErrSessstime("Enter session start time");
        }
        if (!sessEndTime) {
          setSesseTime("Enter session end time");
        }

        if (!QusStTime) {
          setErrSessqsTime("Enter  question start time");
        }
        if (!QusEndTime) {
          setErrSessqeTime("Enter  question end time");
        }
        if (!BrkStTime) {
          setErrSessbsTime("Enter  Break start time");
        }
        if (!BrkEndTime) {
          setErrSessbeTime("Enter  Break start time");
        }
        if (!sDate) {
          setErrSessdate("Enter session start date");
        }
        if (!RecType) {
          setErrtype("Enter session type");
        }
      }
    }


  };
  //Assign the session start date
  const funseStDate = (data) => {
    setSDate(data);
  };

  const FunGoProfile = () => {
    history.push('/app/trainer/Profile');
  };

  //create meeting
  const createmeeting1 = () => {
    // alert("works");
    if (RecType == 2 && timeblock == false) {

      var result1 = moment(sessStTime).format(" HH:mm");
      var sesStTime = sDate + '' + result1 + ":00";

      //End date variable
      var result2 = moment(sessEndTime).format(" HH:mm");
      var sesEndDate = EndDate + '' + result2 + ":00";


      var sesEndTime = moment(sessEndTime).format(" HH:mm");

      // var QussStTime =moment(QusStTime).format(" HH:mm");

      // var QussEndTime =moment(QusEndTime).format(" HH:mm");

      // var BrksStTime =moment(BrkStTime).format(" HH:mm");

      // var BrkendTime =moment(BrkEndTime).format(" HH:mm");

      if (recTypeValue == '2') {
        var selectedWeekdays = [];
        days.map(function (value, index1) {

          if (value.status == true) {

            selectedWeekdays.push(value.id);

          }
        });

      }

      var startTime = new Date(sDate + ' ' + moment(sessStTime).format(" HH:mm"));
      var endTime = new Date(sDate + ' ' + moment(sessEndTime).format(" HH:mm"));
      var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
      var resultInMinutes = Math.round(difference / 60000);

      //       var startTime = new Date('2012/10/09 22:00:00'); 
      // var endTime = new Date('2012/10/10 01:00:00');
      // var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
      // var resultInMinutes = Math.round(difference / 60000);

      // console.log(resultInMinutes);




      var bodyFormData = new FormData();

      bodyFormData.append('topic', title);
      bodyFormData.append('type', RecType);
      bodyFormData.append('content', Content);
      bodyFormData.append('start_time', sesStTime);

      bodyFormData.append('duration', resultInMinutes);

      if (RecType == 2) {
        bodyFormData.append('recurrence', 0);
      } else if (RecType == 8) {
        bodyFormData.append('recurrence', 1);
        bodyFormData.append('repeat_interval', 0);
        bodyFormData.append('end_date_time', sesEndDate);
        bodyFormData.append('recurrence_type', recTypeValue);
      }

      if (recTypeValue == 2) {
        bodyFormData.append('weekly_days', selectedWeekdays);
      }
      if (recTypeValue == 3) {

        bodyFormData.append('monthly_days', selectaday);
      }

      //recTypeValue
      bodyFormData.append('paid', 0);
      //bodyFormData.append('schedule_for', mailId);
      bodyFormData.append('timezone', 'Asia/Kolkata');
      bodyFormData.append('room_size', Roomsize);
      //bodyFormData.append('password', password);
      bodyFormData.append('agenda', 'Test data');
      bodyFormData.append('end_time', sesEndTime + ":00");
      // bodyFormData.append('start_qa_time', QussStTime+":00");
      // bodyFormData.append('end_qa_time', QussEndTime+":00");
      // bodyFormData.append('start_break_time', BrksStTime+":00");
      // bodyFormData.append('end_break_time', BrkendTime+":00");

      console.log("test", resultInMinutes);

      var usertoken = localStorage.getItem('user');
      var test = usertoken.replace(/[#[$\]\\"]/g, '');
      usertoken = 'Bearer ' + test;
      axios({
        method: "post",
        url: baseUrl + "trainer/meeting",
        data: bodyFormData,
        headers: {
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
          'Authorization': usertoken
        }
      })
        .then(function (response) {
          console.log(response);
          //handle success
          if (response.status == '200') {

            notify();
            //reset the fields
            setTitle('');
            setSTime('');
            setDuration('');
            setMailID('');
            setPassword('');
            setAgenda('');
            setContent('');
            setRecType('');
            setRoomsize('');
            setSTime('');
            setSDate('');
            setDuration('');
            setsessStTime(null);
            setsessEndTime(null);
            setQusStTime(null);
            setQusEndTime(null);
            setBrkStTime(null);
            setBrkEndTime(null);
            window.location.reload();
            getmeetinglist('', 1,);
          }

        })
        .catch(function (error) {
          //handle error           
          if (error.response.data.error) {
            notifyfailer(error.response.data.error.start_time);
          }
          if (error.message) {

          }

        });

    }
    else {
      var result1 = moment(sessStTime).format(" HH:mm");
      var sesStTime = sDate + '' + result1 + ":00";

      //End date variable
      var result2 = moment(sessEndTime).format(" HH:mm");
      var sesEndDate = EndDate + '' + result2 + ":00";


      var sesEndTime = moment(sessEndTime).format(" HH:mm");

      var QussStTime = moment(QusStTime).format(" HH:mm");

      var QussEndTime = moment(QusEndTime).format(" HH:mm");

      var BrksStTime = moment(BrkStTime).format(" HH:mm");

      var BrkendTime = moment(BrkEndTime).format(" HH:mm");

      if (recTypeValue == '2') {
        var selectedWeekdays = [];
        days.map(function (value, index1) {

          if (value.status == true) {

            selectedWeekdays.push(value.id);

          }
        });

      }

      var startTime = new Date(sDate + ' ' + moment(sessStTime).format(" HH:mm"));
      var endTime = new Date(sDate + ' ' + moment(sessEndTime).format(" HH:mm"));
      var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
      var resultInMinutes = Math.round(difference / 60000);

      var bodyFormData = new FormData();

      bodyFormData.append('topic', title);
      bodyFormData.append('type', RecType);
      bodyFormData.append('content', Content);
      bodyFormData.append('start_time', sesStTime);

      console.log("test1", resultInMinutes);

      bodyFormData.append('duration', resultInMinutes);

      if (RecType == 2) {
        bodyFormData.append('recurrence', 0);
      } else if (RecType == 8) {
        bodyFormData.append('recurrence', 1);
        bodyFormData.append('repeat_interval', 0);
        bodyFormData.append('end_date_time', sesEndDate);
        bodyFormData.append('recurrence_type', recTypeValue);
      }

      if (recTypeValue == 2) {
        bodyFormData.append('weekly_days', selectedWeekdays);
      }
      if (recTypeValue == 3) {

        bodyFormData.append('monthly_days', selectaday);
      }

      //recTypeValue
      bodyFormData.append('paid', 0);
      //bodyFormData.append('schedule_for', mailId);
      bodyFormData.append('timezone', 'Asia/Kolkata');
      bodyFormData.append('room_size', Roomsize);
      //bodyFormData.append('password', password);
      bodyFormData.append('agenda', 'Test data');
      bodyFormData.append('end_time', sesEndTime + ":00");
      bodyFormData.append('start_qa_time', QussStTime + ":00");
      bodyFormData.append('end_qa_time', QussEndTime + ":00");
      bodyFormData.append('start_break_time', BrksStTime + ":00");
      bodyFormData.append('end_break_time', BrkendTime + ":00");

      var usertoken = localStorage.getItem('user');
      var test = usertoken.replace(/[#[$\]\\"]/g, '');
      usertoken = 'Bearer ' + test;
      axios({
        method: "post",
        url: baseUrl + "trainer/meeting",
        data: bodyFormData,
        headers: {
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
          'Authorization': usertoken
        }
      })
        .then(function (response) {
          //handle success
          if (response.status == '200') {

            notify();
            //reset the fields
            setTitle('');
            setSTime('');
            setDuration('');
            setMailID('');
            setPassword('');
            setAgenda('');
            setContent('');
            setRecType('');
            setRoomsize('');
            setSTime('');
            setSDate('');
            setDuration('');
            setsessStTime(null);
            setsessEndTime(null);
            setQusStTime(null);
            setQusEndTime(null);
            setBrkStTime(null);
            setBrkEndTime(null);
            window.location.reload();
            getmeetinglist('', 1,);
          }

        })
        .catch(function (error) {
          //handle error           
          if (error.response.data.error) {
            notifyfailer(error.response.data.error.start_time);
          }
          if (error.message) {

          }

        });
    }
  };

  const getprofile = () => {
    var usertoken = localStorage.getItem('user');
    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;
    axios({
      method: "get",
      url: baseUrl + "trainer/profile",
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }

    })
      .then(function (response) {
        if (response.status == '200') {

          if (response.data.data.age && response.data.data.name && response.data.data.dob && response.data.data.image && response.data.data.email) {
            SetProfilelist(true);
          }
          else {

            if (!response.data.data.name) {
              setOpen(true)
              setErrname("update your name");
            }
            if (!response.data.data.age) {
              setOpen(true)
              setErrage("update your Age ");
            }
            if (!response.data.data.dob) {
              setOpen(true)
              setErrdob("update your Date of birth");
            }
            if (!response.data.data.image) {
              setOpen(true)
              setErrimage("update Your Image");
            }
            if (!response.data.data.email) {
              setOpen(true)
              setErremail("update your Mail ID");
            }

          }

        };

      })
      .catch(function (error) {
        //handle error      

      });
  };
  //Get Meeting list
  const getmeetinglist = (event, value, meType) => {

    let setdata = '';
    if (meType) {
      setdata = meType;
    } else {
      setdata = Mtype;
    }

    var usertoken = localStorage.getItem('user');
    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;


    axios({
      method: "get",
      url: baseUrl + "trainer/meeting?page=" + value + "&limit=10&type=" + setdata + "&topic=asc&start_time=desc",

      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }
    })
      .then(function (response) {

        if (response.status == '200') {

          setEmtyarry('Meeting list is empty!');
          setmeetingList(response.data.data);
          settotalpages(response.data.data.total_pages);
          setPage(response.data.data.current_page);
          //setPage
        }

      })
      .catch(function (response) {
        //handle error  
        setEmtyarry('Meeting list is empty!');
      });
  };
  //set meeting type
  const meetingtype = (data) => {
    setMtype(data);
    getmeetinglist(data);
  };
  //Join Meeting
  const joinMeeting = (elem) => {
    elem.username = window.localStorage.getItem('Uname');
    // setMeetingID(elem);
    console.log("elem", elem)
    if (elem.zoom_meeting_id) {
      setShowMeeting(true);
      setMeetingData(elem);
    } else {
      alert('Meeting is Not available');
    }
  };

  // Kind Attention Onchange Function
  const chkhandleChange = (prop, event, index, item) => {
    let newArr = [...days];
    days.map((data) => {
      if (event.target.checked === true) {
        newArr[index].status = event.target.checked;
      } else {
        newArr[index].status = false;
      }
    });

    setdays(newArr);
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      //backgroundColor: theme.palette.common.black,
      backgroundColor: '#3B5FB7',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const clickRec = (e) => {
    setSelectbox(true)
    setRecType(e.target.value)
    if (e.target.value == 8) {
      setSdate(false)
      setTimeblock(true)
      setCheckblock(false)

    }
    if (e.target.value == 2) {
      setSdate(true)
      setCheckblock(true)
      setTimeblock(false)

    }
  }


  const testfun = (data) => {

    var test = moment(data).format(" HH:mm");
    var result = moment(data).format("YYYY-MM-DD HH:mm");
    setsessStTime(result)
    var test1 = moment(data).format("H");
    const startHour = test1;
    // const endHour = 24;
    let endHour = "";
    for (var i = 0; i <= 4; i++) {
      endHour = parseInt(startHour) + parseInt(i)

    }
    console.log("startHour", startHour);
    console.log("endHour", endHour)
    let findhrs = (Array.from({
      length: endHour - startHour
    }, (v, index) => {

      return [0, 15, 30, 45].map((interval) => {
        return moment({
          hour: index,
          minute: interval
        })
          .add(startHour, 'hours')
          .format('hh:mm A')
      })
    }).flat());

    var CrtDate = moment(data).format("YYYY-MM-DD");
    // CrtDate = CrtDate.toString()
    var newStateArray = [];
    findhrs.map(function (item, index) {

      var x = {
        id: index,
        NormalTime: item,
        dateTime: CrtDate + ' ' + moment(CrtDate + ' ' + item).format("HH:mm"),
      };

      var startarray = [];
      var newstartobject = {}
      newstartobject.NormalTime = test;

      startarray.push(newstartobject);
      newStateArray.push(x);



    });
    let filteredarray = [];
    var test1 = moment(data).format("mm");
    console.log('checcck', test1);
    console.log('demo', newStateArray);
    if (test1 == '00') {
      //   alert('start', test1);
      filteredarray = newStateArray;
    }
    else if (test1 <= '15') {
      // alert('one', test1);
      filteredarray = newStateArray.filter((item) => item.id >= 1);
    } else if (test1 <= '30') {
      //  alert('two', test1);
      filteredarray = newStateArray.filter((item) => item.id >= 2);
    } else if (test1 <= '45') {
      // alert('three', test1);
      filteredarray = newStateArray.filter((item) => item.id >= 3);
    } else {
      // alert('end', test1);
      filteredarray = newStateArray.filter((item) => item.id >= 4);
    }



    SetSessEndTimeArr(filteredarray);
    // setsessStTime(data);
  }

  //Idintify
  const testfun1 = (data) => {

    var result3 = moment(data).format("YYYY-MM-DD HH:mm");
    setsessEndTime(result3);

    var result3 = moment(data).format("YYYY-MM-DD HH:mm");
    const filteredarray = SessEndTimeArr.filter((item) => item.dateTime == result3);

    const filteredarray1 = SessEndTimeArr.filter((item) => item.id <= filteredarray[0].id);

    SetSessQStrTimeArr(filteredarray1);

  };
  const testfun2 = (data) => {

    var result = moment(data).format("YYYY-MM-DD HH:mm");
    setQusStTime(result);



    var result3 = moment(data).format("YYYY-MM-DD HH:mm");

    const filteredarray = SessQStrTimeArr.filter((item) => item.dateTime == result3);

    const filteredarray1 = SessQStrTimeArr.filter((item) => item.id > filteredarray[0].id);


    SetSessQEndTimeArr(filteredarray1);


  };

  const testfun3 = (data) => {

    var result = moment(data).format("YYYY-MM-DD HH:mm");
    setQusEndTime(result);

    const filteredarray = SessQStrTimeArr.filter((item) => item.dateTime == QusStTime);

    const filteredarray1 = SessQStrTimeArr.filter((item) => item.dateTime == result);

    let svalue = filteredarray[0].id;
    let evalue = filteredarray1[0].id;

    var tempArray = []
    SessQStrTimeArr.map(function (item, index) {
      if (item.id <= svalue || item.id >= evalue) {
        if (item.id == svalue) {
          var y = {
            id: item.id,
            NormalTime: item.NormalTime + ' Question start time',
            dateTime: item.dateTime
          }
          tempArray.push(y);
        } else if (item.id == evalue) {
          var y = {
            id: item.id,
            NormalTime: item.NormalTime + ' Question End time',
            dateTime: item.dateTime
          }
          tempArray.push(y);
        } else {
          var y = {
            id: item.id,
            NormalTime: item.NormalTime,
            dateTime: item.dateTime
          }
          tempArray.push(y);
        }
      }
    });


    SetSessBrStrTimeArr(tempArray);
  }
  const testfun4 = (data) => {
    var result = moment(data).format("YYYY-MM-DD HH:mm");
    setBrkStTime(result);

    var result3 = moment(data).format("YYYY-MM-DD HH:mm");

    const filteredarray = SessBrStrTimeArr.filter((item) => item.dateTime == result3);

    const filteredarray1 = SessBrStrTimeArr.filter((item) => item.id > filteredarray[0].id);


    SetSessBrEndTimeArr(filteredarray1);

  }
  const testfun5 = (data) => {
    var result = moment(data).format("YYYY-MM-DD HH:mm");
    setBrkEndTime(result);
  }

  const yesterday = moment().format("YYYY-MM-DD")
  const checkmeeting = (event, value) => {
    setPage(value);
    getmeetinglist('empty', value);
  }


  const editmeeting = (e) => {
    console.log("e", e);
    setUbtn(false)
    setShowtable(false)
    if (e.start_qa_time && e.end_qa_time && e.start_break_time && e.end_break_time) {
      setTimeblock(true)
      setCheckblock(false)
    }

    //  session end time
    var endtime_edit = moment(e.start_time).format("YYYY-MM-DD ");
    setsessEndTime(moment(endtime_edit + ' ' + e.end_time).format("YYYY-MM-DD HH:mm"));
    var result = moment(e.start_time).format("YYYY-MM-DD HH:mm");
    setsessStTime(result)

    var test1 = moment(e.start_time).format("H");

    let startHour = test1;
    let endHour = "";
    for (var i = 0; i <= 4; i++) {
      endHour = parseInt(startHour) + parseInt(i)
    }
    let findhrs = (Array.from({
      length: endHour - startHour
    }, (v, index) => {

      return [0, 15, 30, 45].map((interval) => {
        return moment({
          hour: index,
          minute: interval
        })
          .add(startHour, 'hours')
          .format('hh:mm A')
      })
    }).flat());

    let CrtDate = moment(e.start_time).format("YYYY-MM-DD");
    // CrtDate = CrtDate.toString()
    var newStateArray = [];
    findhrs.map(function (item, index) {

      var x = {
        NormalTime: item,
        dateTime: CrtDate + ' ' + moment(CrtDate + ' ' + item).format("HH:mm"),
      };

      newStateArray.push(x);
    });

    SetSessEndTimeArr(newStateArray);

    // question start time
    setQusStTime(moment(endtime_edit + ' ' + e.start_qa_time).format("YYYY-MM-DD HH:mm"));
    var test2 = moment(endtime_edit + ' ' + e.end_time).format("H");
    startHour = test1;
    endHour = test2;
    findhrs = (Array.from({
      length: endHour - startHour
    }, (v, index) => {

      return [0, 15, 30, 45].map((interval) => {
        return moment({
          hour: index,
          minute: interval
        })
          .add(startHour, 'hours')
          .format('hh:mm A')
      })
    }).flat());

    CrtDate = moment(e.start_time).format("YYYY-MM-DD");
    // CrtDate = CrtDate.toString()
    var newStateArray = [];
    findhrs.map(function (item, index) {

      var x = {
        NormalTime: item,
        dateTime: CrtDate + ' ' + moment(CrtDate + ' ' + item).format("HH:mm"),
      };

      newStateArray.push(x);
    });
    SetSessQStrTimeArr(newStateArray);
    setQusEndTime(moment(endtime_edit + ' ' + e.end_qa_time).format("YYYY-MM-DD HH:mm"));
    setBrkStTime(moment(endtime_edit + ' ' + e.start_break_time).format("YYYY-MM-DD HH:mm"));
    setBrkEndTime(moment(endtime_edit + ' ' + e.end_break_time).format("YYYY-MM-DD HH:mm"));
    setContent(e.content);
    setRoomsize(e.room_size);
    setRecType(e.type)
    setMeetingid(e.id)
    setSDate(moment(e.start_time).format("YYYY-MM-DD"));
    // var updateday=[];
    if (e.recurrence) {
      //week days caluculation
      if (e.recurrence.weekly_days) {

        var test1 = e.recurrence.weekly_days;
        let newArr = [...days];
        var mynewarray = test1.split(',');
        mynewarray.map((data) => {
          var newindex = data - 1;
          newArr[newindex].status = true;
        });
        setdays(newArr);
      }
      setrecTypeValue(e.recurrence.type);
      setEndDate(moment(e.recurrence.end_date_time).format("YYYY-MM-DD"));

    }
    setTitle(e.topic)
    setSelectbox(true)
    if (e.type == 8) {
      setSdate(false)

    }
    if (e.type == 2) {
      setSdate(true)

    }
  }
  const updatemeeting = () => {

    if (RecType == 2 && timeblock == true) {

      if (RecType && title && Content && Roomsize && sDate && sessStTime && sessEndTime) {

        var result1 = moment(sessStTime).format(" HH:mm");
        var sesStTime = sDate + '' + result1 + ":00";

        //End date variable
        var result2 = moment(sessEndTime).format(" HH:mm");
        var sesEndDate = EndDate + '' + result2 + ":00";


        var sesEndTime = moment(sessEndTime).format(" HH:mm");

        var QussStTime = moment(QusStTime).format(" HH:mm");

        var QussEndTime = moment(QusEndTime).format(" HH:mm");

        var BrksStTime = moment(BrkStTime).format(" HH:mm");

        var BrkendTime = moment(BrkEndTime).format(" HH:mm");

        if (recTypeValue == '2') {
          var selectedWeekdays = [];
          days.map(function (value, index1) {

            if (value.status == true) {

              selectedWeekdays.push(value.id);

            }
          });

        }

        var startTime = new Date(sDate + ' ' + moment(sessStTime).format(" HH:mm"));
        var endTime = new Date(sDate + ' ' + moment(sessEndTime).format(" HH:mm"));
        var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds


        var resultInMinutes = Math.round(difference / 60000);

        var bodyFormData = new FormData();

        bodyFormData.append('topic', title);
        bodyFormData.append('type', RecType);
        bodyFormData.append('content', Content);
        bodyFormData.append('start_time', sesStTime);
        bodyFormData.append('duration', resultInMinutes);

        if (RecType == 2) {
          bodyFormData.append('recurrence', 0);
        } else if (RecType == 8) {
          bodyFormData.append('recurrence', 1);
          bodyFormData.append('repeat_interval', 0);
          bodyFormData.append('end_date_time', sesEndDate);
          bodyFormData.append('recurrence_type', recTypeValue);
        }

        if (recTypeValue == 2) {
          bodyFormData.append('weekly_days', selectedWeekdays);
        }
        if (recTypeValue == 3) {
          //   alert('hi');
          bodyFormData.append('monthly_days', selectaday);
        }

        //recTypeValue
        bodyFormData.append('paid', 0);
        //bodyFormData.append('schedule_for', mailId);
        bodyFormData.append('timezone', 'Asia/Kolkata');
        bodyFormData.append('room_size', Roomsize);
        //bodyFormData.append('password', password);
        bodyFormData.append('agenda', 'Test data');
        bodyFormData.append('end_time', sesEndTime + ":00");
        // bodyFormData.append('start_qa_time', QussStTime+":00");
        // bodyFormData.append('end_qa_time', QussEndTime+":00");
        // bodyFormData.append('start_break_time', BrksStTime+":00");
        // bodyFormData.append('end_break_time', BrkendTime+":00");
        bodyFormData.append('id', Meetingid);
        bodyFormData.append('meetingId', Meetingid);
        bodyFormData.append('_method', "put");

        var usertoken = localStorage.getItem('user');
        var test = usertoken.replace(/[#[$\]\\"]/g, '');
        usertoken = 'Bearer ' + test;
        axios({
          method: "post",
          url: baseUrl + "trainer/meeting",
          data: bodyFormData,
          headers: {
            'Accept': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
            'Authorization': usertoken
          }
        })
          .then(function (response) {
            //handle success
            if (response.status == '200') {

              notify();
              //reset the fields
              setTitle('');
              setSTime('');
              setDuration('');
              setMailID('');
              setPassword('');
              setAgenda('');
              setContent('');
              setRecType('');
              setRoomsize('');
              setSTime('');
              setSDate('');
              setDuration('');
              setsessStTime(null);
              setsessEndTime(null);
              setQusStTime(null);
              setQusEndTime(null);
              setBrkStTime(null);
              setBrkEndTime(null);
              window.location.reload();
              getmeetinglist('', 1,);
            }

          })
          .catch(function (error) {
            //handle error          
            if (error.response.data.error) {
              notifyfailer(error.response.data.error.start_time);
            }

            if (error.message) {
              alert('Invalid User Data');

            }

          });
      } else {
        alert('Please enter all fields');
        if (!title) {
          setErrSessTopic("Enter session topic");
        }
        if (!Content) {
          setErrcontent("Enter course content");
        }
        if (!Roomsize) {
          setErrroom("Enter room size");
        }
        if (!sessStTime) {
          setErrErrSessstime("Enter session start time");
        }
        if (!sessEndTime) {
          setSesseTime("Enter session end time");
        }
        if (!sDate) {
          setErrSessdate("Enter session start date");
        }
        if (!RecType) {
          setErrtype("Enter session type");
        }
        if (!QusStTime) {
          setErrSessqsTime("Enter  question start time");
        }
        if (!QusEndTime) {
          setErrSessqeTime("Enter  question end time");
        }
        if (!BrkStTime) {
          setErrSessbsTime("Enter  Break start time");
        }
        if (!BrkEndTime) {
          setErrSessbeTime("Enter  Break start time");
        }
      }
    }
    else if (RecType == 2 && timeblock == false) {

      if (RecType && title && Content && Roomsize && sDate && sessStTime && sessEndTime && QusStTime && QusEndTime && BrkStTime && BrkEndTime) {
        var result1 = moment(sessStTime).format(" HH:mm");
        var sesStTime = sDate + '' + result1 + ":00";

        //End date variable
        var result2 = moment(sessEndTime).format(" HH:mm");
        var sesEndDate = EndDate + '' + result2 + ":00";


        var sesEndTime = moment(sessEndTime).format(" HH:mm");

        var QussStTime = moment(QusStTime).format(" HH:mm");

        var QussEndTime = moment(QusEndTime).format(" HH:mm");

        var BrksStTime = moment(BrkStTime).format(" HH:mm");

        var BrkendTime = moment(BrkEndTime).format(" HH:mm");

        if (recTypeValue == '2') {
          var selectedWeekdays = [];
          days.map(function (value, index1) {

            if (value.status == true) {

              selectedWeekdays.push(value.id);

            }
          });

        }

        var startTime = new Date(sDate + ' ' + moment(sessStTime).format(" HH:mm"));
        var endTime = new Date(sDate + ' ' + moment(sessEndTime).format(" HH:mm"));
        var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
        var resultInMinutes = Math.round(difference / 60000);

        var bodyFormData = new FormData();

        bodyFormData.append('topic', title);
        bodyFormData.append('type', RecType);
        bodyFormData.append('content', Content);
        bodyFormData.append('start_time', sesStTime);
        bodyFormData.append('duration', resultInMinutes);

        if (RecType == 2) {
          bodyFormData.append('recurrence', 0);
        } else if (RecType == 8) {
          bodyFormData.append('recurrence', 1);
          bodyFormData.append('repeat_interval', 0);
          bodyFormData.append('end_date_time', sesEndDate);
          bodyFormData.append('recurrence_type', recTypeValue);
        }

        if (recTypeValue == 2) {
          bodyFormData.append('weekly_days', selectedWeekdays);
        }
        if (recTypeValue == 3) {
          //alert('hi');
          bodyFormData.append('monthly_days', selectaday);
        }

        //recTypeValue
        bodyFormData.append('paid', 0);
        //bodyFormData.append('schedule_for', mailId);
        bodyFormData.append('timezone', 'Asia/Kolkata');
        bodyFormData.append('room_size', Roomsize);
        //bodyFormData.append('password', password);
        bodyFormData.append('agenda', 'Test data');
        bodyFormData.append('end_time', sesEndTime + ":00");
        bodyFormData.append('start_qa_time', QussStTime + ":00");
        bodyFormData.append('end_qa_time', QussEndTime + ":00");
        bodyFormData.append('start_break_time', BrksStTime + ":00");
        bodyFormData.append('end_break_time', BrkendTime + ":00");
        bodyFormData.append('id', Meetingid);
        bodyFormData.append('meetingId', Meetingid);
        bodyFormData.append('_method', "put");

        var usertoken = localStorage.getItem('user');
        var test = usertoken.replace(/[#[$\]\\"]/g, '');
        usertoken = 'Bearer ' + test;
        axios({
          method: "post",
          url: baseUrl + "trainer/meeting",
          data: bodyFormData,
          headers: {
            'Accept': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
            'Authorization': usertoken
          }
        })
          .then(function (response) {
            //handle success
            if (response.status == '200') {

              notify();
              //reset the fields
              setTitle('');
              setSTime('');
              setDuration('');
              setMailID('');
              setPassword('');
              setAgenda('');
              setContent('');
              setRecType('');
              setRoomsize('');
              setSTime('');
              setSDate('');
              setDuration('');
              setsessStTime(null);
              setsessEndTime(null);
              setQusStTime(null);
              setQusEndTime(null);
              setBrkStTime(null);
              setBrkEndTime(null);
              window.location.reload();
              getmeetinglist('', 1,);
            }

          })
          .catch(function (error) {
            //handle error          
            if (error.response.data.error) {
              notifyfailer(error.response.data.error.start_time);
            }

            if (error.message) {
              alert('Invalid User Data');

            }

          });
      }
      else {
        alert('Please enter all fields');
        if (!title) {
          setErrSessTopic("Enter session topic");
        }
        if (!Content) {
          setErrcontent("Enter course content");
        }
        if (!Roomsize) {
          setErrroom("Enter room size");
        }
        if (!sessStTime) {
          setErrErrSessstime("Enter session start time");
        }
        if (!sessEndTime) {
          setSesseTime("Enter session end time");
        }


        if (!sDate) {
          setErrSessdate("Enter session start date");
        }
        if (!RecType) {
          setErrtype("Enter session type");
        }

      }
    }

    else {
      if (RecType && title && Content && Roomsize && sDate && sessStTime && sessEndTime) {
        var result1 = moment(sessStTime).format(" HH:mm");
        var sesStTime = sDate + '' + result1 + ":00";

        //End date variable
        var result2 = moment(sessEndTime).format(" HH:mm");
        var sesEndDate = EndDate + '' + result2 + ":00";


        var sesEndTime = moment(sessEndTime).format(" HH:mm");

        var QussStTime = moment(QusStTime).format(" HH:mm");

        var QussEndTime = moment(QusEndTime).format(" HH:mm");

        var BrksStTime = moment(BrkStTime).format(" HH:mm");

        var BrkendTime = moment(BrkEndTime).format(" HH:mm");

        if (recTypeValue == '2') {
          var selectedWeekdays = [];
          days.map(function (value, index1) {

            if (value.status == true) {

              selectedWeekdays.push(value.id);

            }
          });

        }

        var startTime = new Date(sDate + ' ' + moment(sessStTime).format(" HH:mm"));
        var endTime = new Date(sDate + ' ' + moment(sessEndTime).format(" HH:mm"));
        var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
        var resultInMinutes = Math.round(difference / 60000);

        var bodyFormData = new FormData();

        bodyFormData.append('topic', title);
        bodyFormData.append('type', RecType);
        bodyFormData.append('content', Content);
        bodyFormData.append('start_time', sesStTime);
        bodyFormData.append('duration', resultInMinutes);

        if (RecType == 2) {
          bodyFormData.append('recurrence', 0);
        } else if (RecType == 8) {
          bodyFormData.append('recurrence', 1);
          bodyFormData.append('repeat_interval', 0);
          bodyFormData.append('end_date_time', sesEndDate);
          bodyFormData.append('recurrence_type', recTypeValue);
        }

        if (recTypeValue == 2) {
          bodyFormData.append('weekly_days', selectedWeekdays);
        }
        if (recTypeValue == 3) {
          // alert('hi');
          bodyFormData.append('monthly_days', selectaday);
        }

        //recTypeValue
        bodyFormData.append('paid', 0);
        //bodyFormData.append('schedule_for', mailId);
        bodyFormData.append('timezone', 'Asia/Kolkata');
        bodyFormData.append('room_size', Roomsize);
        //bodyFormData.append('password', password);
        bodyFormData.append('agenda', 'Test data');
        bodyFormData.append('end_time', sesEndTime + ":00");
        bodyFormData.append('start_qa_time', QussStTime + ":00");
        bodyFormData.append('end_qa_time', QussEndTime + ":00");
        bodyFormData.append('start_break_time', BrksStTime + ":00");
        bodyFormData.append('end_break_time', BrkendTime + ":00");
        bodyFormData.append('id', Meetingid);
        bodyFormData.append('meetingId', Meetingid);
        bodyFormData.append('_method', "put");

        var usertoken = localStorage.getItem('user');
        var test = usertoken.replace(/[#[$\]\\"]/g, '');
        usertoken = 'Bearer ' + test;
        axios({
          method: "post",
          url: baseUrl + "trainer/meeting",
          data: bodyFormData,
          headers: {
            'Accept': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
            'Authorization': usertoken
          }
        })
          .then(function (response) {
            //handle success
            if (response.status == '200') {

              notify();
              //reset the fields
              setTitle('');
              setSTime('');
              setDuration('');
              setMailID('');
              setPassword('');
              setAgenda('');
              setContent('');
              setRecType('');
              setRoomsize('');
              setSTime('');
              setSDate('');
              setDuration('');
              setsessStTime(null);
              setsessEndTime(null);
              setQusStTime(null);
              setQusEndTime(null);
              setBrkStTime(null);
              setBrkEndTime(null);
              window.location.reload();
              getmeetinglist('', 1,);
            }

          })
          .catch(function (error) {
            //handle error          
            if (error.response.data.error) {
              notifyfailer(error.response.data.error.start_time);
            }

            if (error.message) {
              alert('Invalid User Data');

            }

          });
      }
      else {
        alert('Please enter all fields');
        if (!title) {
          setErrSessTopic("Enter session topic");
        }
        if (!Content) {
          setErrcontent("Enter course content");
        }
        if (!Roomsize) {
          setErrroom("Enter room size");
        }
        if (!sessStTime) {
          setErrErrSessstime("Enter session start time");
        }
        if (!sessEndTime) {
          setSesseTime("Enter session end time");
        }

        if (!QusStTime) {
          setErrSessqsTime("Enter  question start time");
        }
        if (!QusEndTime) {
          setErrSessqeTime("Enter  question end time");
        }
        if (!BrkStTime) {
          setErrSessbsTime("Enter  Break start time");
        }
        if (!BrkEndTime) {
          setErrSessbeTime("Enter  Break start time");
        }
        if (!sDate) {
          setErrSessdate("Enter session start date");
        }
        if (!RecType) {
          setErrtype("Enter session type");
        }
      }
    }


  };


  //filter functions
  const funFilType = (value) => {
    console.log(value);
  };
  const deletemeeting = (elem) => {

    swal({
      title: "Are you sure?",
      // text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          var bodyFormData = new FormData();
          bodyFormData.append('id', elem.id);
          bodyFormData.append('guard', "trainer");
          bodyFormData.append('_method', "delete");


          var usertoken = localStorage.getItem('user');
          var test = usertoken.replace(/[#[$\]\\"]/g, '');
          usertoken = 'Bearer ' + test;

          axios({
            method: "post",
            url: baseUrl + "trainer/meeting",
            data: bodyFormData,
            headers: {
              'Accept': 'application/json',
              'X-Requested-With': 'XMLHttpRequest',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
              'Authorization': usertoken
            }
          })
            .then(function (response) {
              //handle success
              if (response.status == '200') {
                swal(" Meeting deleted!", {
                  icon: "success",
                });

              }
            })
            .catch(function (error) {
              //handle error         
            });


        }
      });
  };


  const cancelbtn = () => {
    if (showtable == false) {
      window.location.reload();
    }

  }
  return (
    <>
      <>
        <Card >
          <CardContent>
            <Grid item md={12} sm={12} xs={12} lg={12} >
              {open == true && (<Typography fontWeight="fontWeightBold" style={{ fontWeight: "bold", marginBottom: "5px" }}>Update your profile below list</Typography>)}

              {Errname ? <Alert icon={false} severity="error">{Errname} </Alert> : " "}
              {Errdob ? <Alert icon={false} severity="error">{Errdob} </Alert> : " "}

              {Errage ? <Alert icon={false} severity="error">{Errage}</Alert> : " "}
              {Errimage ? <Alert icon={false} severity="error">{Errimage}</Alert> : " "}
              {Erremail ? <Alert icon={false} severity="error">{Erremail}</Alert> : " "}
              <br />
              {open == true && (<Typography variant="body1">
                Go to <Link style={{ cursor: "pointer", color: "#000 !important" }}
                  onClick={() => FunGoProfile()}
                >Profile page</Link>
              </Typography>)}
            </Grid>

            {Profilelist === true && (

              <Grid container spacing={1}>

                <Grid item md={6} sm={6} xs={12} lg={3}>
                  <FormControl style={{ minWidth: 250, marginTop: '12px' }} error={Errtype}>
                    <InputLabel id="demo-simple-select-label">Session type</InputLabel>
                    <br />
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      multiline
                      value={RecType}

                      onClick={e => clickRec(e)}
                    // onClick={e => setRecType(e.target.value)}  
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {sessiondata.length > 0 && sessiondata.map((item) => (
                        <MenuItem value={item.id}>{item.value}</MenuItem>
                      ))}

                    </Select>
                    <FormHelperText>{Errtype}</FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item md={6} sm={6} xs={12} lg={9}>
                  <TextField

                    margin="normal"
                    helperText={ErrSessTopic}
                    error={ErrSessTopic}
                    placeholder="Session Topic"
                    inputProps={{ maxLength: 100 }}
                    fullWidth
                    label="Session Topic"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Grid>

                <Grid item md={6} sm={6} xs={12} lg={9}>
                  <TextField
                    helperText={Errcontent}
                    error={Errcontent}
                    margin="normal"
                    multiline
                    placeholder="Course Content"
                    // inputProps={{maxLength: 100}} 
                    fullWidth
                    label="Course Content"
                    value={Content}
                    onChange={(e) => setContent(e.target.value)}
                  />
                </Grid>

                <Grid item md={6} sm={6} xs={12} lg={3}>
                  <TextField
                    helperText={Errroom}
                    error={Errroom}
                    margin="normal"
                    multiline
                    placeholder="Room Size"
                    inputProps={{ maxLength: 3 }}
                    pattern="^-?[0-9]\d*\.?\d*$"
                    fullWidth
                    label="Room Size"
                    value={Roomsize}

                    onChange={(e) => SetNewRoomSize(e)}
                  // value={Room} 

                  />
                </Grid>
                {selectbox === true && (
                  <>
                    <Grid item md={6} sm={6} xs={12} lg={3} style={{ marginTop: '18px' }}>
                      <ThemeProvider theme={theme}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <IOSSwitch
                                checked={state.checkedB}
                                onChange={handleChange}
                                name="checkedB"
                                color="red"
                              />
                            }
                            label={"unpaid"}
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </ThemeProvider>
                    </Grid>
                    <>

                      <Grid item md={6} sm={6} xs={12} lg={3} >
                        <TextField
                          fullWidth
                          helperText={ErrSessdate}
                          error={ErrSessdate}
                          label="Schedule Start Date"
                          style={{ top: '16px' }}
                          // type="datetime-local"
                          type="date"
                          format="MM/dd/yyyy"
                          //defaultValue={curDate}
                          value={sDate}
                          onChange={(e) => funseStDate(e.target.value)}
                          InputProps={{ inputProps: { min: yesterday } }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      {RecType == 8 && sdate === false && (
                        <>
                          <Grid item md={6} sm={6} xs={12} lg={3} >
                            <TextField
                              disabled={sDate ? false : true}
                              helperText={ErrSesedate}
                              error={ErrSesedate}
                              label="Schedule End Date"
                              style={{ top: '16px' }}
                              type="date"
                              defaultValue={curDate}
                              value={EndDate}
                              onChange={(e) => setEndDate(e.target.value)}
                              InputProps={{ inputProps: { min: sDate } }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>

                          <Grid item md={6} sm={6} xs={12} lg={3}>
                            <FormControl style={{ minWidth: 250, marginTop: '15px' }}>
                              <InputLabel id="demo-simple-select-label">Multi session Type</InputLabel>
                              <br />
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                multiline
                                value={recTypeValue}
                                onChange={(e) => setrecTypeValue(e.target.value)}
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {sessiontype.length > 0 && sessiontype.map((item) => (
                                  <MenuItem value={item.id}>{item.value}</MenuItem>
                                ))}

                              </Select>
                            </FormControl>
                          </Grid>
                        </>
                      )}

                      <Grid item md={6} sm={6} xs={12} lg={3} >
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                          <KeyboardTimePicker style={{ minWidth: 250, marginTop: '15px' }}
                            helperText={ErrSessstime}
                            error={ErrSessstime}
                            disabled={sDate ? false : true}
                            label="Session Start Time"
                            defaultValue={sessStTime}
                            value={sessStTime}
                            onChange={e => testfun(e)}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>

                      {/* <Grid item md={6} sm={6} xs={12} lg={3} >
                        
                        <FormControl style={{ minWidth: 250, marginTop: '13px' }}>
                          <InputLabel id="demo-simple-select-label">Session End Time</InputLabel>
                          <br />
                          <Select
                            labelId="demo-simple-select-label"
                            // id="demo-simple-select"
                            multiline
                            helperText={ErrSesseTime}
                            error={ErrSesseTime}
                            disabled={sessStTime ? false : true}
                            value={sessEndTime}
                            defaultValue={sessEndTime}
                            onChange={e => testfun1(e.target.value)}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>

                            {SessEndTimeArr.length > 0 && SessEndTimeArr.map((item) => (
                              <MenuItem value={item.dateTime}>{item.NormalTime}</MenuItem>
                            ))}

                          </Select>
                          <FormHelperText style={{ color: "red" }}>{ErrSesseTime != '' ? ErrSesseTime : ''}</FormHelperText>
                        </FormControl>
                      </Grid> */}

                      <Grid item md={6} sm={6} xs={12} lg={3} >


                        <FormControl style={{ minWidth: 250, marginTop: '13px' }}>
                          <InputLabel id="demo-simple-select-label">Session End Time</InputLabel>
                          <br />
                          <Select
                            labelId="demo-simple-select-label"
                            // id="demo-simple-select"
                            multiline
                            helperText={ErrSesseTime}
                            error={ErrSesseTime}
                            disabled={sessStTime ? false : true}
                            value={sessEndTime}
                            defaultValue={sessEndTime}
                            onChange={e => testfun1(e.target.value)}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>

                            {SessEndTimeArr.length > 0 && SessEndTimeArr.map((item) => (
                              <MenuItem value={item.dateTime}>{item.NormalTime}</MenuItem>
                            ))}

                          </Select>
                          <FormHelperText style={{ color: "red" }}>{ErrSesseTime != '' ? ErrSesseTime : ''}</FormHelperText>
                        </FormControl>
                      </Grid>



                    </>

                    {recTypeValue == 3 && (<Grid item md={8} sm={6} xs={12} lg={3}>
                      <FormControl style={{ minWidth: 250 }}>
                        <InputLabel id="demo-simple-select-label">Select a Day</InputLabel>
                        <br />
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select12"
                          value={selectaday}
                          onChange={(e) => setselectaday(e.target.value)}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {Monthday.length > 0 && Monthday.map((item) => (
                            <MenuItem value={item.id}>{item.value}</MenuItem>
                          ))}

                        </Select>
                      </FormControl>
                    </Grid>)}



                    <Grid item md={12} sm={12} xs={12} lg={12} style={{ marginTop: '20px' }}>
                      {sdate === false && recTypeValue == 2 && (<Grid class="days">
                        <Grid style={{ marginTop: "9px" }}>Weeks</Grid>
                        {days.length > 0 && days.map((item, index) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                name={item.id}
                                checked={item.status}
                                onChange={(e) => chkhandleChange("Status", e, index, item)}
                              />
                            }
                            label={item.value}
                          />
                        ))}

                      </Grid>)}

                    </Grid>
                    {checkblock == true && (
                      <>
                        <Grid item md={12} sm={12} xs={12} lg={12} >
                          <FormGroup row>

                            <FormControlLabel
                              control={<Checkbox
                                checked={timeblock}
                                onChange={(e) => setTimeblock(e.target.checked)}
                              />}
                              label="Do you want Question and Break time ?"
                            />
                          </FormGroup>
                        </Grid>   </>)}
                    {timeblock == true && (
                      <>
                        <Grid item md={6} sm={6} xs={12} lg={3} >
                          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardTimePicker
                              helperText ={ErrSessqsTime}
                              error ={ErrSessqsTime} 
                              label="Question Start Time"
                              defaultValue={QusStTime}
                              KeyboardButtonProps={{
                          'aria-label': 'change time',
                      }}
                           value={QusStTime}                    
                       onChange={e=>testfun2(e)}
                                    />
                             </MuiPickersUtilsProvider>                      */}

                          <FormControl style={{ minWidth: 250 }}>
                            <InputLabel id="demo-simple-select-label">Question Start Time</InputLabel>
                            <br />
                            <Select
                              labelId="demo-simple-select-label"
                              // id="demo-simple-select"
                              multiline
                              disabled={sessEndTime ? false : true}
                              defaultValue={QusStTime}
                              error={ErrSessqsTime}
                              value={QusStTime}
                              onChange={e => testfun2(e.target.value)}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>

                              {SessQStrTimeArr.length > 0 && SessQStrTimeArr.map((item) => (
                                <MenuItem value={item.dateTime}>{item.NormalTime}</MenuItem>
                              ))}

                            </Select>
                            <FormHelperText style={{ color: "red" }}>{ErrSessqsTime != '' ? ErrSessqsTime : ''}</FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item md={6} sm={6} xs={12} lg={3} >
                          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                            helperText ={ErrSessqeTime}
                            error ={ErrSessqeTime} 
                      label="Question End Time"
                 
                        defaultValue={QusEndTime}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                      }}
                      value={QusEndTime}
                      disabled={QusStTime ? false:true}
                      onChange={e=>testfun3(e)}                  
        
                                    />
                             </MuiPickersUtilsProvider>  */}

                          <FormControl style={{ minWidth: 250 }}>
                            <InputLabel id="demo-simple-select-label">Question End Time</InputLabel>
                            <br />
                            <Select
                              labelId="demo-simple-select-label"
                              // id="demo-simple-select"
                              multiline
                              error={ErrSessqeTime}
                              disabled={QusStTime ? false : true}
                              defaultValue={QusEndTime}
                              value={QusEndTime}
                              onChange={e => testfun3(e.target.value)}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>

                              {SessQEndTimeArr.length > 0 && SessQEndTimeArr.map((item) => (
                                <MenuItem value={item.dateTime}>{item.NormalTime}</MenuItem>
                              ))}

                            </Select>
                            <FormHelperText style={{ color: "red" }}>{ErrSessqeTime != '' ? ErrSessqeTime : ''}</FormHelperText>
                          </FormControl>

                        </Grid>

                        <Grid item md={6} sm={6} xs={12} lg={3} >
                          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                      label="Break Start Time"
                        defaultValue={BrkStTime}
                        helperText ={ErrSessbsTime}
                        error ={BrkStTime? "":ErrSessbsTime} 
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                      }}
                      value={BrkStTime}
                      onChange={e=>testfun4(e)}                  
                                    />
                             </MuiPickersUtilsProvider>  */}


                          <FormControl style={{ minWidth: 250 }}>
                            <InputLabel id="demo-simple-select-label">Break Start Time</InputLabel>
                            <br />
                            <Select
                              labelId="demo-simple-select-label"
                              // id="demo-simple-select"
                              multiline
                              disabled={sessEndTime ? false : true}
                              defaultValue={BrkStTime}
                              error={BrkStTime ? "" : ErrSessbsTime}
                              value={BrkStTime}
                              onChange={e => testfun4(e.target.value)}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>

                              {SessBrStrTimeArr.length > 0 && SessBrStrTimeArr.map((item) => (
                                <MenuItem value={item.dateTime}>{item.NormalTime}</MenuItem>
                              ))}

                            </Select>
                            <FormHelperText style={{ color: "red" }}>{ErrSessbsTime != '' ? ErrSessbsTime : ''}</FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item md={6} sm={6} xs={12} lg={3} >
                          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                        label="Break end Time"
                        helperText ={ErrSessbeTime}
                        error ={ErrSessbeTime} 
                        disabled={BrkStTime ? false:true}
                           defaultValue={BrkEndTime}
                           KeyboardButtonProps={{
                          'aria-label': 'change time',
                            }}      
                        value={BrkEndTime}
                   onChange={e=>testfun5(e)}     
                           />
                       </MuiPickersUtilsProvider>  */}
                          <FormControl style={{ minWidth: 250 }}>
                            <InputLabel id="demo-simple-select-label">Break end Time</InputLabel>
                            <br />
                            <Select
                              labelId="demo-simple-select-label"
                              // id="demo-simple-select"
                              multiline
                              disabled={BrkStTime ? false : true}
                              defaultValue={BrkEndTime}
                              error={ErrSessbeTime}
                              value={BrkEndTime}
                              onChange={e => testfun5(e.target.value)}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>

                              {SessBrEndTimeArr.length > 0 && SessBrEndTimeArr.map((item) => (
                                <MenuItem value={item.dateTime}>{item.NormalTime}</MenuItem>
                              ))}

                            </Select>
                            <FormHelperText style={{ color: "red" }}>{ErrSessbeTime != '' ? ErrSessbeTime : ''}</FormHelperText>
                          </FormControl>

                        </Grid>
                      </>
                    )}
                  </>)}
                <Grid item md={6} sm={6} xs={12} lg={12} style={{

                  justifyContent: "flex-end ",
                }}>
                  <div
                    className={classes.wrapper}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end ",
                    }}
                  >
                    <Button
                      style={{ backgroundColor: "#868686", marginRight: "10px", marginTop: "10px" }}
                      variant="contained"
                      color="primary"
                      //className={classes.BtnColor}
                      onClick={cancelbtn}
                    >
                      Cancel
                    </Button>
                    {Ubtn === true && (
                      <Button
                        style={{ backgroundColor: "#6bbb4d", marginTop: "10px" }}
                        variant="contained"
                        color="primary"
                        //className={classes.BtnColor}
                        onClick={createmeeting}


                      >
                        Create
                      </Button>)}
                    {Ubtn === false && (
                      <Button
                        style={{ backgroundColor: "#6bbb4d", marginTop: "10px" }}
                        variant="contained"
                        color="primary"
                        //className={classes.BtnColor}
                        onClick={updatemeeting}
                      >
                        Update
                      </Button>)}
                    <ToastContainer />
                  </div>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>

        <br />
        <br />
        {showtable === true && (
          <Card >
            <CardContent style={{ bottom: '20px' }}>
              <Grid container direction="row" alignItems="center">
                <Typography
                  variant="body1"
                  component="h1"
                  className={classes.h1Custom}
                >
                  Meeting List
                </Typography>

              </Grid>

              <Divider className={classes.divbotm} />
              <TableContainer>
                <Table className={classes.table} aria-label="customized table" >
                  <TableHead >
                    <TableRow className={classes.row} >
                      {/* <StyledTableCell align="center"></StyledTableCell> */}
                      <StyledTableCell align="center">Sl No</StyledTableCell>
                      <StyledTableCell align="left" > Topic
                        <Button
                          endIcon={
                            Sorting === true ? (
                              <ArrowDownwardIcon
                                style={{ color: '#fff' }}
                              />
                            ) : (
                              <ArrowUpwardIcon
                                style={{ color: '#fff' }}
                              />
                            )
                          }
                        //onClick={(e) => btnclksorting(Sorting)}
                        >

                        </Button>
                      </StyledTableCell>
                      <StyledTableCell align="left">  Meeting Time (Duration) <Button
                        endIcon={
                          Sorting === true ? (
                            <ArrowDownwardIcon
                              style={{ color: '#fff' }}
                              onClick={(e) => funFilType("asc")}
                            />
                          ) : (
                            <ArrowUpwardIcon
                              style={{ color: '#fff' }}
                              onClick={(e) => funFilType("desc")}
                            />
                          )
                        }
                      //onClick={(e) => btnclksorting(Sorting)}
                      >

                      </Button></StyledTableCell>
                      <StyledTableCell align="center">View </StyledTableCell>
                      <StyledTableCell align="center">  </StyledTableCell>
                      <StyledTableCell align="center">Status </StyledTableCell>
                      <StyledTableCell align="center">  </StyledTableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody  >

                    {meetingList.values.length > 0 && meetingList.values.map((elem, index) => (
                      <StyledTableRow key={elem.index} className={classes.row} >

                        <StyledTableCell component="th" scope="row" align="center"  >
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell align="center" style={{ height: '0px !important' }}>{elem.topic}</StyledTableCell>
                        <StyledTableCell align="center" >{elem.occ_start_time ? elem.occ_start_time : elem.start_time} ({elem.duration})</StyledTableCell>

                        <StyledTableCell align="center" >
                          <Button>
                            <MeetingView userid={elem} />
                          </Button>
                          {elem.meeting_id === null ?
                            <EditIcon onClick={e => editmeeting(elem)} /> : ''} &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                          {elem.meeting_id === null ?
                            <DeleteIcon onClick={e => deletemeeting(elem)} /> : ''}

                        </StyledTableCell>
                        <StyledTableCell align="center" ></StyledTableCell>
                        <StyledTableCell align="center" >
                          {elem.meeting_id === null ? "Wating for admin approval" : <Button
                            style={{ backgroundColor: "#6bbb4d" }}
                            variant="contained"
                            color="primary"
                            onClick={(e) => joinMeeting(elem)}
                          >
                            Start
                          </Button>
                          }</StyledTableCell>

                        <StyledTableCell align="center">
                          <Grid container className={classes.action}>
                          </Grid>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <br />
              <Grid container spacing={2} alignItems="center" >
                <Grid item md={4} sm={4} xs={5} lg={12}>
                  <Pagination count={totalpages} color="primary" defaultPage={page} page={page} onChange={checkmeeting} />
                </Grid>
              </Grid>
              {meetingList.length == 0 && <Grid container direction="row" alignItems="center">
                <Typography
                  variant="body1"
                  component="h1"
                  className={classes.h1Custom}
                >
                  {emtyarry}
                </Typography>
              </Grid>}
            </CardContent>
          </Card>
        )}
      </>

      {showMeeting === true && (
        <TZoom Meeting={MeetingData} />
      )}
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}


function validateNumber(e) {
  const pattern = /^[0-9]$/;

  return pattern.test(e.key)
}
