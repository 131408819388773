import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  visitsNumberContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  progressSection: {
    marginBottom: theme.spacing(1),
  },
  progressTitle: {
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginBottom: theme.spacing(1),
    backgroundColor: 'rgb(236, 236, 236)',
  },
  pieChartLegendWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    marginRight: theme.spacing(1),
  },
  legendItemContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  fullHeightBody: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  tableWidget: {
    overflowX: "auto",
  },
  progressBarPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  progressBarWarning: {
    backgroundColor: theme.palette.warning.main,
  },
  performanceLegendWrapper: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  legendElement: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  legendElementText: {
    marginLeft: theme.spacing(1),
  },
  serverOverviewElement: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
  },
  serverOverviewElementText: {
    minWidth: 145,
    paddingRight: theme.spacing(2),
  },
  serverOverviewElementChartWrapper: {
    width: "100%",
  },
  mainChartBody: {
    overflowX: "auto",
  },
  mainChartHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      flexWrap: "wrap",
    },
  },
  mainChartHeaderLabels: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      order: 3,
      width: "100%",
      justifyContent: "center",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  mainChartHeaderLabel: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(3),
  },
  mainChartSelectRoot: {
    borderColor: theme.palette.text.hint + "80 !important",
  },
  mainChartSelect: {
    padding: 10,
    paddingRight: 25,
  },
  mainChartLegentElement: {
    fontSize: "18px !important",
    marginLeft: theme.spacing(1),
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  },
  square:{
    borderRadius:"10%"
    
  },
 
  Test1:{

    color:"#26A69A"
  },
  Test2:{
   
    color:"#3b62af"
  },
  Test3:{
  
    color:"#69be47"
  },

  cardtext:{
    color:"#00897a",
    textAlign:"center",
    padding:theme.spacing(0),
    marginTop:theme.spacing(4),
    paddingBottom:theme.spacing(0) ,
  
  },
  cardicon1:{
    // marginTop:theme.spacing(1),
    fontSize:"50px",

 

  },
  cardicon2:{
    marginTop:theme.spacing(1),
    width:"43px"
   
  },
  cardtext1:{
  
    color:"#3b62af",
    textAlign:"center",
    padding:theme.spacing(0),
    marginTop:theme.spacing(4),
    paddingBottom:theme.spacing(0)

  },
  cardnumbers:{

  },
  maincard:{
    color:"#3B5FB7",

  },
  cardfooter:{
    // text-decoration: none;
    backgroundColor:"#01887a",
    padding:theme.spacing(0),
    color:"white",
  


  },
  cardfooter1:{
  
    backgroundColor:"#3b62af",
    padding:theme.spacing(1),
    color:"white"


  },
  InsidebackCLR1:{
  
    paddingBottom:theme.spacing(0 ),
    padding:theme.spacing(0),
  },
  impname:{
    fontSize:"12px"

  },
  specification:{
    color:"#69be47"
  },
  image:{
    padding:theme.spacing(0),

  },
  image1:{
    padding:theme.spacing(0),
    paddingBottom:theme.spacing(0)
  },
  tabletext:{
    fontSize:"15px",
    color:"#69e947"
  },
  tabletext1:{
    fontSize:"13px",
    color:"#3b62af",
  
  },
  table:{
    paddingLeft:theme.spacing(1)

  },
  secondsection:{
    fontSize:"12px",
    

  },
  square1:{
    borderRadius:"10%",
    backgroundColor:"#69be47"

    
  },
  cardbutton:{
    marginTop:theme.spacing(0),
    backgroundColor:"#69be47",
    borderRadius:"10px",
    fontSize:"10px",
    color:"white",
  textAlign:"center"

  },
  cardbutton1:{
    marginTop:theme.spacing(0),
    backgroundColor:"#d34d7a",
    borderRadius:"10px",
    fontSize:"10px",
    color:"white",
  textAlign:"center"

  },
  cardbutton2:{
    marginTop:theme.spacing(0),
    backgroundColor:"#43a5f8",
    borderRadius:"10px",
    fontSize:"10px",
    color:"white",
  textAlign:"center"

  },
  cardbutton3:{
    backgroundColor:"#2aa497",
    borderRadius:"10px",
    fontSize:"10px",
    color:"white",
  textAlign:"center",
  marginTop:theme.spacing(0),

  },
  count:{

    textAlign:"end",
    fontSize:"12px"


  },
  calender:{
  
    display:"flex",
    justifyContent: "space-around",
    textAlign:"center"
   },
  calendertext:{
 justifyContent: "space-around",
    // paddingLeft:theme.spacing(3)
      },
  calendertext1:{
    color:"#3b62af"

  },
  dot:{
    height:"5px",
    width:"5px",
    backgroundColor: "#69be47",
    borderRadius:"50%",
    display:" inline-block",
    marginBottom: "7px"


  },
  Cardfooter:{
    marginTop:theme.spacing(1),

  },
  tabletext2:{
    textAlign:"end",
    paddingRight:theme.spacing(2),
    fontSize:"12px",


  },
  playicon:{
    fontSize:"12px",
    

  },
  month:{
    marginTop:theme.spacing(0),
    textAlign:"center"

  },
  monthtext:{
    alignItems:"center"

  },
  pagenation1:{
    display:"flex"

  },
  pagenation:{
    display:"flex",
    justifyContent: "space-around",

  },
 
}));
