import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Divider from '@material-ui/core/Divider';
import Chart from '../charts';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {

  const [open, setOpen] = React.useState(false);
  const [userID, setUserId] = React.useState([]);
  var classes = useStyles();

  useEffect(() => {

    //setOpen(true);
    // setUserId(props.userid);

  }, []);




  const handleClickOpen = () => {
    setOpen(true);
    setUserId(props.userid);
    console.log("props.userid", props.userid)

  };
  // console.log("userID",userID)
  const handleClose = () => {

    setOpen(false);
  };

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        <Tooltip title="Quick-View">
          <VisibilityIcon className={classes.view} />
        </Tooltip>
      </Button>
      <Dialog fullWidth maxWidth="md"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{userID.topic}</DialogTitle  >
        <div dividers>
          <DialogContent style={{ backgroundColor: "rgb(241 237 237)" }}>
            <Grid container spacing={2}>

              <Grid item md={8} sm={6} xs={12} lg={7} >
                <Typography

                  className={classes.Custlablecolor}
                  color="textSecondary"
                >
                  scheduled date
                </Typography>
                <Typography variant="body1"> {moment(userID.start_time).format(" dddd DD/mm/yyyy")}</Typography>
              </Grid>

              <Grid item md={8} sm={6} xs={12} lg={5}>

                <Grid item md={8} sm={6} xs={12} lg={12}>
                  <Typography
                    className={classes.Custlablecolor}
                    color="textSecondary"
                  >
                    scheduled time
                  </Typography>
                  <Typography variant="body1">{moment(userID.start_time).format("hh:mm a")} to {userID.end_time}</Typography>
                </Grid>
                <Grid item md={8} sm={6} xs={12} lg={12}>
                  <Grid container spacing={1}>
                    {userID.start_break_time ?
                      <Grid item md={8} sm={6} xs={12} lg={6}>


                        <Typography
                          className={classes.Custlablecolor}
                          color="textSecondary"
                        >
                          Break time
                        </Typography>
                        <Typography variant="body1">{userID.start_break_time} to {userID.end_break_time}</Typography>

                      </Grid>
                      : ""}
                    {userID.start_qa_time ?
                      <Grid item md={8} sm={6} xs={12} lg={6}>
                        <Typography
                          className={classes.Custlablecolor}
                          color="textSecondary"
                        >
                          question time
                        </Typography>
                        <Typography variant="body1">{userID.start_qa_time} to {userID.end_qa_time}</Typography>

                      </Grid>
                      : ""}
                  </Grid>
                </Grid>
              </Grid>
              {/* ???? */}


            </Grid>


          </DialogContent>
          <DialogContent style={{ overflow: "hidden" }}>
            <Grid item md={8} sm={6} xs={12} lg={12}>
              <Grid container spacing={1}>
                <Grid item md={8} sm={6} xs={12} lg={9}>
                  <Typography
                    className={classes.Custlablecolor}
                    color="textSecondary"
                  >
                    Room Size:{userID.room_size}
                  </Typography>

                </Grid>

                <Grid item md={8} sm={6} xs={12} lg={3} style={{ textAlign: 'end' }}>
                  <Typography
                    className={classes.Custlablecolor}
                    color="textSecondary"
                  >
                    {userID.days} days to go
                  </Typography>

                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} sm={6} xs={12} lg={12}>
              <Chart userID={userID} />
            </Grid>
            <Grid item md={12} sm={6} xs={12} lg={12}>
              <Grid container spacing={1}>
                <Grid item md={6} sm={6} xs={12} lg={8}>
                  <Typography
                    className={classes.Custlablecolor}
                    color="textSecondary"
                  >
                    Invited user: {userID.invite}
                  </Typography>
                  <Typography variant="body1"></Typography>
                </Grid>
                <Grid item md={6} sm={6} xs={12} lg={4} style={{ textAlign: 'end' }}>
                  <Typography
                    className={classes.Custlablecolor}
                    color="textSecondary"
                  >
                    Entrolled user:{userID.total_enrollments_count}
                  </Typography>

                </Grid>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions style={{ backgroundColor: "#e6e4e4" }}>
            {/* <Button onClick={handleClose} color="primary">
            Disagree
          </Button> */}
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </div>

      </Dialog>
    </div>
  );
}
