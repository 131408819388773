import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
  PeopleAlt as StudentIcon,
  RecordVoiceOver as TrainerIcon,
  Duo as MeetingIcon,
  Settings as SettingsIcon,
  LocalMovies as LocalMovies,
} from "@material-ui/icons";
import DashboardIcon from '@material-ui/icons/Dashboard';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import ShopTwoIcon from "@material-ui/icons/ShopTwo";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { isMobile, deviceDetect } from "react-device-detect";
import { useTheme } from "@material-ui/styles";
import { useHistory, withRouter } from "react-router-dom";

import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";


const structure = [
  { id: 0, label: "Dashboard", link: "app/student/dashboard", icon: <HomeIcon /> },
  { id: 1, type: "divider" },
  { id: 2, type: "divider" },
  // { id: 2, label: "Tables", link: "/app/tables", icon: <TableIcon /> },
  // {
  //   id: 4,
  //   label: "UI Elements",
  //   link: "/app/ui",
  //   icon: <UIElementsIcon />,
  //   children: [
  //     { label: "Icons", link: "/app/ui/icons" },
  //     { label: "Charts", link: "/app/ui/charts" },
  //     { label: "Maps", link: "/app/ui/maps" },
  //   ],
  // },
  // { id: 5, type: "divider" },
  // { id: 6, type: "title", label: "HELP" },

  // { id: 11, type: "title", label: "PROJECTS" },

];

const structure2 = [
  { id: 0, label: "Dashboard", link: "/app/trainer/dashboard", icon: <DashboardIcon /> },
  { id: 1, type: "divider" },
  // { id: 2, label: "Tables", link: "/app/tables", icon: <TableIcon /> },
  // {
  //   id: 4,
  //   label: "UI Elements",
  //   link: "/app/ui",
  //   icon: <UIElementsIcon />,
  //   children: [
  //     { label: "Icons", link: "/app/ui/icons" },
  //     { label: "Charts", link: "/app/ui/charts" },
  //     { label: "Maps", link: "/app/ui/maps" },
  //   ],
  // },
  // { id: 5, type: "divider" },
  // { id: 6, type: "title", label: "HELP" },

  // { id: 11, type: "title", label: "PROJECTS" },

];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();
  const history = useHistory();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
    if (isMobile) {
      setPermanent(false);
    }
  });

  const btncallLink = (linkData) => {
    history.push(linkData);
  };

  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }

  return (
    <Drawer id='zooSidebar' 
      variant={isPermanent ? "permanent" : "temporary"}

      className={classNames(classes.drawer, {
        [classes.drawerClose]: isSidebarOpened,
        [classes.drawerOpen]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerClose]: isSidebarOpened,
          [classes.drawerOpen]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar}  
      />
      <div className={classes.mobileBackButton} 
      >
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {localStorage.getItem('access') == '2' &&
          <>
            <ListItem button style={{ left: "0px", width: "100%", top: "9px", height: "69px" }}> <DashboardIcon style={{ top: "0px", margin: "-16px -14px 0px 13px", }} onClick={e => { btncallLink('/app/trainer/dashboard') }} />
              <ListItemLink href="#/app/trainer/dashboard" style={{ left: "-48px", top: "24px", height: "21px", width: "103px", fontSize: "10px" }}>
                <ListItemText style={{ padding: "0px 11px 14px 0px" }} primary="Dashboard" />
              </ListItemLink>
            </ListItem>

            <ListItem button style={{ left: "0px", width: "100%", top: "9px", height: "69px" }}> <LiveTvIcon style={{ top: "0px", margin: "-16px -14px 0px 13px", }} onClick={e => { btncallLink('/app/trainer/Shedulemeeting') }} />
              <ListItemLink href="#/app/trainer/Shedulemeeting" style={{ left: "-48px", top: "24px", height: "21px", width: "103px", fontSize: "10px" }}>
                <ListItemText style={{ padding: "0px 11px 14px 0px" }} primary="New Meeting" />
              </ListItemLink>
            </ListItem>
            <ListItem button style={{ left: "0px", width: "100%", top: "9px", height: "69px" }}> <ShopTwoIcon style={{ top: "0px", margin: "-16px -14px 0px 13px", }} onClick={e => { btncallLink('/app/trainer/Gallery') }} />
              <ListItemLink href="#/app/trainer/Gallery" style={{ left: "-48px", top: "24px", height: "21px", width: "103px", fontSize: "10px" }}>
                <ListItemText style={{ padding: "0px 11px 14px 11px" }} primary="Gallery" />
              </ListItemLink>
            </ListItem>
          </>
        }
        {localStorage.getItem('access') == '1' &&
          <>
            <ListItem button style={{ left: "0px", width: "100%", top: "9px", height: "69px" }}> <DashboardIcon style={{ top: "0px", margin: "-16px -14px 0px 13px", }} onClick={e => { btncallLink('/app/student/dashboard') }} />
              <ListItemLink href="#/app/student/dashboard" style={{ left: "-48px", top: "24px", height: "21px", width: "103px", fontSize: "10px" }}>
                <ListItemText style={{ padding: "0px 0px 14px 0px" }} primary="Dashboard" />
              </ListItemLink>
            </ListItem>

            <ListItem button style={{ left: "0px", width: "100%", top: "9px", height: "69px" }}> <LiveTvIcon style={{ top: "0px", margin: "-16px -14px 0px 13px", }} onClick={e => { btncallLink('/app/student/MeetingList') }} />
              <ListItemLink href="#/app/student/MeetingList" style={{ left: "-48px", top: "24px", height: "21px", width: "103px" }}>
                <ListItemText style={{ padding: "0px 0px 14px 0px" }} primary="Meeting List" />
              </ListItemLink>
            </ListItem>

            <ListItem button style={{ left: "0px", width: "100%", top: "9px", height: "69px" }}> <ShopTwoIcon style={{ top: "0px", margin: "-16px -14px 0px 13px", }} onClick={e => { btncallLink('/app/student/Gallery') }} />
              <ListItemLink href="#/app/student/Gallery" style={{ left: "-48px", top: "24px", height: "21px", width: "103px" }}>
                <ListItemText style={{ padding: "0px 11px 14px 9px" }} primary="Gallery" />
              </ListItemLink>
            </ListItem>
          </>
        }

        {localStorage.getItem('access') == '0' &&
          <>
            <ListItem button style={{ left: "23px" }}> <HomeIcon onClick={e => { btncallLink('/app/admin/dashboard') }} />
              <ListItemLink href="#/app/admin/dashboard" style={{ left: "15px" }}>
                <ListItemText primary="Dashboard" />
              </ListItemLink>
            </ListItem>

            <ListItem button style={{ left: "23px" }}> <TrainerIcon onClick={e => { btncallLink('/app/admin/TrainerList') }} />
              <ListItemLink href="#/app/admin/TrainerList" style={{ left: "15px" }}>
                <ListItemText primary="Trainer List" />
              </ListItemLink>
            </ListItem>

            <ListItem button style={{ left: "23px" }}> <StudentIcon onClick={e => { btncallLink('/app/admin/StudentList') }} />
              <ListItemLink href="#/app/admin/StudentList" style={{ left: "15px" }}>
                <ListItemText primary="Student List" />
              </ListItemLink>
            </ListItem>

            <ListItem button style={{ left: "23px" }}> <MeetingIcon onClick={e => { btncallLink('/app/admin/MeetingList') }} />
              <ListItemLink href="#/app/admin/MeetingList" style={{ left: "15px" }}>
                <ListItemText primary="Meeting List" />
              </ListItemLink>
            </ListItem>

            <ListItem button style={{ left: "23px" }}> <SettingsIcon onClick={e => { btncallLink('/app/admin/Settings') }} />
              <ListItemLink href="#/app/admin/Settings" style={{ left: "15px" }}>
                <ListItemText primary="Settings" />
              </ListItemLink>
            </ListItem>

          </>
        }

      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
