import React, { useState, useEffect } from "react";
import configurl from "../../config1.json";
import axios from "axios";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";


import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide() {
  const [open, setOpen] = React.useState(false);
  const [ShowList, setShowList] = React.useState(true);
  const [ShowNew, setShowNew] = React.useState(false);
  const [mstate, setMstate] = React.useState('');
  const [country, setcountry] = React.useState([]);
  const [city, setCity] = React.useState('');
  const [ShowCUpdate, setShowCUpdate] = React.useState(false);
  const [ShowCSave, setShowCSave] = React.useState(true);
  const baseUrl = configurl.baseUrl[0];
  const [sState, setSState] = React.useState('1');
  const [sCountry, setSCountry] = React.useState('1');
  const [scity, setSCity] = React.useState('1');
  const [CName, setCName] = React.useState('');



  const handleClickOpen = () => {
    setOpen(true);
    setShowList(true);
    setShowNew(false);
  };
  const handleNewctryOpen = () => {
    setShowList(false);
    setShowNew(true);
  };
  const handleNewctryClose = () => {
    setShowList(true);
    setShowNew(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#3B5FB7',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);


  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
  });
  useEffect(() => {
    getcountry();
    chooseState(sCountry);
    getCity(scity);
  }, []);

  const editbutton = (row) => {
    setShowCUpdate(true);
    setShowCSave(false);
    handleNewctryOpen();
    setSCity(row);
    setCName(row.name)
  };
  const hidebtn = () => {
    setShowCUpdate(false);
    setShowCSave(true);
  };
  const onclick = () => {
    handleNewctryOpen();
    hidebtn();
  };

  const getcountry = () => {
    var usertoken = localStorage.getItem('user');
    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;
    axios({
      method: "get",
      url: baseUrl + "api/list/countries",
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }

    })
      .then(function (response) {

        //handle success
        if (response.status == '200') {
          setcountry(response.data.data);
        }
      })
      .catch(function (error) {
      });
  };

  const funState = (data) => {
    setSCountry(data);
    chooseState(data);
  };

  const funCity = (data) => {
    setSState(data);
    getCity(data);
  };


  const createCity = () => {

    var usertoken = localStorage.getItem('user');

    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;

    var bodyFormData = new FormData();

    bodyFormData.append('name', CName);
    bodyFormData.append('country_id', sCountry);
    bodyFormData.append('state_id', sState);
    bodyFormData.append('guard', 'city');

    axios({
      method: "post",
      url: baseUrl + "admin/csc",
      data: bodyFormData,
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }

    })
      .then(function (response) {

        //handle success
        if (response.status == '200') {
          getCity(sState);
          setCName('');
          handleNewctryClose();

        }

      })
      .catch(function (error) {
      });
  };

  const getCity = (data) => {

    var usertoken = localStorage.getItem('user');

    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;

    axios({
      method: "get",
      url: baseUrl + "api/list/cities?id=" + data,

      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }

    })
      .then(function (response) {

        if (response.status == '200') {
          setCity(response.data.data);

        }

      })
      .catch(function (error) {

      });

  };

  const chooseState = (data) => {

    var usertoken = localStorage.getItem('user');
    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;

    axios({
      method: "get",
      url: baseUrl + "api/list/states?id=" + data,

      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }

    })
      .then(function (response) {

        if (response.status == '200') {
          setMstate(response.data.data);
        }
      })
      .catch(function (error) {
      });

  };
  const cupdate = () => {

    var usertoken = localStorage.getItem('user');
    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;

    var bodyFormData = new FormData();

    bodyFormData.append('guard', 'city');
    bodyFormData.append('_method', 'put');
    bodyFormData.append('state_id', sState);
    bodyFormData.append('country_id', sCountry);
    bodyFormData.append('id', parseInt(scity.id));
    bodyFormData.append('name', CName);

    axios({
      method: "post",
      url: baseUrl + "admin/csc",
      data: bodyFormData,
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }

    })
      .then(function (response) {
        //handle success
        if (response.status == '200') {
          // alert("sucessfully");
          setCity(response.data.data);
          setShowNew(false);
          setShowList(true);

        }

      })
      .catch(function (error) {

      });
  };
  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        City
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom>
                City
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained"
                color="secondary" onClick={handleNewctryOpen}>
                New&nbsp;City
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>

          <Card >
            <CardContent>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={5} md={6} sm={6} lg={12}>
                  {ShowList === true &&
                    <>

                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <FormControl style={{ minWidth: 150 }}>
                            <InputLabel id="demo-simple-select-label">Country</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              multiline
                              value={sCountry}
                              onClick={e => funState(e.target.value)}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {country.map((item) => (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              ))}

                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl style={{ minWidth: 150 }}>
                            <InputLabel id="demo-simple-select-label">State</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              multiline
                              value={sState}
                              onClick={e => funCity(e.target.value)}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {mstate.length > 0 && mstate.map((item) => (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              ))}


                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <DialogContentText id="alert-dialog-slide-description">
                        <TableContainer >
                          <Table aria-label="customized table">
                            <TableHead>
                              <TableRow>

                                <StyledTableCell align="right">Sl no</StyledTableCell>

                                <StyledTableCell align="right">City</StyledTableCell>
                                <StyledTableCell align="right">Action</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {city.length > 0 && city.map((row, index) => (
                                <StyledTableRow key={row.index}>
                                  <StyledTableCell component="th" scope="row">
                                    {index + 1}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">{row.name}</StyledTableCell>
                                  {/* <StyledTableCell align="left">{row.fat}</StyledTableCell>
              <StyledTableCell align="left">Tenkasi</StyledTableCell> */}
                                  <StyledTableCell align="right">
                                    <Button    >
                                      <EditIcon

                                        onClick={() => editbutton(row)}
                                      />
                                    </Button>
                                    {/* <Button onClick={()=>cdelete(row)}>
                <DeleteForeverIcon/>
                </Button> */}

                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </DialogContentText> </>}

                  {ShowNew === true && <DialogContentText id="alert-dialog-slide-description">
                    <Grid container spacing={3}>
                      <Grid item xs={5} md={6} sm={6} lg={4}>
                        <FormControl margin="normal" required fullWidth>
                          <InputLabel id="Produc">Country</InputLabel>
                          <Select
                            fullWidth="true"
                            labelId="selProduct"
                            // error={vPProduct === true}
                            id="selProduct"
                            value={sCountry}
                            onClick={e => funState(e.target.value)}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {country.map((item) => (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={5} md={6} sm={6} lg={4}>
                        <FormControl margin="normal" required fullWidth>
                          <InputLabel id="Produc">State</InputLabel>
                          <Select
                            fullWidth="true"
                            labelId="selProduct"
                            // error={vPProduct === true}
                            id="selProduct"
                            value={sState}
                            onClick={e => funCity(e.target.value)}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {mstate.map((item) => (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={5} md={6} sm={6} lg={4}>
                        <TextField

                          margin="normal"
                          inputProps={{ style: { borderColor: '#F75740 !important' } }}
                          multiline
                          value={CName}
                          fullWidth
                          label="City Name"
                          type="text"
                          onChange={(e) => setCName(e.target.value)}
                        />
                      </Grid>

                      <Grid item xs={12} md={12} sm={12} lg={12} style={{ textAlign: "end" }}>
                        {ShowCSave === true &&
                          <Button variant="outlined"
                            color="primary"
                            onClick={createCity}
                          >
                            Save
                          </Button>
                        }
                        {ShowCUpdate === true &&
                          <Button variant="outlined"
                            color="primary"
                            onClick={cupdate}
                          >
                            Update
                          </Button>}
                      </Grid>

                    </Grid>


                  </DialogContentText>}
                </Grid>
              </Grid >
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
