import { mdiWindowShutter } from "@mdi/js";
import React from "react";
import { useHistory } from "react-router-dom";
var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut, SocialUser };

// ###########################################################
function loginUser( dispatch,history, setIsLoading, setError,Token,user) {
  setError(false);
  setIsLoading(true);
  debugger;
  if (Token) {
   
    setTimeout(() => {
      localStorage.setItem('id_token', 1)
      localStorage.setItem('user', Token)
      setError(null)
      setIsLoading(false)
      dispatch({ type: 'LOGIN_SUCCESS' })
     
            if(user == 'student'){
            //history.push('/app/dashboard')
            localStorage.setItem('access', '1');
            history.push('/app/student/dashboard');
            }
            else if(user == 'trainer'){
              localStorage.setItem('access', '2'); 
              history.push('/app/trainer/dashboard');
            }
            else if(user == 'admin'){
              localStorage.setItem('access', '0'); 
              history.push('/app/admin/dashboard');
            }
  }, 2000);
  } 
}

function SocialUser( dispatch,history,data, setIsLoading, setError) {
  //var history = useHistory();

  setError(false);
  setIsLoading(true);
  setTimeout(() => {
    localStorage.setItem('id_token', 1)
    localStorage.setItem('user', data.access_token)
    setError(null)
    setIsLoading(false)
    dispatch({ type: 'LOGIN_SUCCESS' })
           
          if(data.guard == 'student'){
          localStorage.setItem('access', '1');
          history.push('/app/student/dashboard');
          //window.location.reload();
          }
          else if(data.guard == 'trainer'){
            localStorage.setItem('access', '2'); 
            history.push('/app/trainer/dashboard');
            //window.location.reload();
          }
          else if(data.guard == 'admin'){
            localStorage.setItem('access', '0'); 
            history.push('/app/admin/dashboard');
           // window.location.reload();
          }
}, 1000);
}


// function loginUser(dispatch, login, password, history, setIsLoading, setError) {
//   setError(false);
//   setIsLoading(true);

//   if (!!login && !!password) {
   
//     setTimeout(() => {
//       localStorage.setItem('id_token', 1)
//       setError(null)
//       setIsLoading(false)
//       dispatch({ type: 'LOGIN_SUCCESS' })

//       history.push('/app/dashboard')
//     }, 2000);
//   } else {
//     dispatch({ type: "LOGIN_FAILURE" });
//     setError(true);
//     setIsLoading(false);
//   }
// }

// function signOut(dispatch, history) {
//   localStorage.removeItem("id_token");
//   dispatch({ type: "SIGN_OUT_SUCCESS" });
//   history.push("/login");
// }
function signOut(dispatch, history) {

  if(localStorage.getItem('access') == '1'){
    //history.push('/app/dashboard')
  localStorage.removeItem("id_token");
  localStorage.removeItem("user");
  localStorage.removeItem("access");
  dispatch({ type: "SIGN_OUT_SUCCESS" });

  history.push("/");
    }
  else if(localStorage.getItem('access') == '2'){
  
    localStorage.removeItem("id_token");
    localStorage.removeItem("user");
    localStorage.removeItem("access");
    dispatch({ type: "SIGN_OUT_SUCCESS" });
  
    history.push("/trainerLogin");

  }else if(localStorage.getItem('access') == '0'){
  
    localStorage.removeItem("id_token");
    localStorage.removeItem("user");
    localStorage.removeItem("access");
    dispatch({ type: "SIGN_OUT_SUCCESS" });
  
    history.push("/adminLogin");

  }



 
}
