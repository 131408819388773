import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import axios from "axios";
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import configurl from "../../config1.json";
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { Typography } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {


  const [open, setOpen] = React.useState(false);
  const [userID, setUserId] = React.useState([]);
  const [Tid, setTid] = React.useState();
  const [count, setCount] = React.useState();
  const [username, setUsername] = React.useState();
  const baseUrl = configurl.baseUrl[0];
  const [page, setPage] = useState(1);
  const [totalpages, settotalpages] = useState('');

  


  var classes = useStyles('');
  useEffect(() => {
    setTid(props.user.id);
    followlist(page);

  }, []);

  const handleClickOpen = () => {
    followlist();
    setOpen(true);
    setUserId(props.user);
    setTid(props.user.id);


  };

  const handleClose = () => {

    setOpen(false);
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#3B5FB7",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);


  const checkmeeting = (event, value) => {

    setPage(value);
    followlist(event, value);
  }

  const followlist = (event, value) => {
    if (value == undefined) {
      value = 1;
    };
    var bodyFormData = new FormData();
    var usertoken = localStorage.getItem('user');
    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;
    var student_id = parseInt(Tid);

    axios({
      method: "get",
      url: baseUrl + "admin/follower/trainer?guard=student&student_id=" + student_id + "&page=" + value + "&limit=10",

      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }

    })
      .then(function (response) {

        if (response.status == '200') {

          setUsername(response.data.data.values)
          setCount(response.data.data.total)
          settotalpages(response.data.data.total_pages);

        }
      })
      .catch(function (error) {
      });

  };
  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        <Tooltip title="Quick-View">
          <PersonAddIcon className={classes.view} />
        </Tooltip>
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted

        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Follower list"}</DialogTitle>
        {/* {username ?  */}
        <DialogContent>

          {count == 0 ?

            <Typography >   Follower count: 0 </Typography>
            :
            <Grid container spacing={2}>

              <Grid item md={12} sm={12} xs={12} lg={12}>
                <Typography
                  variant="body1"
                  component="h1"
                  className={classes.h1Custom}
                >
                  Total count: {count}
                </Typography>
                <TableContainer component={Paper}>

                  <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="right"></StyledTableCell>
                        <StyledTableCell align="right">Sl no</StyledTableCell>
                        <StyledTableCell align="right"></StyledTableCell>
                        <StyledTableCell align="center">Name</StyledTableCell>
                        <StyledTableCell align="right"></StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {username ? username.map((row, index) => (
                        <StyledTableRow key={row.index}>
                          <StyledTableCell align="center"></StyledTableCell>
                          <StyledTableCell align="center" component="th" scope="row">
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell align="right"></StyledTableCell>
                          <StyledTableCell align="left">{row.trainer.name ? row.trainer.name : ""}</StyledTableCell>
                          <StyledTableCell align="right"></StyledTableCell>


                        </StyledTableRow>
                      )) : ""}
                    </TableBody>
                  </Table>

                </TableContainer>
                <br />
                <Grid container spacing={2} alignItems="center" >
                  <Grid item md={4} sm={4} xs={5} lg={12}>
                    <Pagination count={totalpages} color="primary" defaultPage={page} page={page} onChange={checkmeeting} />
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          }
        </DialogContent>
        {/* : <Typography>Total count :0 </Typography> } */}
        <DialogActions>

          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}