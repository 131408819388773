import React, { useState } from "react";

import { useTheme } from "@material-ui/styles";

import {
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  AcUnit as MeetingIcon,
  ArrowBack as ArrowBackIcon,
  PeopleAlt as StudentIcon,
  RecordVoiceOver as TrainerIcon
} from "@material-ui/icons";

import ShopTwoIcon from '@material-ui/icons/ShopTwo';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ShareIcon from '@material-ui/icons/Share';
//import Zoom  from '../../../components/zoomNew';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { Grid,CardContent,Card,TextField,Avatar,FormControl,Select,MenuItem,InputLabel,Typography,Divider } from "@material-ui/core";
// styles
import useStyles from "./styles";
import DividerWithText from '../../../components/DividerWithText';
 import "./zstyle.css";
import image  from "../image/images.jfif"


const mainChartData = getMainChartData();

const PieChartData = [
  { name: "Group A", value: 400, color: "primary" },
  { name: "Group B", value: 300, color: "secondary" },
  { name: "Group C", value: 300, color: "warning" },
  { name: "Group D", value: 200, color: "success" },
];

export default function Dashboard(props) {
  var classes = useStyles();
  var theme = useTheme();
  const [showFPTYPE, setShowFPTYPE] = useState(false);
  const [joinMeeting, setjoinMeeting] = useState(false);
  // local
  var [mainChartState, setMainChartState] = useState("monthly");

  return (
    <>
     <Grid container >
          <Grid item xs={12} md={12} lg={8} sm={12}>

                  <Grid container spacing={2}>
                      <Grid item xs={12} md={4} lg={12} sm={4} lg={3}>
                            <Card className={classes.cards}>
                              <CardContent className={classes.cardtext} style={{paddingBottom:"0px"}}>
                              <Grid container >
                                  
                                  <Grid  item xs={12}sm={6}lg={6}>
                                        <Typography   >
                                              20  <br/>
                                              Trainer
                                          </Typography> 
                                          </Grid>
                                          <Grid  item xs={12}sm={6}lg={6}>
                                    <StudentIcon className={classes.cardicon1}/>
                                  
                                  </Grid>
                               
                                            
                                  <Grid  item md={12} xs={12}sm={12}lg={12}>
                                        <Typography /*variant="h5"*/  className={classes.cardfooter}  >
                                            View More
                                          </Typography> 
                                  </Grid>
                               
                                  
                                </Grid>    
                              </CardContent>  
                            </Card>  
                      </Grid>
                      <Grid item xs={12} md={4} lg={12} sm={4} lg={3}>
                            <Card className={classes.InsidebackCLR1} >
                              <CardContent className={classes.cardtext1} style={{paddingBottom:"0px"}} >
                            
                                <Grid container >
                                  
                                  <Grid  item xs={12}sm={6}lg={6}>
                                        <Typography /*variant="h5"*/  >
                                              100  <br/>
                                              Students
                                          </Typography> 
                                          </Grid>
                                          <Grid  item xs={12}sm={6}lg={6}>
                                    <StudentIcon className={classes.cardicon1}/>
                                  
                                  </Grid>
                               
                                            
                                  <Grid  item md={12} xs={12}sm={12}lg={12}>
                                        <Typography /*variant="h5"*/  className={classes.cardfooter1} >
                                            View More
                                          </Typography> 
                                  </Grid>
                               
                                  
                                </Grid>  
                              </CardContent>  
                            </Card>  
                      </Grid>
                      
                      <Grid item xs={12} md={4} lg={12} sm={4} lg={3}>
                            <Card className={classes.InsidebackCLR1}>
                              <CardContent className={classes.cardtext1} style={{paddingBottom:"0px"}} >
                            
                                <Grid container >
                                  
                                  <Grid  item xs={12}sm={6}lg={6}>
                                        <Typography /*variant="h5"*/  >
                                              500  <br/>
                                              Meetings 
                                          </Typography> 
                                          </Grid>
                                          <Grid  item xs={12}sm={6}lg={6}>
                                    <MeetingIcon className={classes.cardicon1}/>
                                  
                                  </Grid>
                                
                              
                                  <Grid  item md={12} xs={12}sm={12}lg={12}>
                                        <Typography /*variant="h5"*/  className={classes.cardfooter1} >
                                            View More
                                          </Typography> 
                                  </Grid>
                                </Grid>
                                  
                                
                              </CardContent>  
                            </Card>  
                      </Grid>

                      <Grid item xs={12} md={12} lg={12} sm={12} lg={11}>
                            <Card className={classes.InsidebackCLR}>
                              <CardContent>
                            
                                <Typography variant="body1">
                                 
                                  &nbsp;Data&nbsp;List
                                </Typography>
                              
                                <Grid item xs={12}sm={12}lg={12}>  
                                  <Divider />
                                </Grid>

                                <Grid container spacing={1}>
                                  
                                  <Grid  item xs={12}sm={12}lg={12}>
                                        <Card className={classes.image}>
                                            <CardContent  className={classes.image1} style={{paddingBottom:"0px"}}>
                                              <Grid container >
                                                    
                                                      <Grid  item xs={6}sm={6}lg={9}  className={classes.table}>  
                                                    
                                                  
                                                    
                                                      </Grid>
                                                </Grid>  
                                            </CardContent>     
                                        </Card>
                                  </Grid> 
                                </Grid>  
                              </CardContent>  
                            </Card>  
                      </Grid>
                      </Grid> 
                    </Grid>   
             

          <Grid item xs={12} md={12} lg={4} sm={12}>
            <div>
                <div >
                  <Grid container spacing={1}>
                
                  <Grid item xs={12} md={12} lg={12} sm={12}>
                  <Card className={classes.card}>
                   <CardContent>
                      admin
                     </CardContent>
               </Card>
                     </Grid> 
         

                  </Grid> 
                </div>
            </div>
          </Grid> 
     </Grid>
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
