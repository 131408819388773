import React,{ useState } from "react";
import { Grid,
  CircularProgress,
  Typography,
  Button,
  Card,
  Tabs,
  Tab,
  TextField,
  Fade, } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import axios from "axios";
import FormData from "form-data";
import configurl from "../../config1.json";
//import "./zstyle.css";

// styles
import useStyles from "./styles";

// logo
import logo from "./home.png";
import logolog from "./logolog.png";
import OtpInput from 'react-otp-input';
import google from "../../images/google.svg";

// context
import { useUserDispatch, loginUser } from "../../context/UserContext";

const Login = (props) =>{
//function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  var [nameValue, setNameValue] = useState("");
  var [otp, setOTP] = useState("");
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [cpassword, setcpassword] = useState("");
  var [ccpassword, setccpassword] = useState("");
 //show / hide varables
  const [signupf ,setSignupf]= useState(true);
  const [verfy, setVerify] = useState(false);
  const [showpass, setshowpass] = useState(false);
  const baseUrl = configurl.baseUrl[0];

  const OTPhandleChange = (otp) => {
    setOTP(otp);
  };

  const createpassword=() =>{

    var bodyFormData = new FormData();
    bodyFormData.append('password', cpassword);
    bodyFormData.append('password_confirmation', ccpassword);
    bodyFormData.append('guard', 'student');
    bodyFormData.append('otp', otp);
    bodyFormData.append('mobile', loginValue);
    bodyFormData.append('mcc_id', '2');
   
  
    axios({
      method: "post",
      url: baseUrl+"auth/password",
      data: bodyFormData,
      headers: { 
                 'Accept': 'application/json',
                 'X-Requested-With': 'XMLHttpRequest',
                 'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*' ,
                  'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, PATCH, DELETE'}
    })
      .then(function (response) {
        //handle success
        if(response.status =='200'){
          console.log(response.data.data['access_token']);
         alert('Create Your Password!');
         let Token = JSON.stringify(response.data.data['access_token']);
         loginUser( userDispatch,props.history,setIsLoading,setError,Token,'student')
        }
        
      })
      .catch(function (response) {
        //handle error
      //  console.log(response);
      });
  };

  const regularLogin =(mobile,pws,e) =>{
    e.preventDefault();
    var bodyFormData = new FormData();
    bodyFormData.append('email', mobile);
    bodyFormData.append('password', pws);
    bodyFormData.append('guard', 'admin');
    // bodyFormData.append('mcc_id', '2');

    
    axios({
      method: "post",
      url: baseUrl+"auth/login",
      data: bodyFormData,
      headers: { 
                 'Accept': 'application/json',
                 'X-Requested-With': 'XMLHttpRequest',
                 'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*' ,
                  'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, PATCH, DELETE'}
    })
      .then(function (response) {
        //handle success
        if(response.status =='200'){
          console.log(response.data.data['access_token']);
        
         let Token = JSON.stringify(response.data.data['access_token']);
         loginUser( userDispatch,props.history,setIsLoading,setError,Token,'admin')
        }
        
      })
      .catch(function (Error) {
        //handle error
        debugger;
        if(Error.message){
          alert('Invalid User Data');
        }
      // console.log(Error.message);
      });
  };

  const verifyotp =()=>{

    var bodyFormData = new FormData();
    bodyFormData.append('name', nameValue);
    bodyFormData.append('guard', 'student');
    bodyFormData.append('otp', otp);
    bodyFormData.append('mobile', loginValue);
    bodyFormData.append('mcc_id', '2');
   
  
    axios({
      method: "post",
      url: baseUrl+"auth/otp",
      data: bodyFormData,
      headers: { 
                 'Accept': 'application/json',
                 'X-Requested-With': 'XMLHttpRequest',
                 'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*' ,
                  'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, PATCH, DELETE'}
    })
      .then(function (response) {
        //handle success
        if(response.status =='200'){
          setSignupf(false);
          setVerify(false);
          setshowpass(true);
         alert('your otp verifyed!');
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };


  const userRegister = (username,mailId) =>{
 // console.log(username+mailId+pws);
 
   var bodyFormData = new FormData();
   bodyFormData.append('name', username);
   bodyFormData.append('guard', 'student');
   bodyFormData.append('mobile', mailId);
  //  bodyFormData.append('mcc_id', '2');

   axios({
     method: "post",
     url: baseUrl+"auth/register",
     data: bodyFormData,
     headers: { 
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json',
               'Access-Control-Allow-Origin': '*' ,
                 'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, PATCH, DELETE'}
   })
     .then(function (response) {
       //handle success
       console.log(response.status);
       
      if(response.status =='200'){
        setSignupf(false);
        setshowpass(false);
        setVerify(true);
      }
     })
     .catch(function (error) {
       //handle error
       //console.log(response);
       console.log(error.response.status);
       if(error.response.status == 422){
          alert('Given mobile number is already exist!');
       }

     });

};


  return (
    <Grid container className={classes.container} style={{overflow:'scroll'}}>
      
      <div className={classes.formContainer} >
       
        <div className={classes.form}>
        <Card style={{padding:"22px",borderRadius:'17px',color:"#3B5FB7",backgroundColor:'aliceblue'}} >
        
        <Typography variant="h1" className={classes.greeting} >
        <img src={logolog} alt="logo" style={{width:'50px'}} /*className={classes.logotypeImage}*/ /><br/>
             Admin Login
        </Typography> 
        
         
          {activeTabId === 0 && (
            <React.Fragment>
              
             
             
              <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  Something is wrong with your login or password :(
                </Typography>
              </Fade>
              <form >
              <TextField
             // variant="outlined"
                id="email"
                label="Email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={e => setLoginValue(e.target.value)}
                margin="normal"
                placeholder="Email"
                type="text"
                fullWidth
              />
              <TextField
             // variant="outlined"
                label="Password"
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                onChange={e => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Password"
                type="password"
                fullWidth
              />
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                  type='submit'
                    disabled={
                      loginValue.length < 10 || passwordValue.length < 8
                    }
                    onClick={(e) =>regularLogin(loginValue, passwordValue,e)}
                    // onClick={() =>
                    //   loginUser(
                    //     userDispatch,
                    //     loginValue,
                    //     passwordValue,
                    //     props.history,
                    //     setIsLoading,
                    //     setError,
                    //   )
                    // }
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Login
                  </Button>
                )}
                
                <Button
                  color="primary"
                  size="large"
                  className={classes.forgetButton}
                >
                  Forget Password
                </Button>
              </div>
              </form>

            </React.Fragment>
          )}
          {activeTabId === 1 && (
            
            <React.Fragment>
             
             {signupf === true && ( 
               <>
              <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  Something is wrong with your login or password :(
                </Typography>
              </Fade>
             
              <TextField
                id="name"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={nameValue}
                onChange={e => setNameValue(e.target.value)}
                margin="normal"
               // variant="outlined"
                label="Name"
                placeholder="Name"
                type="text"
                fullWidth
              />
              <TextField
                id="email"
              //  variant="outlined"
                label="Mobile Number"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                  maxLength: 10
                }}
                value={loginValue}
                onChange={e => setLoginValue(e.target.value)}
                margin="normal"
                placeholder="Mobile Number"
                type="text"
                fullWidth
              />
            
              <div className={classes.creatingButtonContainer}>
                {isLoading ? (
                  <CircularProgress size={26} />
                ) : (
                  <Button
                  onClick={() =>userRegister(nameValue, loginValue)}
                    disabled={
                      loginValue.length < 10  ||
                     // passwordValue.length === 0 ||
                      nameValue.length === 0
                    }
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.createAccountButton}
                  >
                    Continue
                  </Button>
                )}
              </div>

   </>         
)}
          
        {verfy === true && ( 
               <> 
              <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  Something is wrong with your login or password :(
                </Typography>
              </Fade>
             <div  style={{ margin: "-9px 48px 13px 24px" }} >
              <OtpInput
              
                value={otp}
               // onChange={(e) => setOTP(e.target.value)}
                onChange={OTPhandleChange}
                //containerStyle={true}
                inputStyle={classes.otpfieldstyle}
                numInputs={6}
                isInputNum={true}
                shouldAutoFocus
                separator={<span>-</span>}
              />
             </div>
            
              <div className={classes.creatingButtonContainer}>
                {isLoading ? (
                  <CircularProgress size={26} />
                ) : (
                  <Button
                  onClick={() =>verifyotp()}
                    disabled={
                      otp.length < 6 
                    }
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.createAccountButton}
                  >
                   Verify and Proceed
                  </Button>
                )}

              </div>

   </>         
)} 

 {showpass === true && ( 
               <> 
              
                <Typography  >
                 Create Password
                </Typography>
             
             
              <TextField
               
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={cpassword}
                onChange={e => setcpassword(e.target.value)}
                margin="normal"
              //  variant="outlined"
                label="New Password"
                placeholder="New Password"
                type="password"
                fullWidth
              />

              <TextField
               // variant="outlined"
                label="Confirm Password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={ccpassword}
                onChange={e => setccpassword(e.target.value)}
                margin="normal"
                placeholder="Confirm Password"
                type="password"
                fullWidth
              /> 
              <div className={classes.creatingButtonContainer}>
                {isLoading ? (
                  <CircularProgress size={26} />
                ) : (
                  <Button
                  onClick={() =>createpassword()}
                    disabled={
                      cpassword != ccpassword ||
                      cpassword =='' ||
                      ccpassword == '' ||
                      cpassword.length < 8
                    }
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.createAccountButton}
                  >
                   Submit
                  </Button>
                )}

              </div>

    </>         
)}

            </React.Fragment>

          )}
          </Card>
        </div>
         <Typography color="primary" className={classes.copyright} style={{color:"white"}}>
              I am already a member     <a style={{ textDecoration: 'none', color: 'inherit' }} onClick={() => window.location.reload(false)} rel="noopener noreferrer" ><b>Login</b></a>
        {/* © 2014-{new Date().getFullYear()} <a style={{ textDecoration: 'none', color: 'inherit' }} href="https://flatlogic.com" rel="noopener noreferrer" target="_blank">Flatlogic</a>, LLC. All rights reserved. */}
        </Typography> 
      </div>
    </Grid>
  );
}

export default withRouter(Login);
