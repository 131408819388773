import React, { useState } from 'react';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { useHistory } from "react-router-dom";
import { Grid, CircularProgress } from "@material-ui/core";
import axios from "axios";
import useStyles from "../pages/login/styles";
import configurl from "../config1.json";
import swal from 'sweetalert';
import "./CStyle.css"

import { useUserDispatch, loginUser, SocialUser } from "../context/UserContext";
import { Button } from '@material-ui/core';

const clientId = "398510712854-rkei64ns7dvm8tn2014106ketsbbo08t.apps.googleusercontent.com";

function Login(props) {
  var userDispatch = useUserDispatch();
  var classes = useStyles();
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  const [showloginButton, setShowloginButton] = useState(false);
  const [showlogoutButton, setShowlogoutButton] = useState(false);
  const baseUrl = configurl.baseUrl[0];
  var history = useHistory();

  const onLoginSuccess = (res) => {
    console.log("res", res)
    var bodyFormData = new FormData();
    bodyFormData.append('guard', "student");
    bodyFormData.append('access_token', res.accessToken);
    bodyFormData.append('driver', "google");

    axios({
      method: "post",
      url: baseUrl + "auth/social/login",
      data: bodyFormData,
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE'
      }
      //  'Authorization': usertoken}
    })
      .then(function (response) {
        //handle success
        if (response.status == '200') {
          //alert("123")
          setShowloginButton(false);
          setShowlogoutButton(true);
          let Token = JSON.stringify(response.data.access_token);
          SocialUser(userDispatch, props.moveF, response.data, setIsLoading, setError);
          // loginUser( userDispatch,props.history,setIsLoading,setError,Token,response.data.guard)


        }

      })
      .catch(function (error) {
        //handle error        

      });

  };

  const onLoginFailure = (res) => {
  };

  const onSignoutSuccess = () => {
    //  alert("You have been logged out successfully");
    setShowloginButton(true);
    setShowlogoutButton(false);
    //history.push('/');
  };

  return (
    <div>
      {/* { showloginButton === false && <Button
               
                onClick={(e) => setShowloginButton(true)}>G Sign in</Button>
                } */}
      {/* { showloginButton ? */}
      {isLoading ? (
        <CircularProgress size={26} className={classes.loginLoader} />
      ) : (
        <GoogleLogin
          clientId={clientId}
          buttonText="Sign In"
          onSuccess={onLoginSuccess}
          onFailure={onLoginFailure}
          cookiePolicy={'single_host_origin'}
          isSignedIn={false}
          className="google"

        />)}
      {/* : null} */}

      {/* { showlogoutButton ?
                <GoogleLogout
                    clientId={clientId}
                    buttonText="Sign Out"
                    onLogoutSuccess={onSignoutSuccess}
                >
                </GoogleLogout> : null
            } */}
    </div>
  );
}
export default Login;