import React, { useCallback, useState ,useEffect} from "react";
import { PieChart, Pie, Sector,Cell } from "recharts";
import mettinglist from "../components/popup/Tmeetingview"

const data = [
  { name: "Invited", value: 100 },
  { name: "Entrolled", value: 50 },
 
];
const COLORS = ["#3B5FB7", "rgb(107, 187, 77)"];
const renderActiveShape = (props) => {

 

  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,

  } = props;

  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`User ${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {/* {`(Rate123 ${(percent * 100).toFixed(2)}%)`} */}
      </text>
    </g>
  );
};

export default function App(props) {

  const [activeIndex, setActiveIndex] = useState(0);
  let [invite, setInvite] = useState("");
  let [total_enrollments_count, setTotal_enrollments_count] = useState("");
  
  
const data1 = [
  { name: "Invited", value: props.userID.invite },
  { name: "Entrolled", value: props.userID.total_enrollments_count },
 
];
  console.log("meeting details",props.userID);

  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );
  useEffect(() => {
    // console.log("1123",props)
  //console.log("1",props.userID)
  //console.log("2",props.inviteuser)
  //console.log("3",props.entrolleduser)
getvalue()

}, []);

const getvalue=()=>{
  setInvite(props.invite);
  setTotal_enrollments_count(props.total_enrollments_count);
}

  return (
    <PieChart width={700} height={230}>
      <Pie
        activeIndex={activeIndex}
        activeShape={renderActiveShape}
        data={data1}
        cx={470}
        cy={100}
        innerRadius={60}
        outerRadius={80}
        fill="#8884d8"
        dataKey="value"
        onMouseEnter={onPieEnter}
           >
               {data1.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
}
