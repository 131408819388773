import React, { useState, useEffect } from "react";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import moment from "moment";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from '@material-ui/pickers';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(false);
  const [selectedDate, setselectedDate] = useState( moment().format('YYYY-MM-DD'));
  // const[day1,setDay1]=('')
  const [isOpen, setIsOpen] = useState(false);
  const [smonth, setSmonth] = useState();
    useEffect(() => {
    dash_days() ;  
  });
const btnContinue =(value) =>{
  setselectedDate(moment(value).format('YYYY-MM-DD'))
  props.finalResult(value);
};
const dash_days=()=>{
    var month= moment(selectedDate).format('MMMM');
    setSmonth(month); 
};
  const handleClickOpen = () => {
    setOpen(true);
    setIsOpen(true)
  };
  const handleClose = () => {
    setOpen(false);

  };

  return (
    <div>
      <Button  color="primary" onClick={handleClickOpen}>
    {smonth}
      </Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
      onClose={handleClose}
          margin="normal"
          id="date-picker-dialog"
          label="Date picker dialog"
          format="MMMM"
          value={selectedDate}
          //onChange={(e) =>handleDateChange(e.target.value)}
          onChange={(e) => btnContinue(e)}
          //onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          open={isOpen}
        />
              </MuiPickersUtilsProvider>   
      </Dialog>
    </div>
  );
}