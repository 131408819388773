import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/styles";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Pagination from '@material-ui/lab/Pagination';
import moment from "moment";
import CircularProgress from '@material-ui/core/CircularProgress';

import FormData from "form-data";
import configurl from "../../../config1.json";
import TZoom  from '../../../components/zoomTrainer';
import { ToastContainer, toast } from 'react-toastify';
import Logolog from "./logolog.png";
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';
import axios from "axios";
import MeetingView from "../../../components/popup/MeetingView";
import MeetingEdit from "../../../components/popup/MeetingEdit";
import MeetingDelete from "../../../components/popup/MeetingDelete";

// styles
import { withStyles, makeStyles } from '@material-ui/core/styles';
import useStyles from "./styles";
import "./zstyle.css";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';

const useStyles1 = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));


const StyledTableCell = withStyles((theme) => ({
  head: {
    //backgroundColor: theme.palette.common.black,
    backgroundColor: '#3B5FB7',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function Shedulemeeting(props) {
  var classes = useStyles();
  var theme = useTheme();
  var classes1 = useStyles1();
  
  const [curDate, setCurDate] = useState('');
  const [title,setTitle]= useState('');
  const [type,settype]= useState(2);
  const [sTime,setSTime] = useState('');
  const [duration, setDuration]=useState('');
  const [mailId, setMailID]=useState('');
  const [password, setPassword]=useState('');
  const [agenda,setAgenda]=useState('');
  const [meetingList, setmeetingList]=useState([]);
  const [Mtype,setMtype]=useState('all');
  const baseUrl = configurl.baseUrl[0];
  const [showMeeting,setShowMeeting] = useState(false);
  const [MeetingData, setMeetingData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalpages, settotalpages] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setloading]= useState(false);
  const [emtyarry, setEmtyarry] = useState("");


  useEffect(() => {

    var div = document.getElementById('pageTitle');
    div.innerHTML = "Marabu Nalam - Meeting List";

    var div = document.getElementById('crumbTitle');
    div.innerHTML = " Meeting List";

    let mydate = new Date();
    setCurDate(moment(mydate).format("YYYY-MM-DD HH:MM"));
    getmeetinglist('empty',page);

  }, []);

 
   //set meeting type
   const meetingtype= (data)=>{
    setMtype(data);
    setPage(1)
    getmeetinglist('empty',1,data);
  };
  
  //Get Meeting list
  const getmeetinglist= (event,value,meType) =>{
    let setdata ='';
    if(meType){
      setdata = meType;
    }else{
      setdata = Mtype;
    }

    setOpen(true);
    //setloading(true);
    var usertoken = localStorage.getItem('user');
    var test =  usertoken.replace(/[#[$\]\\"]/g,'');
    usertoken = 'Bearer '+test;
      
    
    axios({
       method: "get",
       url: baseUrl+"admin/meeting?page="+value+"&limit=10 &type="+setdata,
     
       headers: { 
                 'Accept': 'application/json',
                 'X-Requested-With': 'XMLHttpRequest',
                 'Content-Type': 'application/json',
                 'Access-Control-Allow-Origin': '*' ,
                 'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                 'Authorization': usertoken}
     })
       .then(function (response) {
         //handle success
         if(response.status =='200'){        
              setTimeout(() => {
            setmeetingList(response.data.data.values); 
           settotalpages(response.data.data.total_pages);
           setEmtyarry('Meeting list is empty!');
          // setloading(false); 
           setOpen(false);
          }, 1000);
         } 
       })
       .catch(function (response) {
         //handle error       
         setEmtyarry('Meeting list is empty!');
       });
  };


   //Create Meeting list
   const createmeeting = (elem) =>{
   

          setOpen(true);
        
          var usertoken = localStorage.getItem('user');
          var test =  usertoken.replace(/[#[$\]\\"]/g,'');
          usertoken = 'Bearer '+test;
          var bodyFormData = new FormData();        
          bodyFormData.append('id',elem.id);        
          axios({
            method: "post",
            url: baseUrl+"admin/meeting?id="+elem.id,
            data: bodyFormData,
          
            headers: { 
                      'Accept': 'application/json',
                      'X-Requested-With': 'XMLHttpRequest',
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*' ,
                      'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                      'Authorization': usertoken}
          })
            .then(function (response) {
              //handle success
              if(response.status =='200'){                   
                    swal("" ,"Meeting Created sucessfully!", "success");
                    setTimeout(() => {            
                setOpen(false);
                }, 1000);
              } 
            })
            .catch(function (Error) {
              //handle error
              setOpen(false);
              debugger;
              if(Error.message){
                alert(Error.message);
              }           
            });
            };
 
  const checkmeeting =(event,value)=>{
    setPage(value);
    getmeetinglist('empty',value);
  }


  return (
    <>
          <>
            
        
            <Card >
              <CardContent style={{bottom:'20px'}}>
              <Grid container direction="row" alignItems="center">
                  <Typography
                    variant="body1"
                    component="h1"
                    className={classes.h1Custom}
                  >
                 Meeting List
                  </Typography>
                
                  <Button 
                  onClick={() =>meetingtype('all')} 
                  >All</Button>
                <Button 
                onClick={() =>meetingtype('approve')}
                >Approve</Button>
                 <Button 
                  onClick={() =>meetingtype('unapprove')} 
                  >Un Approve</Button>
                </Grid>
               
                <Divider className={classes.divbotm} />
               
                <TableContainer>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Sl No</StyledTableCell>
            <StyledTableCell align="left">Topic</StyledTableCell>
            <StyledTableCell align="left">Meeting&nbsp;Time&nbsp;(Duration)</StyledTableCell>
            <StyledTableCell align="center"></StyledTableCell>
            <StyledTableCell align="center"></StyledTableCell>
            <StyledTableCell align="center"></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {meetingList.length > 0 && meetingList.map((row,index) => (
            <StyledTableRow key={row.index}>
              <StyledTableCell component="th" scope="row" align="center">
                {index+1}
              </StyledTableCell>
              <StyledTableCell >{row.topic}</StyledTableCell>
              <StyledTableCell align="left">{row.start_time} ({row.duration})</StyledTableCell>          
               
              <StyledTableCell align="left">
                   
                    <Button                     
                    >
                      <Tooltip title="Quick-View">
                          <MeetingView userid={row} />
                      </Tooltip>
                    </Button>
              
                      <Button  color="primary" 
                      //onClick={handleClickOpen}
                      >
                        <Tooltip title="Edit">
                            <MeetingEdit userid={row} />
                        </Tooltip>
                      </Button>
                 
                      <Button  color="primary" 
                      //onClick={handleClickOpen}
                      >
                        <Tooltip title="Delete">
                            <MeetingDelete userid={row} />
                        </Tooltip>
                      </Button>
                      {/* {!row.meeting_id  &&  <Button
                       //className={classes.BtnColor}
                       onClick={(e)=> createmeeting(row)}
                     > 
                      < CreateNewFolderIcon className={classes.create}/>
                     </Button> } */}

              </StyledTableCell>
              
              <StyledTableCell align="left"> </StyledTableCell>
              <StyledTableCell align="left"> </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
              
                
                 <br />
                 <Grid container spacing={2} alignItems="center" >  
                  <Grid item md={4} sm={4} xs={5} lg={12}>
                 <Pagination count={totalpages} color="primary" defaultPage={page} page={page} onChange={checkmeeting} />
                 </Grid>
                 </Grid>

                {meetingList.length == 0 &&  <Grid container direction="row" alignItems="center">
                  <Typography
                    variant="body1"
                    component="h1"
                    className={classes.h1Custom}
                  >
                 {emtyarry}
                  </Typography>
                </Grid>}                     
              </CardContent>
            </Card>
             <Backdrop className={classes1.backdrop}  open={open} >
                <CircularProgress /> </Backdrop>
        </> 

        {showMeeting === true && (   
              <TZoom Meeting={MeetingData} />                
          )}
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
