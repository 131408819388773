import React, { useState, useEffect } from "react";
import Link from "@material-ui/core/Link";
import { useTheme } from "@material-ui/styles";
//import { ZoomMtg } from 'zoomus-jssdk';
import moment from "moment";
import axios from "axios";
import Zoom from "../../../components/zoomNew";
import Button from "@material-ui/core/Button";
import {
  Grid,
  CardContent,
  Card,
  LinearProgress,
  Avatar,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  Divider,
} from "@material-ui/core";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ShopTwoIcon from "@material-ui/icons/ShopTwo";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ShareIcon from "@material-ui/icons/Share";
import DateFnsUtils from "@date-io/date-fns";
import Day from "../../../components/popup/Calender";
import Rupees from "./rupee (1).png";
import configurl from "../../../config1.json";
import ReactPlayer from "react-player";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// styles
import useStyles from "./styles";
import "../../zstyle.css";
import logo from "./user_new.png";

// img
import image from "../image/images.jfif";

const mainChartData = getMainChartData();
const PieChartData = [
  { name: "Group A", value: 400, color: "primary" },
  { name: "Group B", value: 300, color: "secondary" },
  { name: "Group C", value: 300, color: "warning" },
  { name: "Group D", value: 200, color: "success" },
];

export default function Dashboard(props) {
  var classes = useStyles();
  var theme = useTheme();
  const [showFPTYPE, setShowFPTYPE] = useState(false);
  const [joinMeeting, setjoinMeeting] = useState(false);
  const [Newcalender, setNewcalender] = useState([]);
  const [Newdate, setNewdate] = useState([]);
  const [meeting, setMeeting] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [CurUrl, setCurUrl] = useState("");
  const [name, setName] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [date, setDate] = React.useState("");
  const [age, setAge] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [calender, setCalender] = useState(moment().format("DD"));
  const [MonthName, setMonthName] = useState(moment().format("MMM"));
  const [NextMonth, setNextMonth] = useState("");

  const [Sfollowers, setSfollowers] = useState("");
  const [SmeetCount, setSmeetCount] = useState(0);
  const [Scharges, setScharges] = useState(0);
  const [Sclass, setSclass] = useState(0);

  const [file, setFile] = useState("");


  const [meetingList, setmeetingList] = useState([]);

  const [CurAPIDate, setCurAPIDate] = useState(new Date());
  const baseUrl = configurl.baseUrl[0];
  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2014-08-18T21:11:54"),
  );
  var con = moment(selectedDate).format("DD");
  // local
  var [mainChartState, setMainChartState] = useState("monthly");
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    var div = document.getElementById("pageTitle");
    div.innerHTML = "Marabu Nalam - Dashboard";

    var div = document.getElementById("crumbTitle");
    div.innerHTML = "Dashboard";
    datefield();
    getprofile();
    getDashboard();
    funcdatebasemeeting();
    getgallery();

  }, []);



  const funDatas = (date) => {
    var next_month = moment(date).add(1, "M").format("MMM");
    if (moment(date).format("MMM") == "Dec") {
      var year = moment(date).add(1, "y").format("YYYY");
    } else {
      var year = moment(date).format("YYYY");
    }

    funcdatebasemeeting(date);
    console.log(date);
    setMonthName(moment(date).format("MMM"));
    setCalender(moment(date).format("DD"));
    setNewdate(date);
    datefield(date, next_month, year);
  };




  const getprofile = () => {
    var usertoken = localStorage.getItem("user");

    var test = usertoken.replace(/[#[$\]\\"]/g, "");
    usertoken = "Bearer " + test;

    axios({
      method: "get",
      url: baseUrl + "student/profile",

      headers: {
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "GET, POST, OPTIONS, PUT, PATCH, DELETE",
        Authorization: usertoken,
      },
    })
      .then(function (response) {
        //handle success
        if (response.status == "200") {

          setName(response.data.data.name);
          setTitle(response.data.data.title);
          setDate(response.data.data.dob);
          setAge(response.data.data.age);
          setNumber(response.data.data.mobile);
          // setGetcity(response.data.data.city.id)
          // setGetstate(response.data.data.city.state.id)
          // setSelectCountry(response.data.data.city.state.country.id)
          setFile(response.data.data.image);
          //setDiscipline(response.data.data.discipline_of_practice_id)
          // setSplValue(response.data.data.topic_of_interest_id)
          //setEmail(response.data.data.email);
          //setNumber(response.data.data.mobile);
          //setMcc_id(response.data.data.mcc_id);
          //setSelectCountry(response.data.data.city.state.country.id);

          // if (response.data.data.city.state.country) {
          //   chooseState(response.data.data.city.state.country.id);
          // }

          // setCityid(response.data.data.city.id);

          // if (response.data.data.city) {
          //   choosecity(response.data.data.city.id);
          // }
          // choosestate1(response.data.data.city.state.id);
          // setStateid(response.data.data.city.state.id);
        }
      })
      .catch(function (error) { });
  };

  const getDashboard = () => {
    var usertoken = localStorage.getItem("user");

    var test = usertoken.replace(/[#[$\]\\"]/g, "");
    usertoken = "Bearer " + test;

    axios({
      method: "get",
      url: baseUrl + "student/dashboard",

      headers: {
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "GET, POST, OPTIONS, PUT, PATCH, DELETE",
        Authorization: usertoken,
      },
    })
      .then(function (response) {
        //handle success
        if (response.status == "200") {

          setSclass(response.data.data.enrollment_meetings.length)
          setScharges(response.data.data.total_payment_sum_trainer_charge);
          setSfollowers(response.data.data.trainer_follows_count);
          setSmeetCount(response.data.data.total_meetings_count);
        }
      })
      .catch(function (error) { });
  };



  const funcdatebasemeeting = (sDate) => {
    var usertoken = localStorage.getItem('user');
    var bodyFormData = new FormData();
    console.log(sDate);

    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;

    let seledate = '';
    if (sDate) {
      seledate = sDate;
    } else {
      seledate = CurAPIDate;
    }
    axios({
      method: "get",
      url: baseUrl + "student/meeting/list?find_start_date=" + moment(seledate).format("YYYY-MM-DD"),
      // url: baseUrl + "student/meeting/list?find_start_date=2021-11-02",
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }
    })
      .then(function (response) {
        //handle success
        console.log('meetdata', response.data.data);

        if (response.status == "200") {

          setmeetingList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log('meetdata', error);
      });
  };





  const getgallery = () => {
    var usertoken = localStorage.getItem("user");

    var test = usertoken.replace(/[#[$\]\\"]/g, "");
    usertoken = "Bearer " + test;

    axios({
      method: "get",
      url: baseUrl + "student/gallery/free",

      headers: {
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "GET, POST, OPTIONS, PUT, PATCH, DELETE",
        Authorization: usertoken,
      },
    })
      .then(function (response) {
        //handle success
        if (response.status == "200") {
          setMeeting(response.data.data.values);
          // console.log(response.data.data.values);
        }
      })
      .catch(function (error) { });
  };

  // *****************************************************************

  // *****************************************************************




  const datefield = (date1, next_month, year) => {
    function endOfMonth(date) {
      return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }
    var dt = new Date();

    if (date1) {
      var dt = date1;
    }

    var last_date = endOfMonth(dt).toString();
    var month_end = moment(last_date).format("DD");
    let check = 1;
    let calender45 = [];
    for (let i = 0; i < 7; i++) {
      var tes1fu2 = moment(date1).subtract(3, "days").format("MMM");
      var tes1fu3 = moment(date1).format("YYYY");

      if (date1) {
        var subtract_day = moment(date1).subtract(3, "days").format("DD");
        var get_result = moment(date1).subtract(3, "days").format("ddd");
        var get_day = parseInt(subtract_day) + parseInt(i);
        var tes1fu1 = moment()
          .day(get_day + 1)
          .format("ddd");
        if (month_end > get_day) {
          calender45.push({
            id: i,
            date: get_day,
            day: tes1fu1,
            month: tes1fu2,
            year: tes1fu3,

          });
        }
        if (month_end <= get_day) {
          var tes1fu99 = moment()
            .day(check + 1)
            .format("ddd");
          calender45.push({
            id: i,
            date: check,
            day: tes1fu1,
            month: next_month,
            year: year,

          });
          check++;
        }
        setNewcalender(calender45);
      } else {
        var subtract_day = moment().subtract(3, "days").format("DD");
        var get_result = moment().subtract(3, "days").format("ddd");
        var get_day = parseInt(subtract_day) + parseInt(i);
        var tes1fu1 = moment()
          .day(get_day + 1)
          .format("ddd");
        if (month_end > get_day) {
          calender45.push({
            id: i,
            date: get_day,
            day: tes1fu1,
            month: tes1fu2,
            year: tes1fu3,
          });
        }
        if (month_end <= get_day) {
          var tes1fu99 = moment()
            .day(check + 1)
            .format("ddd");
          calender45.push({
            id: i,
            date: check,
            day: tes1fu1,
            month: next_month,
            year: year,
          });
          check++;
        }
        setNewcalender(calender45);
      }
    }
  };

  const FunPlayEnb = (video) => {
    setCurUrl(video.url);
    setOpen(true);
  };


  const clenderfcn = (item) => {
    console.log("item", item);
    setMonthName(item.month);
    setCalender(item.date);

    console.log(item.date.toString().length);
    let day = '';
    if (item.date.toString().length == 1) {
      day = '0' + item.date;
    } else {
      day = item.date;
    }
    let sDate = new Date(item.year + '-' + item.MunNum + '-' + day);
    console.log((item.year + '-' + item.MunNum + '-' + day));
    // d = new Date("2015-03-25");
    funcdatebasemeeting(sDate);
  };






  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          md={12}
          lg={8}
          sm={12} /* style={{ margin: '-13px' }} */
        >
          <div style={{ margin: "0px 0px 0px -11px" }}>
            <Grid container spacing={2}>
              {/* First container */}

              {isOpen === false && (
                <Grid item xs={12} md={12} lg={12} sm={12} lg={6}>
                  <Card className={classes.InsidebackCLR}>
                    <CardContent style={{ paddingBottom: "0px" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={3} sm={3} lg={3} md={4}>
                          <Avatar
                            variant="square"
                            src={
                              baseUrl + "storage/" +
                              file
                            }
                            className={classes.square}
                          >
                            {/* <img src={logo} class='img' ></img> */}
                          </Avatar>
                        </Grid>
                        <Grid item xs={6} sm={6} lg={7} md={4}>
                          <Typography
                            variant="h5" class='maincard' style={{ marginLeft: '-23px' }}>
                            Welcome Back,
                            <br />
                            <span style={{ fontWeight: 'bold' }}>{name} !</span>
                          </Typography>



                        </Grid>
                        <Grid item xs={3} sm={3} lg={2} md={4}>

                          <ShareIcon className={classes.Test3} />

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Typography /*variant="h5"*/ class="maincard"
                            style={{ marginLeft: "2px" }}
                          >
                            You are Doing a Great job! Now You are completed
                            <span class="specification"> {Sclass} class rooms </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Divider />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={4} sm={4} lg={4} md={4}>
                          <Typography /*variant="h5"*/ class="Test1">
                            Followers <br /> 300
                          </Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} lg={4} md={4}>
                          <Typography /*variant="h5"*/ class="Test2">
                            Following <br /> {Sfollowers ? Sfollowers : "0"}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} lg={4} md={4}>
                          <Typography /*variant="h5"*/ class="Test3">
                            Sessions <br />  {Sclass}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              )}
              {isOpen === false && (
                <Grid item xs={12} md={6} lg={12} sm={6} lg={3}>
                  <Card className={classes.InsidebackCLR1}>
                    <CardContent
                      class="cardtext"
                      style={{ paddingBottom: "0px" }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                          <Typography /*variant="h5"*/ class="cardnumbers">
                            {Sclass} <br />
                            Classrooms
                          </Typography>
                          <br></br>
                          <ShopTwoIcon className={classes.cardicon1} />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                          <Divider />

                          <Grid item md={12} xs={12} sm={12} lg={12}>
                            <Typography /*variant="h5"*/ class="cardfooter">
                              <Link href="#/app/trainer/Shedulemeeting" style={{ textDecoration: "none" }}>
                                {" "}
                                View More
                              </Link>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              )}
              {isOpen === false && (
                <Grid item xs={12} md={6} lg={12} sm={6} lg={3}>
                  <Card className={classes.InsidebackCLR1}>
                    <CardContent
                      class="cardtext1"
                      style={{ paddingBottom: "0px" }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} lg={12}>
                          <Typography /*variant="h5"*/ class="cardnumbers">
                            0 <br />
                            Total Revenue
                          </Typography>
                          <br></br>
                          <img src={Rupees} className={classes.cardicon2}></img>
                          {/* <Rupees className={classes.cardicon1}/> */}
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                          <Divider />

                          <Grid item md={12} xs={12} sm={12} lg={12}>
                            <Typography /*variant="h5"*/ class="cardfooter1">
                              View More
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              )}

              <Grid
                container
                spacing={1}
                style={{
                  justifyContent: "flex-end",
                  padding: "10px 10px 20px 10px",
                }}
              >

                {meeting.length === 0 ? <Grid container
                  spacing={1}
                  style={{
                    justifyContent: "center",
                    textAlign: "center",
                    marginTop: "20px"

                  }}>Video Not available </Grid> : ""}
                {isOpen === false &&
                  meeting.length > 0 &&
                  meeting.map((item) => (
                    <Grid item xs={12} sm={12} lg={12}>
                      {item.gallery_map.map(
                        (items) =>
                          items.gallery_part.length > 0 &&
                          items.gallery_part.map((fvideo) => (
                            <>
                              <Card
                                className={classes.image}
                                style={{ marginTop: "5px" }}
                              >
                                <CardContent
                                  className={classes.image1}
                                  style={{ paddingBottom: "0px" }}
                                >
                                  <Grid container>
                                    <Grid item xs={6} sm={6} lg={2}>
                                      <img
                                        src={
                                          "https://acharya.heptotechnologies.org/storage/" +
                                          fvideo.thumbnail
                                        }
                                        onClick={() => FunPlayEnb(fvideo)}
                                        style={{
                                          width: "100px",
                                          height: "75px",
                                        }}
                                      ></img>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      sm={6}
                                      lg={10}
                                      className={classes.table}
                                    >
                                      <Typography
                                        class="tabletext"
                                        style={{ marginTop: "0px" }}
                                      >
                                        {item.meetings.topic +
                                          " part " +
                                          fvideo.part}
                                      </Typography>
                                      <Typography
                                        /*variant="h5"*/ class="tabletext1"
                                      >
                                        {item.meetings.content}
                                      </Typography>
                                      <Grid container spacing={0}>
                                        <Grid item xs={12} lg={12}>
                                          <Grid
                                            container
                                            style={{ marginTop: "2px" }}
                                          >
                                            <Grid item xs={12} lg={6}>
                                              <Typography class="tabletext1">
                                                {item.meetings.start_time}
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              lg={6}
                                              className={classes.tabletext2}
                                            >
                                               Video Duration : {display(60 * 60 * 0 + item.meetings.duration)}
                                              <PlayCircleFilledIcon
                                                className={classes.playicon}
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </>
                          )),
                      )}
                    </Grid>
                  ))}

                {isOpen && (
                  <>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setOpen(false)}
                    >
                      Close
                    </Button>
                    <br />
                    <ReactPlayer
                      controls
                      height="600px"
                      width="100%"
                      url={CurUrl}
                    />
                  </>
                )}
              </Grid>
              {isOpen && (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </Button>
                  <br />
                  <ReactPlayer
                    controls
                    height="600px"
                    width="100%"
                    url={CurUrl}
                  />
                </>
              )}
            </Grid>
          </div>
        </Grid>

        {/* right side section */}
        {isOpen === false && (
          <Grid
            item
            xs={12}
            md={12}
            lg={4}
            sm={12}
            style={{
              // margin: "-13px",
              backgroundColor: "#F5F7F8",
              padding: "10px",
            }}
          >
            <div style={{}}>
              {/* <Card>
                <CardContent> */}
              <div style={{ margin: "0px -23px 0px 0px" }}>
                <Grid container spacing={1}>
                  {/* calender section */}

                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sm={12}
                    className={classes.month}
                  >
                    <login />
                    <Day finalResult={funDatas} />
                  </Grid>
                  <Grid container style={{ marginTop: "0px", cursor: "pointer" }}>
                    <Grid item xs={12} md={12} lg={12} sm={12}>
                      <Grid className={classes.calender} style={{ marginTop: "0px", cursor: "pointer" }}>
                        {Newcalender.map((item) => (
                          <Typography class="calendertext" onClick={() => {
                            clenderfcn(item);
                          }}>
                            <span class="calendertext1">{item.day}</span>
                            <br></br>
                            <span class="Date">{item.date}</span>
                            {calender == item.date && (
                              <Grid
                                container
                                style={{
                                  marginTop: "0px",
                                  justifyContent: "center",
                                }}
                              >
                                <span class="dot"></span>
                              </Grid>
                            )}
                          </Typography>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={6}
                    sm={6}
                    style={{ marginTop: "10px" }}
                    className={classes.pagenation}
                  >
                    <Typography class="pagenation1">Scheduled</Typography>
                    <Typography class="pagenation1">Completed</Typography>
                  </Grid>
                  {/* Right card section */}

                  {meetingList.values.length > 0 && meetingList.values.map((elem, index) => (

                    <Grid item xs={12} md={12} lg={12} sm={12}>
                      <Card>
                        <CardContent
                          style={{ padding: "6px", paddingBottom: "4px" }}
                        >
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              lg={2}
                              md={2}
                              sm={2}
                              className={classes.rightcard}
                            >
                              <Avatar
                                variant="square"
                                className={classes.square1}
                                style={{ fontSize: "15px", textAlign: "center" }}
                              >
                                {/* {MonthName} */}
                                {(moment(elem.meeting_start_time).format("MMM"))}
                                <br></br>
                                {/* {calender} */}
                                {(moment(elem.meeting_start_time).format("DD"))}
                              </Avatar>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              lg={10}
                              md={9}
                              sm={10}
                              className={classes.rightcard}
                            >
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  xs={5}
                                  lg={7}
                                  md={7}
                                  sm={7}
                                  className={classes.impname} style={{ fontSize: "12px" }}
                                >
                                  {elem.topic}
                                </Grid>
                                <Grid style={{ padding: "7px 7px 1px 1px" }}
                                  item
                                  xs={7}
                                  lg={5}
                                  md={5}
                                  sm={5}
                                  className={classes.count}
                                >
                                  {(moment(elem.meeting_start_time).format("h:mma"))} to {(moment(elem.meeting_end_time).format("h:mma"))}
                                </Grid>
                                <Grid
                                  item
                                  xs={5}
                                  lg={4}
                                  md={4}
                                  sm={4}
                                  className={classes.secondsection}
                                >
                                  <Typography class="cardbutton">
                                    Allopathy{" "}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={7}
                                  lg={8}
                                  md={8}
                                  sm={8}
                                  className={classes.count}
                                >
                                  Entrolled user: {elem.total_enrollments_count} | Invited: {elem.invite}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}


                  {meetingList.values.length == 0 &&

                    <Grid item xs={12} md={12} lg={12} sm={12}>
                      <Card style={{ padding: "10px !important" }}>
                        <CardContent
                          style={{ padding: "6px", paddingBottom: "4px" }}
                        >
                          <Typography style={{ textAlign: "center" }}
                            variant="body1"
                            component="h1"
                            className={classes.h1Custom}
                          >
                            Meeting is not available !!!
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>

                  }



                  {/* <Grid item xs={12} md={12} lg={12} sm={12}>
                    <Card style={{ padding: "10px !important" }}>
                      <CardContent
                        style={{ padding: "6px", paddingBottom: "4px" }}
                      >
                        <Grid container>
                          <Grid item xs={12} sm={2} md={2} lg={2}>
                            <Avatar
                              variant="square"
                              className={classes.square1}
                              style={{ fontSize: "15px", textAlign: "center" }}
                            >
                              {MonthName} <br></br>
                              {calender}
                            </Avatar>
                          </Grid>
                          <Grid item xs={12} sm={10} md={9} lg={10}>
                            <Grid container spacing={1}>
                              <Grid
                                item
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                className={classes.impname} style={{ fontSize: "12px" }}
                              >
                                Dermatologist New Strategies
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                className={classes.count}
                              >
                                10AM to 12Pm
                              </Grid>
                              <Grid
                                item
                                xs={5}
                                sm={4}
                                md={4}
                                lg={4}
                                className={classes.secondsection}
                              >
                                <Typography class="cardbutton1">
                                  Dermatology
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={7}
                                sm={8}
                                md={8}
                                lg={8}
                                className={classes.count}
                              >
                                Entrolled user: 20 |Invited: 50
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid> */}
                  {/* <Grid item xs={12} md={12} lg={12} sm={12}>
                    <Card>
                      <CardContent
                        style={{ padding: "6px", paddingBottom: "4px" }}
                      >
                        <Grid container>
                          <Grid item xs={12} sm={2} md={2} lg={2}>
                            <Avatar
                              variant="square"
                              className={classes.square1}
                              style={{ fontSize: "15px", textAlign: "center" }}
                            >
                              {MonthName}
                              <br></br>
                              {calender}
                            </Avatar>
                          </Grid>
                          <Grid item xs={12} sm={10} md={9} lg={10}>
                            <Grid container spacing={1}>
                              <Grid
                                item
                                xs={6}
                                sm={8}
                                md={8}
                                lg={8}
                                className={classes.impname} style={{ fontSize: "12px" }}
                              >
                                Dental Health Studies
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                sm={4}
                                md={4}
                                lg={4}
                                className={classes.count}
                              >
                                10AM to 12Pm
                              </Grid>
                              <Grid
                                item
                                xs={5}
                                sm={4}
                                md={4}
                                lg={4}
                                className={classes.secondsection}
                              >
                                <Typography class="cardbutton2" style={{ color: "white" }}>
                                  Dental
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={7}
                                sm={8}
                                md={8}
                                lg={8}
                                className={classes.count}
                              >
                                Entrolled user: 20 |Invited: 50
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid> */}
                  {/* <Grid item xs={12} md={12} lg={12} sm={12}>
                    <Card>
                      <CardContent
                        style={{ padding: "6px", paddingBottom: "4px" }}
                      >
                        <Grid container>
                          <Grid item xs={12} sm={2} md={2} lg={2}>
                            <Avatar
                              variant="square"
                              className={classes.square1}
                              style={{ fontSize: "15px", textAlign: "center" }}
                            >
                              {MonthName}
                              <br></br>
                              {calender}
                            </Avatar>
                          </Grid>
                          <Grid item xs={12} sm={10} md={9} lg={10}>
                            <Grid container spacing={1}>
                              <Grid
                                item
                                xs={6}
                                sm={8}
                                md={8}
                                lg={8}
                                className={classes.impname} style={{ fontSize: "12px" }}
                              >
                                Dermatologist New Strategies
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                sm={4}
                                md={4}
                                lg={4}
                                className={classes.count}
                              >
                                10AM to 12Pm
                              </Grid>
                              <Grid
                                item
                                xs={5}
                                sm={4}
                                md={4}
                                lg={4}
                                className={classes.secondsection}
                              >
                                <Typography class="cardbutton3" style={{ color: "white" }}>
                                  Dermatology
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={7}
                                sm={8}
                                md={8}
                                lg={8}
                                className={classes.count}
                              >
                                Entrolled user: 20 |Invited: 50
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid> */}
                  {/* <Grid item xs={12} md={12} lg={12} sm={12}>
                    <Card style={{ padding: "5px" }}>
                      <CardContent
                        style={{ padding: "6px", paddingBottom: "4px" }}
                      >
                        <Grid container>
                          <Grid item xs={12} sm={2} md={2} lg={2}>
                            <Avatar
                              variant="square"
                              className={classes.square1}
                              style={{ fontSize: "15px", textAlign: "center" }}
                            >
                              {MonthName} <br></br>
                              {calender}
                            </Avatar>
                          </Grid>
                          <Grid item xs={12} sm={10} md={9} lg={10}>
                            <Grid container spacing={1}>
                              <Grid
                                item
                                xs={6}
                                sm={8}
                                md={8}
                                lg={7}
                                className={classes.impname} style={{ fontSize: "12px" }}
                              >
                                Root Canel Treatment
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                sm={4}
                                md={4}
                                lg={5}
                                className={classes.count}
                                style={{ textAlign: "end !important" }}
                              >
                                10AM to 12Pm
                              </Grid>
                              <Grid
                                item
                                xs={5}
                                sm={4}
                                md={4}
                                lg={4}
                                className={classes.secondsection}
                              >
                                <Typography className={classes.cardbutton}>
                                  Dermatology
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={7}
                                sm={8}
                                md={8}
                                lg={8}
                                className={classes.count}
                              >
                                Entrolled user: 20 |Invited: 50
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid> */}
                  {/* <Grid item xs={12} md={12} lg={12} sm={12}>
                    <Card style={{ padding: "5px" }}>
                      <CardContent
                        style={{ padding: "6px", paddingBottom: "4px" }}
                      >
                        <Grid container>
                          <Grid item xs={12} sm={2} md={2} lg={2}>
                            <Avatar
                              variant="square"
                              className={classes.square1}
                              style={{ fontSize: "15px", textAlign: "center" }}
                            >
                              {MonthName} <br></br>
                              {calender}
                            </Avatar>
                          </Grid>
                          <Grid item xs={12} sm={10} md={9} lg={10}>
                            <Grid container spacing={1}>
                              <Grid
                                item
                                xs={6}
                                sm={8}
                                md={8}
                                lg={7}
                                className={classes.impname} style={{ fontSize: "12px" }}
                              >
                                Root Canel Treatment
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                sm={4}
                                md={4}
                                lg={5}
                                className={classes.count}
                                style={{ textAlign: "end !important" }}
                              >
                                10AM to 12Pm
                              </Grid>
                              <Grid
                                item
                                xs={5}
                                sm={4}
                                md={4}
                                lg={4}
                                className={classes.secondsection}
                              >
                                <Typography className={classes.cardbutton}>
                                  Dermatology
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={7}
                                sm={8}
                                md={8}
                                lg={8}
                                className={classes.count}
                              >
                                Entrolled user: 20 |Invited: 50
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid> */}
                  {/* <Grid item xs={12} md={12} lg={12} sm={12}>
                    <Card style={{ padding: "5px" }}>
                      <CardContent
                        style={{ padding: "6px", paddingBottom: "4px" }}
                      >
                        <Grid container>
                          <Grid item xs={12} sm={2} md={2} lg={2}>
                            <Avatar
                              variant="square"
                              className={classes.square1}
                              style={{ fontSize: "15px", textAlign: "center" }}
                            >
                              {MonthName} <br></br>
                              {calender}
                            </Avatar>
                          </Grid>
                          <Grid item xs={12} sm={10} md={9} lg={10}>
                            <Grid container spacing={1}>
                              <Grid
                                item
                                xs={6}
                                sm={8}
                                md={8}
                                lg={7}
                                className={classes.impname}
                              >
                                Root Canel Treatment
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                sm={4}
                                md={4}
                                lg={5}
                                className={classes.count}
                                style={{ textAlign: "end !important" }}
                              >
                                10AM to 12Pm
                              </Grid>
                              <Grid
                                item
                                xs={5}
                                sm={4}
                                md={4}
                                lg={4}
                                className={classes.secondsection}
                              >
                                <Typography className={classes.cardbutton}>
                                  Dermatology
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={7}
                                sm={8}
                                md={8}
                                lg={8}
                                className={classes.count}
                              >
                                Entrolled user: 20 |Invited: 50
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid> */}
                </Grid>
              </div>
              {/* </CardContent>
              </Card> */}
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}

console.log(display(60 * 60 * 0 + 195)) // 2.5 hours + 25 seconds

function display (seconds) {
  let format = val => `0${Math.floor(val)}`.slice(-2)
  let hours = seconds / 3600
  let minutes = (seconds % 3600) / 60

  return [hours, minutes, seconds % 60].map(format).join(':')

// var hr = new Hr(null);
// if(hours<=0){
//   hr.display=none;

// }
}
