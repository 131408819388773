import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/styles";
//import { ZoomMtg } from 'zoomus-jssdk';
//import { ZoomMtg } from "@zoomus/websdk";
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import Card from "@material-ui/core/Card";
import Avatar from '@material-ui/core/Avatar';
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Pagination from '@material-ui/lab/Pagination';
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "axios";
import FormData from "form-data";
import configurl from "../../../config1.json";
import TZoom  from '../../../components/zoomTrainer';
import { ToastContainer, toast } from 'react-toastify';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import Checkbox from "@material-ui/core/Checkbox";

import ViewProfile from "../../../components/popup/StudentViewProfile"
import EditProfile from "../../../components/popup/StudentEditProfile"
import DeleteProfile from "../../../components/popup/StudentDeleteProfile"
import Sfollow from "../../../components/popup/Sfollow"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';

import 'react-toastify/dist/ReactToastify.css';
// styles
import { withStyles, makeStyles } from '@material-ui/core/styles';
import useStyles from "./styles";
import "./zstyle.css";

const StyledTableCell = withStyles((theme) => ({
  head: {
    //backgroundColor: theme.palette.common.black,
    backgroundColor: '#3B5FB7',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles1 = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function Shedulemeeting(props) {
  var classes = useStyles();
  var theme = useTheme();
  var classes1 = useStyles1();
  
  const [curDate, setCurDate] = useState('');
  const [title,setTitle]= useState('');
  const [type,settype]= useState(2);
  const [sTime,setSTime] = useState('');
  const [duration, setDuration]=useState('');
  const [mailId, setMailID]=useState('');
  const [password, setPassword]=useState('');
  const [agenda,setAgenda]=useState('');
  const [meetingList, setmeetingList]=useState([]);
  const [Mtype,setMtype]=useState('all');
  const baseUrl = configurl.baseUrl[0];
  const [showMeeting,setShowMeeting] = useState(false);
  const [MeetingData, setMeetingData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalpages, settotalpages] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setloading]= useState(false);
  const [emtyarry, setEmtyarry] = useState("");
  const [curSelId,setcurSelId]=useState([]);
  
  useEffect(() => {


    var div = document.getElementById('pageTitle');
    div.innerHTML = "Marabu Nalam - Student List";

    var div = document.getElementById('crumbTitle');
    div.innerHTML = " Student List";

    let mydate = new Date();
    setCurDate(moment(mydate).format("YYYY-MM-DD HH:MM"));
    getmeetinglist('empty',page);

  }, []);

 
  
  //Get Meeting list
  const getmeetinglist= (event,value) =>{
    setOpen(true);
    //setloading(true);
    var usertoken = localStorage.getItem('user');
    var test =  usertoken.replace(/[#[$\]\\"]/g,'');
    usertoken = 'Bearer '+test;      

    axios({
       method: "get",
       url: baseUrl+"admin/list?guard=student&page="+value+"&limit=10",
     
       headers: { 
                 'Accept': 'application/json',
                 'X-Requested-With': 'XMLHttpRequest',
                 'Content-Type': 'application/json',
                 'Access-Control-Allow-Origin': '*' ,
                 'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                 'Authorization': usertoken}
     })
       .then(function (response) {
         //handle success
         if(response.status =='200'){  
           
              setTimeout(() => {
            setmeetingList(response.data.data.values); 
           settotalpages(response.data.data.total_pages);
           setEmtyarry('Student list is empty!');
         
          // setloading(false); 
           setOpen(false);
          }, 1000);
         } 
       })
       .catch(function (response) {
         //handle error  
         setEmtyarry('Student list is empty!');
       });
  };
 
  const checkmeeting =(event,value)=>{
    setPage(value);
    getmeetinglist('empty',value);
  }


  return (
    <>
          <>
            
        
            <Card >
              <CardContent style={{bottom:'20px'}}>
              <Grid container direction="row" alignItems="center">
                  <Typography
                    variant="body1"
                    component="h1"
                    className={classes.h1Custom}
                  >
                 Student List
                  </Typography>
                 
                </Grid>
              
                <Divider className={classes.divbotm} />
               
                <TableContainer>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Sl No</StyledTableCell>
   
            <StyledTableCell align="left"> Name</StyledTableCell>
            <StyledTableCell align="center">Phone</StyledTableCell>
            <StyledTableCell align="center">Email</StyledTableCell>
            <StyledTableCell align="center">status</StyledTableCell>
            <StyledTableCell align="center">Follow</StyledTableCell>
            <StyledTableCell align="center">Action</StyledTableCell>
  
        
          </TableRow>
        </TableHead>
        <TableBody>
          {meetingList.length > 0 && meetingList.map((elem,index) => (
            <StyledTableRow key={elem.index}>
              <StyledTableCell component="th" scope="row" align="center">
              {index+1}
              {/* <Checkbox
                    checked={elem.Status}
                    onChange={(e) => selectdata("Status",e,index,elem)}
                      /> */}
              </StyledTableCell>
              <StyledTableCell  align="left">{elem.name}</StyledTableCell>
              <StyledTableCell  align="left">{elem.mobile}</StyledTableCell>
              <StyledTableCell  align="left">{elem.email}</StyledTableCell>
              <StyledTableCell align="center">{elem.status==0?'Active':'DeActive'}</StyledTableCell>
              <StyledTableCell  align="center" ><Sfollow user={elem}/></StyledTableCell> 
              <StyledTableCell align="center"> 
              <Grid container direction="row" alignItems="center" className={classes.action}>
                 
                 <ViewProfile userid={elem} />
                 <EditProfile userid={elem} />
                 <DeleteProfile userid={elem} />
                
               </Grid>
               
              </StyledTableCell>
            
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
                 <br />
                 <Grid container spacing={2} alignItems="center" >  
                  <Grid item md={4} sm={4} xs={5} lg={12}>
                 <Pagination count={totalpages} color="primary" defaultPage={page} page={page} onChange={checkmeeting} />
                 </Grid>
                 </Grid>

                {meetingList.length == 0 &&  <Grid container direction="row" alignItems="center">
                  <Typography
                    variant="body1"
                    component="h1"
                    className={classes.h1Custom}
                  >
                 {emtyarry}
                  </Typography>
                </Grid>}                     
              </CardContent>
            </Card>
             <Backdrop className={classes1.backdrop}  open={open} >
                <CircularProgress /> </Backdrop>
        </> 

        {showMeeting === true && (   
              <TZoom Meeting={MeetingData} />                
          )}
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
