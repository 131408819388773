import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/styles";
//import { ZoomMtg } from 'zoomus-jssdk';
//import { ZoomMtg } from "@zoomus/websdk";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import SZoom from "../../../components/zoomStudents";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
// import useMousePosition from "../../../components/Cursor";
import VisibilityIcon from "@material-ui/icons/Visibility";

import axios from "axios";
import FormData from "form-data";
import configurl from "../../../config1.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// styles
import useStyles from "./styles";
import "./zstyle.css";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import moment from "moment";

import MeetingView from "../../../components/popup/Smeetingview";

export default function Shedulemeeting(props) {
  const history = useHistory();
  var classes = useStyles();
  var theme = useTheme();
  // const { x, y } = useMousePosition();
  const [curDate, setCurDate] = useState("");
  const [title, setTitle] = useState("");
  const [type, settype] = useState(2);
  const [sTime, setSTime] = useState("");
  const [duration, setDuration] = useState("");
  const [mailId, setMailID] = useState("");
  const [password, setPassword] = useState("");
  const [agenda, setAgenda] = useState("");
  const [meetingList, setmeetingList] = useState([]);
  const [Mtype, setMtype] = useState("all");
  const baseUrl = configurl.baseUrl[0];
  const [showMeeting, setShowMeeting] = useState(false);
  const [Meeting, setMeeting] = useState([]);
  const [emtyarry, setEmtyarry] = useState("");
  const [list, setList] = useState("");
  const [totalpages, settotalpages] = useState("");
  const [page, setPage] = useState(1);
  const [Showjoin, setShowJoin] = React.useState(true);
  const [Showentroll, setShowEntroll] = React.useState(true);
  // const [Show, setShowEntroll] = React.useState(true);
  const [HideBtn, setHideBtn] = useState(false);
  // const[new_time,setNew_time]=useState("");

  var new_time = moment(Date.now()).format("YYYY-MM-DD hh:mm a");
  const hasMovedCursor = typeof x === "number" && typeof y === "number";
  console.log("hasMovedCursor", hasMovedCursor);
  var SpecialToDate = "31/01/2014";
  var SpecialTo = moment(SpecialToDate, "DD/MM/YYYY");

  useEffect(() => {
    var div = document.getElementById("pageTitle");
    div.innerHTML = "Marabu Nalam - Meeting List";

    var div = document.getElementById("crumbTitle");
    div.innerHTML = " Meeting List";

    let mydate = new Date();
    setCurDate(moment(mydate).format("YYYY-MM-DD HH:MM"));
    getmeetinglist("empty", page);
    // setNew_time(new_time1);

    console.log("dsjcjkds");
  }, []);

  //successfully meeting creation message
  const notify = () =>
    toast.success("👋 Meeting Created!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  //create meeting failer
  const notifyfailer = (message) =>
    toast.error("👋 " + message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  //Get Meeting list
  const getmeetinglist = (event, value, meType) => {
    var usertoken = localStorage.getItem("user");
    var test = usertoken.replace(/[#[$\]\\"]/g, "");
    usertoken = "Bearer " + test;

    axios({
      method: "get",
      url:
        baseUrl +
        "student/meeting/list?page=" +
        value +
        "&limit=10 & category=upcoming",

      headers: {
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "GET, POST, OPTIONS, PUT, PATCH, DELETE",
        Authorization: usertoken,
      },
    })
      .then(function (response) {
        //handle success
        if (response.status == "200") {
          settotalpages(response.data.data.total_pages);
          setmeetingList(response.data.data);
          setList(response.data.data.values);

          setEmtyarry("Meeting list is empty!");
        }
      })
      .catch(function (response) {
        setEmtyarry("Meeting list is empty!");
      });
  };

  const joinMeeting = (elem) => {
    elem.username = window.localStorage.getItem("Uname");

    //setMeetingID(elem.id);
    //setShowMeeting(true)
    meetingData(elem);
  };

  const meetingData = (elem) => {
    var usertoken = localStorage.getItem("user");
    var test = usertoken.replace(/[#[$\]\\"]/g, "");
    usertoken = "Bearer " + test;
    debugger;

    axios({
      method: "get",
      url: baseUrl + "student/meeting?id=" + elem.id,

      headers: {
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "GET, POST, OPTIONS, PUT, PATCH, DELETE",
        Authorization: usertoken,
      },
    })
      .then(function (response) {
        //handle success
        debugger;
        //console.log('test',response);
        if (response.status == "200") {
          setMeeting(response.data.data);
          setShowMeeting(true);
        }
      })
      .catch(function (response) {
        //handle error
      });
  };

  const Entrolled = (elem) => {
    var bodyFormData = new FormData();
    bodyFormData.append("guard", "student");
    bodyFormData.append("meeting_id", elem.id);
    var usertoken = localStorage.getItem("user");
    var test = usertoken.replace(/[#[$\]\\"]/g, "");
    usertoken = "Bearer " + test;

    axios({
      method: "post",
      url: baseUrl + "student/enrollment?id=" + elem.id,
      data: bodyFormData,
      headers: {
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "GET, POST, OPTIONS, PUT, PATCH, DELETE",
        Authorization: usertoken,
      },
    })
      .then(function (response) {
        if (response.status == "200") {
          window.location.reload();
        }
      })
      .catch(function (error) {});
  };

  const StyledTableCell = withStyles((theme) => ({
    head: {
      //backgroundColor: theme.palette.common.black,
      backgroundColor: "#3B5FB7",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const meetingtype = (data) => {
    setMtype(data);
    setPage(1);
    getmeetinglist("empty", 1, data);
  };

  const checkmeeting = (event, value) => {
    setPage(value);
    getmeetinglist("empty", value);
  };
  function myFunction(e) {
    console.log("213");
  }

  return (
    <>
      <>
        {showMeeting === false && (
          <Card onmousemove="myFunction(event)">
            <CardContent style={{ bottom: "20px" }}>
              {/* <Cursor changetime={myFunction()}/> */}

              <Grid container direction="row" alignItems="center">
                <Typography
                  variant="body1"
                  component="h1"
                  className={classes.h1Custom}
                >
                  Meeting List
                </Typography>
              </Grid>

              <Divider className={classes.divbotm} />

              <TableContainer>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow className={classes.row}>
                      <StyledTableCell align="center">S.No</StyledTableCell>
                      <StyledTableCell align="center"> Time </StyledTableCell>
                      <StyledTableCell align="center"> </StyledTableCell>
                      <StyledTableCell align="center"> Topic </StyledTableCell>
                      <StyledTableCell align="center"> View </StyledTableCell>
                      <StyledTableCell align="center"> Action</StyledTableCell>
                      <StyledTableCell align="center"> </StyledTableCell>
                      <StyledTableCell align="center"> </StyledTableCell>
                      
                      {/* <StyledTableCell align="center"> </StyledTableCell>
                      <StyledTableCell align="center"> </StyledTableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list.length > 0 &&
                      list.map((elem, index) => (
                        <StyledTableRow
                          key={elem.index}
                          className={classes.row}
                        >
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {index + 1}
                          </StyledTableCell>

                          <StyledTableCell
                            align="center"
                            style={{ height: "0px !important" }}
                          >
                            {" "}
                            <Typography class="FIRST_HEAD">
                              {" "}
                              {moment(elem.meeting_start_time).format(
                                "hh:mm a",
                              )}{" "}
                              -{" "}
                              {moment(elem.meeting_end_time).format("hh:mm a")}{" "}
                              &nbsp; &nbsp;
                            </Typography>
                            <Typography class="sub_HEAD">
                              Occurrence 13 of 19 &nbsp;&nbsp; &nbsp; &nbsp;
                            </Typography>{" "}
                          </StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ height: "0px !important" }}
                          >
                            <Typography class="FIRST_HEAD">
                              {elem.topic}
                              -TAMIL
                            </Typography>
                            <Typography class="sub_HEAD">
                              Meeting ID:886 77807 3439{" "}
                            </Typography>{" "}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <MeetingView userid={elem} />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {/* {  moment(elem.start_time).subtract(15, 'minutes').format("YYYY-MM-DD HH:mm:ss") ? "": */}
                            {/* {  (( moment(elem.start_time).subtract(30, 'minutes').format("DD/MM/YYYY, h:mm:ss" ) <= moment(new Date()).format("DD/MM/YYYY, h:mm:ss" )) &&
                         (moment(elem.start_time).format("DD/MM/YYYY" ) == moment(new Date()).format("DD/MM/YYYY ")) ) ?  */}

                            {'"' +
                              moment(elem.meeting_start_time)
                                .subtract(30, "m")
                                .format("YYYY-MM-DD hh:mm a") +
                              '"' <=
                              '"' + new_time + '"' && elem.enroll_student ? (
                              <Button
                                style={{ backgroundColor: "#6bbb4c",color:"white", minWidth: "108px" }}
                                variant="contained"
                                // color="primary"
                                //className={classes.BtnColor}
                                onClick={(e) => joinMeeting(elem)}
                              >
                                Join
                              </Button>
                            ) : (
                              <Button
                              style={{ minWidth: "108px" }}
                                variant="contained"
                                color="primary"
                                //className={classes.BtnColor}
                                onClick={(e) => Entrolled(elem)}
                              >
                                {elem.enroll_student ? "Enrolled" : "Enroll"}
                              </Button>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center"> </StyledTableCell>
                          <StyledTableCell align="center">
                            <Grid container className={classes.action}></Grid>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
              <Grid container spacing={2} alignItems="center">
                <Grid item md={4} sm={4} xs={5} lg={12}>
                  <Pagination
                    count={totalpages}
                    color="primary"
                    defaultPage={page}
                    page={page}
                    onChange={checkmeeting}
                  />
                </Grid>
              </Grid>
              {meetingList.length == 0 && (
                <Grid container direction="row" alignItems="center">
                  <Typography
                    variant="body1"
                    component="h1"
                    className={classes.h1Custom}
                  >
                    {emtyarry}
                  </Typography>
                </Grid>
              )}
            </CardContent>
          </Card>
        )}
      </>

      {showMeeting === true && <SZoom MeetingDatas={Meeting} />}
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
