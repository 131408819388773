import React, { useEffect } from "react";
import { ZoomMtg } from '@zoomus/websdk';
import { Zoom } from "@material-ui/core";
import { ZoomOutMapTwoTone } from "@material-ui/icons";


var base_url = window.location.origin;

var apiKey ='deeAejCbSSm9J7ed48V1GQ';
var apiSecret = 'DptTkiaa3JbqhnTy1ypesHhR9y3HpT8BUMUX';
var meetingNumber = '83392115317';
var role = 0 ;
var leaveUrl = base_url+'/';
var userName = 'WebSdk';
var userEmail = '';
var passWord = '046772';


const ZoomStudent =() =>{

  useEffect(() => {
    showZoomDiv();
    //ZoomMtg.setZoomJSLib('node_modules/zoomus/websdk/dist/lib', '/av');
    //ZoomMtg.setZoomJSLib("https://jssdk.zoomus.cn/1.9.1/lib", "/av"); true
     ZoomMtg.setZoomJSLib("https://source.zoom.us/1.9.0/lib", "/av");
    ZoomMtg.preLoadWasm();
  ZoomMtg.prepareJssdk();

    /**
     * You should not visible api secret key on frontend
     * Signature must be generated on server
     * https://marketplace.zoom.us/docs/sdk/native-sdks/web/essential/signature
     */

     ZoomMtg.generateSignature({
      meetingNumber: meetingNumber,
      apiKey: apiKey,
      apiSecret: apiSecret,
      role: role,
      success: function (res) {     
        setTimeout(() => {
          initiateMeeting(res.result);
        }, 1000);
      },
    });
   
    //initiateMeeting();
  }, []);

  const showZoomDiv =() =>{
      document.getElementById('zmmtg-root').style.display ='block';
  };

  const initiateMeeting = (signature) => {
    ZoomMtg.init({
      leaveUrl: leaveUrl,
      isSupportAV: true,
      success: (success) =>{       
         
         ZoomMtg.join({
              signature:signature,
              meetingNumber:meetingNumber,
              userName:userName,
              apiKey:apiKey,
              userEmail:userEmail,
              passWord:passWord,
              success:(success)=>{              
              },
              error:(error)=>{              
              }
         })
      },
      error:(error) =>{    
      }
    })
  };

  return <div className="App">Zoom Testing</div>;
};

export default ZoomStudent;
