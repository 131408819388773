import React, { useState,useEffect } from "react";
import configurl from "../../../config1.json";
import axios from "axios";
import { useTheme } from "@material-ui/styles";
//import { ZoomMtg } from 'zoomus-jssdk';
//import { ZoomMtg } from "@zoomus/websdk";
import Zoom  from '../../../components/zoomNew';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CssBaseline from "@material-ui/core/CssBaseline";
import CardContent from "@material-ui/core/CardContent";
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import EditIcon from '@material-ui/icons/Edit';
import swal from 'sweetalert';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import Eemail  from '../../../components/popup/Email';
import Ephone  from '../../../components/popup/Phone';

// styles
import useStyles from "./styles";
import "./zstyle.css";
import { Grid } from "@material-ui/core";


const mainChartData = getMainChartData();
const PieChartData = [
  { name: "Group A", value: 400, color: "primary" },
  { name: "Group B", value: 300, color: "secondary" },
  { name: "Group C", value: 300, color: "warning" },
  { name: "Group D", value: 200, color: "success" },
];

export default function Dashboard(props) {
  var classes = useStyles();
  var theme = useTheme();
  const [showFPTYPE, setShowFPTYPE] = useState(false);
  const [joinMeeting, setjoinMeeting] = useState(false);
  const [age, setAge] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [name, setName] = React.useState('');
  const [date, setDate] = React.useState('');
  const [country, setcountry] = React.useState([]);
  const [selectCountry, setSelectCountry] = React.useState([]);
  const [mstate, setMstate] = React.useState('');
  const [city, setCity] = React.useState('');
  const [cityid, setCityid] = React.useState('');
  const [getcity, setGetcity] = React.useState('');
  const [specialization, setSpecialization] = React.useState([])
  const [splValue, setSplValue] = React.useState('');
  const [categories, setCategories] = React.useState('');

  const [mail, setMail] = React.useState('');
  const [number, setNumber] = React.useState('');
  const [mcc_id, setMcc_id] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [file, setFile] = useState("");
  const baseUrl = configurl.baseUrl[0];

  
  const [Errname, setErrname] = useState("");
  const [Errphone, setErrErrphone] = useState("");
  const [Erremail, setErremail] = useState("");
  const [Errcity, setErrcity] = useState("");
  const [Errage, setErrage] = useState("");
  const [Errdob, setErrdob] = useState("");





useEffect(() => {
  var div = document.getElementById('pageTitle');
  div.innerHTML = "Marabu Nalam - Profile";

  var div = document.getElementById('crumbTitle');
  div.innerHTML = " Profile";
 userprofile() 
 feature()
 cate_id()
 getprofile()


}, []);

const choosecity=(data)=>{
  setCityid(data)
}
const choosestate1=(data)=>{

  var usertoken = localStorage.getItem('user'); 
  var test =  usertoken.replace(/[#[$\]\\"]/g,'');
  usertoken = 'Bearer '+test;
  axios({
    method: "get",
    url: baseUrl+"api/list/cities?id="+data,   
    headers: { 
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
       'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*' ,
        'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken}
       
  })
    .then(function (response) {
     
      if(response.status =='200'){
        setCity(response.data.data);
    
      }
     
    })
    .catch(function (error) {
   });

}

const chooseState =(data) =>{

  var usertoken = localStorage.getItem('user'); 
  var test =  usertoken.replace(/[#[$\]\\"]/g,'');
  usertoken = 'Bearer '+test;
   
  axios({
    method: "get",
    url: baseUrl+"api/list/states?id="+data,
   
    headers: { 
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
       'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*' ,
        'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken}
       
  })
    .then(function (response) {
     
      if(response.status =='200'){
        setMstate(response.data.data);
        //setcountry(response.data.data)
     
      }
     
    })
    .catch(function (error) {
   });

};
  
  //Get profile api call
    const userprofile=() =>{
      
      var usertoken = localStorage.getItem('user');
 
      var test =  usertoken.replace(/[#[$\]\\"]/g,'');
      usertoken = 'Bearer '+test;
       
      axios({
        method: "get",
        url: baseUrl+"api/list/countries",
       
        headers: { 
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
           'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*' ,
            'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, PATCH, DELETE',
            'Authorization': usertoken}
           
      })
        .then(function (response) {
      
          //handle success
          if(response.status =='200'){
            setcountry(response.data.data)
         
          }
         
        })
        .catch(function (error) {      
       });
    };
    const feature=() =>{
      
      var usertoken = localStorage.getItem('user');
 
      var test =  usertoken.replace(/[#[$\]\\"]/g,'');
      usertoken = 'Bearer '+test;
       
      axios({
        method: "get",
        url: baseUrl+"api/list/specialization",
       
        headers: { 
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
           'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*' ,
            'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, PATCH, DELETE',
            'Authorization': usertoken}
           
      })
        .then(function (response) {
      
          //handle success
          if(response.status =='200'){
            
            setSpecialization(response.data.data)
         
          }
         
        })
        .catch(function (error) {
         //handle error

       });
    };

    const cate_id=() =>{
      
      var usertoken = localStorage.getItem('user');
 
      var test =  usertoken.replace(/[#[$\]\\"]/g,'');
      usertoken = 'Bearer '+test;
       
      axios({
        method: "get",
        url: baseUrl+"api/list/categories",
       
        headers: { 
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
           'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*' ,
            'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, PATCH, DELETE',
            'Authorization': usertoken}
           
      })
        .then(function (response) {
      
         
          //handle success
          if(response.status =='200'){
            setCategories(response.data.data)
         
          }
         
        })
        .catch(function (error) {
         //handle error
         
                });
    };
    const eemail=()=>{
      swal("Type otp here:", {
        content: "input",
      })
      .then((value) => {
        swal(`You typed: ${value}`);
        
       
       var bodyFormData = new FormData();
          bodyFormData.append('email', email);
          bodyFormData.append('otp', value);           
         var usertoken = localStorage.getItem('user');
       var test =  usertoken.replace(/[#[$\]\\"]/g,'');
        usertoken = 'Bearer '+test;
        
          axios({
            method: "post",
            url: baseUrl+"admin/auth",
            data: bodyFormData,
            headers: { 
                       'Accept': 'application/json',
                       'X-Requested-With': 'XMLHttpRequest',
                       'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*' ,
                        'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                        'Authorization': usertoken}
          })
            .then(function (response) {         
              if(response.status =='200'){
           
                setEmail(response.data.data.email);
                swal("" ,"User details has been updated sucessfully!", "success");
              
              }
            })
            .catch(function (error) {       
            });    
      });
  
    }

    const ephone=()=>{
      swal("Enter otp  here:", {
        content: "input",
      })
      .then((value) => {
        swal(`You typed: ${value}`);

        
       
       var bodyFormData = new FormData();       
          bodyFormData.append('mobile ', parseInt(number));         
          bodyFormData.append('mcc_id ', mcc_id);
          bodyFormData.append('otp', value);            
         var usertoken = localStorage.getItem('user');
       var test =  usertoken.replace(/[#[$\]\\"]/g,'');
        usertoken = 'Bearer '+test;
        
          axios({
            method: "post",
            url: baseUrl+"admin/auth",
            data: bodyFormData,
            headers: { 
                       'Accept': 'application/json',
                       'X-Requested-With': 'XMLHttpRequest',
                       'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*' ,
                        'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                        'Authorization': usertoken}
          })
            .then(function (response) {
              if(response.status =='200'){           
                setEmail(response.data.data.email);
                swal("" ,"User details has been updated sucessfully!", "success");
              
              }
            })
            .catch(function (error) {       
            });    
      });
  
    }
    const update=()=>{
      if(!name){
        setErrname("Enter  a name");
      }
      if(!date){
        setErrdob("Enter  a date");
      }
      if(!age){
        setErrage("Enter a age");
      }
 
      var bodyFormData = new FormData();
      bodyFormData.append('title', title);
      bodyFormData.append('name', name);
      bodyFormData.append('dob', date);
      bodyFormData.append('age', parseInt(age));
      bodyFormData.append('city_id',parseInt(cityid) )
      bodyFormData.append('city', parseInt(1))  

     var usertoken = localStorage.getItem('user'); 
    var test =  usertoken.replace(/[#[$\]\\"]/g,'');
    usertoken = 'Bearer '+test;
    
      axios({
        method: "post",
        url: baseUrl+"admin/profile",
        data: bodyFormData,
        headers: { 
                   'Accept': 'application/json',
                   'X-Requested-With': 'XMLHttpRequest',
                   'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*' ,
                    'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                    'Authorization': usertoken}
      })
        .then(function (response) {
          if(response.status =='200'){
            setName(response.data.data.name)
            setTitle(response.data.data.title)
            setDate(response.data.data.dob)
            setAge(response.data.data.age)
            setNumber(response.data.data.mobile)
                   swal("" ,"User details has been updated sucessfully!", "success");
          
          }
        })
        .catch(function (error) {
       
        });
    };
   
    const getprofile=() =>{
      
      var usertoken = localStorage.getItem('user');
 
      var test =  usertoken.replace(/[#[$\]\\"]/g,'');
      usertoken = 'Bearer '+test;
       
      axios({
        method: "get",
        url: baseUrl+"admin/profile",
       
        headers: { 
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
           'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*' ,
            'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, PATCH, DELETE',
            'Authorization': usertoken}
           
      })
        .then(function (response) {
      
         
          //handle success
          if(response.status =='200'){     
            window.localStorage.clear();
            setName(response.data.data.name);
            setTitle(response.data.data.title);
            setDate(response.data.data.dob);
            setAge(response.data.data.age);         
            setEmail(response.data.data.email); 
            setNumber(response.data.data.mobile);
            setMcc_id(response.data.data.mcc_id)
        
  }        
        })
        .catch(function (error) {
         //handle error

       });
    };
    const image=(e)=>{    

      let files=e.target.files;  
      var bodyFormData = new FormData();
      bodyFormData.append('guard',"admin");
      bodyFormData.append('image', files[0]);     
  var usertoken = localStorage.getItem('user');  
     var test =  usertoken.replace(/[#[$\]\\"]/g,'');
     usertoken = 'Bearer '+test;
     
       axios({
         method: "post",
         url: baseUrl+"admin/image",
         data: bodyFormData,
         headers: { 
                    'Accept': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json',
                   'Access-Control-Allow-Origin': '*' ,
                     'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                     'Authorization': usertoken}
       })
         .then(function (response) {    
           if(response.status =='200'){
             setFile(response.data.data.image)
           }
         })
         .catch(function (error) {
    
         });
     };
    
  const clicksplData=(event) =>{

    setSplValue(event.target.value);
  };
  // local
  var [mainChartState, setMainChartState] = useState("monthly");

  return (
    <>
    <div className="ContainerSpace">
        <CssBaseline />
        <Grid container>
          <Grid item xs={12} md={12} lg={12} sm={12}>
            <div>
             
              <div className={classes.marginTop20px}>
             
                 
                  <Grid container>
                    <Grid item xs={12} md={12} lg={12} sm={12} lg={12}>
                        <Card className={classes.InsidebackCLR}>
                          <CardContent>
                            <Grid container spacing={2}>
                             
                                <Grid  item xs={12}sm={5}lg={1}>
                                <Avatar alt="Travis Howard" src={"https://acharya.heptotechnologies.org/storage/"+file}  className={classes.large}/>
                                <input accept="image/*" className={classes.input1} id="icon-button-file" type="file"  onChange={e=>image(e)}/>
      <label htmlFor="icon-button-file">
        <IconButton color="primary" aria-label="upload picture" component="span">
          <PhotoCamera />
        </IconButton>
      </label>
                                  </Grid>

                              <Grid  item xs={12} sm={6} lg={4}>
                              <TextField className={classes.inputbox}
                                          
                                        
                                              label="Title"
                                              multiline
                                              value={title}
                                              onChange={e => setTitle(e.target.value)}
                                             
                                            
                                            />
                                </Grid>

                                    <Grid  item xs={12} sm={6} lg={3}>
                                      <TextField className={classes.inputbox}
                                           variant="standard"
                                            // placeholder="Full Name"
                                             label="Full Name"
                                              multiline
                                              helperText ={Errname}
                                              error ={Errname}
                                              value={name}
                                              onChange={e => setName(e.target.value)}
                                           
                                            
                                            />
                                    </Grid>
                                    <Grid  item xs={12} sm={6} lg={2}>
                                    <TextField className={classes.inputbox1}
                                               disabled
                                               label="Mobile Number"
                                               value={number}
                                               onChange={e => setNumber(e.target.value)}
                                               multiline
                                               InputProps={{
                                                 endAdornment: (
                                                   <InputAdornment position="end">
                                                        {/* <Ephone id={number}  method={"admin"}/> */}
                                                     <EditIcon onClick={ephone} style={{cursor: 'initial'}} />
                                                   </InputAdornment>
                                                 ),
                                               }}
                                               
                                             />
                                      </Grid>
                              </Grid>
                          </CardContent>
                      </Card>
                  </Grid>
                 </Grid>
                <br />
                 <Grid container>
                    <Grid item xs={12} md={12} lg={12} sm={12} lg={12}>
                        <Card className={classes.InsidebackCLR}>
                          <CardContent>
                            <Grid container spacing={2}>
                             
                            
                          
                            <Grid  item xs={12} sm={6} lg={6}>
                            <TextField className={classes.inputbox1}
                                        value={email}
                                    label="Email"   
                                    disabled
                                    placeholder="Enter Email"                                 
                                    multiline
                                    onChange={e => setEmail(e.target.value)}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                       {/* <Button onClick={eemail} style={{cursor: 'initial',marginTop:"-6px",color:"#6bbb4c"}} > */}
                                         {/* <Eemail id={email}  method={"admin"}/> */}
                                         {/* </Button> */}
                                          <EditIcon   onClick={eemail} style={{cursor: 'initial'}}/>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                            </Grid> 
                            <Grid  item xs={12} sm={6} lg={3}>
                            <TextField className={classes.calendar}
                              id="date"
                              label="DOB"
                              type="date"
                              helperText ={Errdob}
                              error ={Errdob}
                              value={date}
                              onChange={e => setDate(e.target.value)}
                              // defaultValue="2017-05-24"
                              className={classes.textField}
                              InputLabelProps={{
                                shrink: true,
                              }}
                          />
                          </Grid>
                          <Grid  item xs={12} sm={6} lg={3}>
                            <TextField className={classes.inputbox1}
                                    id="standard-password-input"
                                    label="age"
                                    multiline
                                    value={age}
                                    helperText ={Errage}
                                    error ={Errage}
                                    onChange={e => setAge(e.target.value)}
                                  autoComplete="current-password"
                                  />
                          </Grid>
                          <Grid  item xs={12} sm={6} lg={6}>
                            <FormControl className={classes.formControl}>
                                  <InputLabel id="demo-simple-select-label" >Specialization</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={splValue}
                                    
                                    onChange={e=>clicksplData(e)}
                            
                                  >
                                        <MenuItem value="">
                                          <em>None</em>
                                        </MenuItem>
                                        {specialization.length >0 &&specialization.map((item) => (
                                          <MenuItem value={item.id}>{item.name}</MenuItem>
                                        ))} 
                                  </Select>
                                </FormControl>
                            </Grid>
                            <Grid  item xs={12} sm={6} lg={6}>
                            <FormControl className={classes.formControl}>
                                  <InputLabel id="demo-simple-select-label" >categories</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    // value={specialization}
                                    multiline
                            
                                  >
                                        <MenuItem value="">
                                          <em>None</em>
                                        </MenuItem>
                                        {categories.length >0 &&categories.map((item) => (
                                          <MenuItem value={item.id}>{item.name}</MenuItem>
                                        ))} 
                                  </Select>
                                </FormControl>
                            </Grid>
                            <Grid  item xs={12} sm={6} lg={6}>
                              <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      multiline
                                      // value={selectCountry}
                                      onClick={e => chooseState(e.target.value)}

                                      
                                  
                                    >
                                       <MenuItem value="">
                                          <em>None</em>
                                        </MenuItem>
                                        {country.map((item) => (
                                          <MenuItem value={item.id}>{item.name}</MenuItem>
                                        ))}  
                                    
                                    </Select>
                                  </FormControl>
                              </Grid>

                      
                            <Grid  item xs={12} sm={6} lg={3}>
                            <FormControl className={classes.formControl}>
                                  <InputLabel id="demo-simple-select-label">State</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    multiline
                                    onClick={e => choosestate1(e.target.value)}
                                    // value={selectCountry}
                                    // onClick={e => chooseState(e.target.value)}

                                    
                                
                                  >
                                     <MenuItem value="">
                                        <em>None</em>
                                      </MenuItem>
                                      {mstate.length >0 && mstate.map((item) => (
                                        <MenuItem value={item.id}>{item.name}</MenuItem>
                                      ))}  
                                  
                                  
                                  </Select>
                                </FormControl>
                            </Grid>
                                <Grid  item xs={12} sm={6} lg={3}>
                                <FormControl className={classes.formControl}>
                                      <InputLabel id="demo-simple-select-label">city</InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        multiline
                                    //  value={city}
                                    onClick={e => choosecity(e.target.value)}
                                      >
                                        <MenuItem value="">
                                        <em>None</em>
                                      </MenuItem>
                                      {city.length >0 && city.map((item) => (
                                        <MenuItem value={item.id}>{item.name}</MenuItem>
                                      ))}  
                                      </Select>
                                    </FormControl>
                                </Grid>
                                  <Grid  item xs={12} sm={12} lg={12}
                                  justify="center" className={classes.buttonsession}>
                                  <Button 
                            
                                  className={classes.button} onClick={update}>Complete My Profile Details</Button>
                                  
                                  </Grid> 
                              </Grid>
                          </CardContent>
                      </Card>
                  </Grid>
                 </Grid>

                 
                  
               
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
