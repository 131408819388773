import React, { useState, useEffect } from "react";
import axios from "axios";
import configurl from "../../../config1.json";
import { useTheme } from "@material-ui/styles";
//import { ZoomMtg } from 'zoomus-jssdk';
//import { ZoomMtg } from "@zoomus/websdk";
import Zoom from '../../../components/zoomNew';
import Eemail from '../../../components/popup/Email';
import Ephone from '../../../components/popup/Phone';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import EditIcon from '@material-ui/icons/Edit';
import swal from 'sweetalert';
import moment from "moment";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { Grid } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import CardContent from "@material-ui/core/CardContent";
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Badge from '@material-ui/core/Badge';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clogo from './359-3592153_firefox-60-shows-a-grey-image-background-on.png';
// import DateFnsUtils from '@date-io/date-fns';
// styles
import useStyles from "./styles";
import "./zstyle.css";

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    // border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);


const mainChartData = getMainChartData();
const PieChartData = [
  { name: "Group A", value: 400, color: "primary" },
  { name: "Group B", value: 300, color: "secondary" },
  { name: "Group C", value: 300, color: "warning" },
  { name: "Group D", value: 200, color: "success" },
];

export default function Dashboard(props) {
  var classes = useStyles();
  var theme = useTheme();
  const [age, setAge] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [name, setName] = React.useState('');
  const [date, setDate] = React.useState('');
  const [country, setCountry] = React.useState([]);

  const [mstate, setMstate] = React.useState('');
  const [city, setCity] = React.useState('');
  const [cityid, setCityid] = React.useState('');
  const [stateid, setStateid] = React.useState('');
  const [specialization, setSpecialization] = React.useState([])
  const [splValue, setSplValue] = React.useState('');
  const [interests, setInterests] = React.useState([])
  const [Practices, setPractices] = React.useState('');
  const [Discipline, setDiscipline] = React.useState('');
  const [NextDate,setNextDate]=React.useState( moment().format("YYYY-MM-DD"))


  const [file, setFile] = useState("");
  const [error, setError] = useState(null);
  const baseUrl = configurl.baseUrl[0];
  const [getcity, setGetcity] = React.useState('');
  const [selectCountry, setSelectCountry] = React.useState([]);
  const [getstate, setGetstate] = React.useState('');
  const [number, setNumber] = React.useState('');
  const [mcc_id, setMcc_id] = React.useState('');
  const [email, setEmail] = React.useState('');


  const [Errname, setErrname] = useState("");
  const [Errphone, setErrErrphone] = useState("");
  const [Erremail, setErremail] = useState("");
  const [Errcity, setErrcity] = useState("");
  const [Errage, setErrage] = useState("");
  const [Errdob, setErrdob] = useState("");

  useEffect(() => {
    var div = document.getElementById('pageTitle');
    div.innerHTML = "Marabu Nalam - Profile";

    var div = document.getElementById('crumbTitle');
    div.innerHTML = " Profile";

    userprofile();
    feature();
    cate_id();
    getprofile();
    setNextDate('"'+NextDate+'"')


  }, []);
  const funAgeCal = (data) => {
    setDate(data);
    var today = new Date();
    var birthDate = new Date(data);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    setAge(age);
  };

  const choosecity = (data) => {
    setCityid(data)
  }
  const choosestate1 = (data) => {
    setStateid(data);
    var usertoken = localStorage.getItem('user');

    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;

    axios({
      method: "get",
      url: baseUrl + "api/list/cities?id=" + data,

      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }

    })
      .then(function (response) {

        if (response.status == '200') {
          setCity(response.data.data);

        }

      })
      .catch(function (error) {
      });

  }

  const chooseState = (data) => {

    setSelectCountry(data);
    var usertoken = localStorage.getItem('user');
    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;

    axios({
      method: "get",
      url: baseUrl + "api/list/states?id=" + data,

      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }

    })
      .then(function (response) {

        if (response.status == '200') {
          setMstate(response.data.data);
        }

      })
      .catch(function (error) {
      });

  };


  //Get profile api call
  const userprofile = () => {

    var usertoken = localStorage.getItem('user');

    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;

    axios({
      method: "get",
      url: baseUrl + "api/list/countries",

      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }

    })
      .then(function (response) {

        if (response.status == '200') {
          setCountry(response.data.data)

        }

      })
      .catch(function (error) {
      });
  };
  const feature = () => {
    var usertoken = localStorage.getItem('user');
    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;
    axios({
      method: "get",
      url: baseUrl + "api/list/topicofinterests",
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }

    })
      .then(function (response) {
        //handle success
        if (response.status == '200') {
          setInterests(response.data.data)
        }
      })
      .catch(function (error) {
        //handle error
      });
  };

  const cate_id = () => {

    var usertoken = localStorage.getItem('user');
    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;
    axios({
      method: "get",
      url: baseUrl + "api/list/topicofinterests",
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }

    })
      .then(function (response) {
        //handle success
        if (response.status == '200') {
          setPractices(response.data.data)
        }
      })
      .catch(function (error) {
        //handle error
      });
  };

  const update = () => {

    if (!name) {
      setErrname("Enter  a name");
    }
    if (!date) {
      setErrdob("Enter  a date");
    }
    if (!age) {
      setErrage("Enter a age");
    }

    var bodyFormData = new FormData();
    bodyFormData.append('title', title);
    bodyFormData.append('name', name);
    bodyFormData.append('dob', date);
    bodyFormData.append('age', parseInt(age));
    bodyFormData.append('city_id', parseInt(cityid))
    // bodyFormData.append('email',email )

    bodyFormData.append('city', parseInt(1))
    bodyFormData.append('discipline_of_practice_id', parseInt(splValue));
    bodyFormData.append('topic_of_interest_id', parseInt(Discipline));

    var usertoken = localStorage.getItem('user');

    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;

    axios({
      method: "post",
      url: baseUrl + "student/profile",
      data: bodyFormData,
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }
    })
      .then(function (response) {
        if (response.status == '200') {
          setName(response.data.data.name);
          setTitle(response.data.data.title);
          setDate(response.data.data.dob);
          setAge(response.data.data.age);
          setNumber(response.data.data.mobile);
          setEmail(response.data.data.email);
          setFile(response.data.data.image);


          swal("", "User details has been updated sucessfully!", "success");
        }
      })
      .catch(function (error) {
      });
  };
  const image = (e) => {


    let files = e.target.files;

    var bodyFormData = new FormData();
    bodyFormData.append('image', files[0]);



    var usertoken = localStorage.getItem('user');

    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;

    axios({
      method: "post",
      url: baseUrl + "student/image",
      data: bodyFormData,
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }
    })
      .then(function (response) {
        if (response.status == '200') {
          setFile(response.data.data.image)



        }
      })
      .catch(function (error) {
      });
  };


  const getprofile = () => {

    var usertoken = localStorage.getItem('user');

    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;

    axios({
      method: "get",
      url: baseUrl + "student/profile",

      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }

    })
      .then(function (response) {

        //handle success
        if (response.status == '200') {


          setName(response.data.data.name)
          setTitle(response.data.data.title)
          setDate(response.data.data.dob)
          setAge(response.data.data.age)
          setNumber(response.data.data.mobile)
          // setGetcity(response.data.data.city.id)
          // setGetstate(response.data.data.city.state.id)
          // setSelectCountry(response.data.data.city.state.country.id)
          setFile(response.data.data.image)
          setDiscipline(response.data.data.discipline_of_practice_id)
          setSplValue(response.data.data.topic_of_interest_id)
          setEmail(response.data.data.email);
          setNumber(response.data.data.mobile);
          setMcc_id(response.data.data.mcc_id);
          setSelectCountry(response.data.data.city.state.country.id);



          if (response.data.data.city.state.country) {
            chooseState(response.data.data.city.state.country.id);
          }

          setCityid(response.data.data.city.id);

          if (response.data.data.city) {
            choosecity(response.data.data.city.id);
          }
          choosestate1(response.data.data.city.state.id);
          setStateid(response.data.data.city.state.id);

        }

      })
      .catch(function (error) {
      });
  };




  const ephone = () => {
    swal("Enter otp  here:", {
      content: "input",
    })
      .then((value) => {
        swal(`You typed: ${value}`);
        var bodyFormData = new FormData();

        bodyFormData.append('mobile ', parseInt(number));

        bodyFormData.append('mcc_id ', mcc_id);
        bodyFormData.append('otp', value);


        var usertoken = localStorage.getItem('user');
        var test = usertoken.replace(/[#[$\]\\"]/g, '');
        usertoken = 'Bearer ' + test;

        axios({
          method: "post",
          url: baseUrl + "student/auth",
          data: bodyFormData,
          headers: {
            'Accept': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
            'Authorization': usertoken
          }
        })
          .then(function (response) {
            if (response.status == '200') {

              setEmail(response.data.data.email);
              swal("", "User details has been updated sucessfully!", "success");

            }
          })
          .catch(function (error) {
          });
      });

  }

  const clicksplData = (event) => {

    setSplValue(event.target.value);
  };

  const clicksplData1 = (event) => {

    setDiscipline(event.target.value);
  };
  // local
  var [mainChartState, setMainChartState] = useState("monthly");





  return (
    <>
      <div className="ContainerSpace">
        <CssBaseline />
        <Grid container>
          <Grid item xs={12} md={12} lg={12} sm={12}>
            <div>

              <div className={classes.marginTop20px}>


                <Grid container>
                  <Grid item xs={12} md={12} lg={12} sm={12} lg={12}>
                    <Card className={classes.InsidebackCLR}>
                      <CardContent>
                        <Grid container spacing={2}>

                          <Grid item xs={12} sm={6} md={6} lg={1} className={classes.mainimg} >


                            <Badge
                              overlap="circle"
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              badgeContent={<> <input accept="image/*" className={classes.input1} id="icon-button-file" type="file" onChange={e => image(e)} />
                                <label htmlFor="icon-button-file">
                                  <IconButton color="primary" aria-label="upload picture" component="span">
                                    <SmallAvatar alt="Remy Sharp" src={clogo} onChange={e => image(e)} />
                                  </IconButton>
                                </label> </>}
                            >
                              <Avatar alt="Travis Howard"  src={
                                  baseUrl + "storage/" +
                                  file
                                } className={classes.large} />
                            </Badge>
                          </Grid>

                          <Grid item xs={12} sm={6} md={6} lg={5}>
                            <TextField className={classes.inputbox}


                              label="Title"
                              multiline
                              value={title}
                              onChange={e => setTitle(e.target.value)}


                            />
                          </Grid>

                          <Grid item xs={12} sm={6} lg={3}>
                            <TextField className={classes.inputbox}
                              variant="standard"
                              // placeholder="Full Name"
                              label="Full Name"
                              helperText={Errname}
                              error={Errname}
                              multiline
                              value={name}
                              onChange={e => setName(e.target.value)}


                            />
                          </Grid>
                          <Grid item xs={12} sm={6} lg={3}>
                            <TextField className={classes.inputbox1}

                              label="Mobile Number"
                              value={number}
                              onChange={e => setNumber(e.target.value)}
                              multiline
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Ephone id={number} method={"student"} />
                                    {/* <EditIcon onClick={ephone} style={{cursor: 'initial'}} /> */}
                                  </InputAdornment>
                                ),
                              }}

                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <br />
                <Grid container>
                  <Grid item xs={12} md={12} lg={12} sm={12} lg={12}>
                    <Card className={classes.InsidebackCLR}>
                      <CardContent>
                        <Grid container spacing={2}>



                          <Grid item xs={12} sm={6} lg={6}>
                            <TextField className={classes.inputbox1}
                              value={email}
                              label="Email"
                              placeholder="Enter Email"
                              multiline
                              onChange={e => setEmail(e.target.value)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {/* <Button onClick={eemail} style={{cursor: 'initial',marginTop:"-6px",color:"#6bbb4c"}} > */}
                                    <Eemail id={email} method={"student"} />
                                    {/* </Button> */}
                                    {/* <EditIcon   onClick={eemail} style={{cursor: 'initial'}}/> */}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} lg={3}>
                            <TextField className={classes.calendar}
                              style={{ width: "250px" }}
                              inputProps={{  max: NextDate}}
                              id="date"
                              label="DOB"
                              type="date"
                              value={date}
                              helperText={Errdob}
                              error={Errdob}
                              //onChange={e => setDate(e.target.value)}
                              onChange={e => funAgeCal(e.target.value)}
                              // defaultValue="2017-05-24"
                              className={classes.textField}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} lg={3}>
                            <TextField className={classes.inputbox1}
                              id="standard-password-input"
                              label="age"
                              helperText={Errage}
                              error={Errage}
                              multiline
                              value={age}
                              onChange={e => setAge(e.target.value)}
                              autoComplete="current-password"
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} lg={6}>
                            <FormControl className={classes.formControl}>
                              <InputLabel id="demo-simple-select-label">Country</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                multiline
                                value={selectCountry}
                                onClick={e => chooseState(e.target.value)}
                                defaultValue={selectCountry}


                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {country.map((item) => (
                                  <MenuItem value={item.id}>{item.name}</MenuItem>
                                ))}

                              </Select>
                            </FormControl>
                          </Grid>


                          <Grid item xs={12} sm={6} lg={3}>
                            <FormControl className={classes.formControl}>
                              <InputLabel id="demo-simple-select-label">State</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                multiline
                                value={stateid}
                                defaultValue={stateid}
                                onClick={e => choosestate1(e.target.value)}
                              // value={selectCountry}
                              // onClick={e => chooseState(e.target.value)}



                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {mstate.length > 0 && mstate.map((item) => (
                                  <MenuItem value={item.id}>{item.name}</MenuItem>
                                ))}


                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={3}>
                            <FormControl className={classes.formControl}>
                              <InputLabel id="demo-simple-select-label">city</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                multiline
                                value={cityid}
                                defaultValue={cityid}
                                onClick={e => choosecity(e.target.value)}

                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>

                                {city.length > 0 && city.map((item) => (
                                  <MenuItem value={item.id}>{item.name}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>


                          <Grid item xs={12} sm={6} lg={6}>
                            <FormControl className={classes.formControl}>
                              <InputLabel id="demo-simple-select-label" >Topic of interests</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={splValue}

                                onChange={e => clicksplData(e)}

                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {interests.length > 0 && interests.map((item) => (
                                  <MenuItem value={item.id}>{item.name}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={6}>
                            <FormControl className={classes.formControl}>
                              <InputLabel id="demo-simple-select-label" >Discipline Of Practices</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={Discipline}
                                multiline
                                onChange={e => clicksplData1(e)}
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {Practices.length > 0 && Practices.map((item) => (
                                  <MenuItem value={item.id}>{item.name}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={12} lg={12}
                            justify="center" className={classes.buttonsession}>
                            <Button

                              class='button' onClick={update}>Complete My Profile Details</Button>

                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>




              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
