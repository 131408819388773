import React, { useState, useEffect } from "react";
import { ZoomMtg } from '@zoomus/websdk';
import { Zoom } from "@material-ui/core";
import { ZoomOutMapTwoTone } from "@material-ui/icons";
import axios from "axios";
import configurl from "../config1.json";
import { isMobile, deviceDetect } from "react-device-detect";
var base_url = window.location.origin;

var apiKey = 'dGR3YICpR4-zjcy6w0msvw';
var apiSecret = 'rDH6hydlc4U5vONxzLQPtrz0bv7ypg7u1pBJ';
var meetingNumber = '83392115317';
var role = 1;
var leaveUrl = base_url + '/';
var userName = 'Trainer';
var userEmail = 'marabuvaidyam@gmail.com';
var passWord = '046772';


const ZoomTrainer = (props) => {



  const baseUrl = configurl.baseUrl[0];
  const [MeetingData, setMeetingData] = useState([]);


  useEffect(() => {
    setMeetingData(props.Meeting);
    // debugger;

    showZoomDiv();
    //ZoomMtg.setZoomJSLib('node_modules/zoomus/websdk/dist/lib', '/av');
    //ZoomMtg.setZoomJSLib("https://jssdk.zoomus.cn/1.9.1/lib", "/av"); true
    ZoomMtg.setZoomJSLib("https://source.zoom.us/1.9.0/lib", "/av");
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareJssdk();

    /**
     * You should not visible api secret key on frontend
     * Signature must be generated on server
     * https://marketplace.zoom.us/docs/sdk/native-sdks/web/essential/signature
     */

    ZoomMtg.generateSignature({
      meetingNumber: String(props.Meeting.zoom_meeting_id),
      apiKey: apiKey,
      // leaveUrl:leaveUrl,
      // userName: userName,
      // userEmail: userEmail,
      // passWord: 'Clock2021&',
      apiSecret: apiSecret,
      role: role,
      success: function (res) {
        setTimeout(() => {
          initiateMeeting(res.result, props.Meeting);
        }, 1000);
      },
    });

    //initiateMeeting();
  }, []);

  //get meeting details    

  const showZoomDiv = () => {
    document.getElementById('zmmtg-root').style.display = 'block';
    document.getElementById('zmmtg-root').style.width = '100%';
    document.getElementById('zooHeader').style.display = 'none';
    if (!isMobile) {
      document.getElementById('zooSidebar').style.display = 'none';
    }
  };

  const initiateMeeting = (signature, data) => {
    // debugger
    ZoomMtg.init({
      leaveUrl: leaveUrl,
      isSupportAV: true,
      success: (success) => {

        ZoomMtg.join({
          signature: signature,
          meetingNumber: String(data.zoom_meeting_id),
          userName: window.localStorage.getItem('Uname') + ' - Trainer',
          apiKey: apiKey,
          userEmail: String(data.schedule_for),
          passWord: String(data.hash_password),
          success: (success) => {

          },
          error: (error) => {

          }
        })
      },
      error: (error) => {

      }
    })
  };

  return <div className="App">Zoom Testing</div>;
};

export default ZoomTrainer;
