import React,{ useState } from "react";
import { Grid,
  CircularProgress,
  Typography,
  Button,
  Card,
  Tabs,
  Tab,
  TextField,
  Fade, } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import axios from "axios";
import FormData from "form-data";
import configurl from "../../config1.json";
//import "./zstyle.css";

// styles
import useStyles from "./styles";

// logo
import logo from "./home.png";
import logolog from "./logolog.png";
import OtpInput from 'react-otp-input';
import google from "../../images/google.svg";

// context
import { useUserDispatch, loginUser } from "../../context/UserContext";

const Privacy = (props) =>{
//function Login(props) {
  var classes = useStyles();

  var userDispatch = useUserDispatch();
  var [otp, setOTP] = useState("");
 

  const baseUrl = configurl.baseUrl[0];


  return (
    <Grid container  >
      
   
      <p>Privacy Policy</p>
        
        
      
    </Grid>
  );
}

export default withRouter(Privacy);
