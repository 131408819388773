import React, { useState, useEffect } from "react";
import axios from 'axios';
import configurl from "../../config1.json";
import { useLocation, useHistory } from "react-router-dom";
import { green } from '@material-ui/core/colors';
import Avatar from '@material-ui/core/Avatar';
import hlogo from "./logolog.png";
import Card from '@material-ui/core/Card';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { isMobile, deviceDetect } from "react-device-detect";

import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Fab,
  Link
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  MailOutline as MailIcon,
  NotificationsNone as NotificationsIcon,
  Person as AccountIcon,
  Search as SearchIcon,
  Send as SendIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import classNames from "classnames";

// styles
import useStyles from "./styles";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// components
import { Badge, Typography, Button } from "../Wrappers";
import Notification from "../Notification/Notification";
import UserAvatar from "../UserAvatar/UserAvatar";
import logo from './Untitled.png'

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../context/UserContext";
import { Grid } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';


const StyledToolbar = withStyles({
  root: {
    background: 'white',
    border: 0,
    color: 'black',
    height: '10px !important',
    padding: '0 30px',
  },

})(Toolbar);


export default function Header(props) {
  var classes = useStyles();
  const history = useHistory();

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  // local
  var [mailMenu, setMailMenu] = useState(null);
  var [isMailsUnread, setIsMailsUnread] = useState(true);
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  var [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
  var [profileMenu, setProfileMenu] = useState(null);
  var [isSearchOpen, setSearchOpen] = useState(false);
  const [TitleName, setTitleName] = useState('MARABU NALAM');
  const baseUrl = configurl.baseUrl[0];
  const [sTime, setSTime] = useState('');
  const [getdata, getData] = useState('');

  //Beginning the Page
  useEffect(() => {
    var pathname = window.location.href;
    userprofile();
  }, []);

  //Get profile api call
  const userprofile = () => {

    var urldata = "";
    if (localStorage.getItem('access') == 1) {
      var urldata = baseUrl + "student/profile"
      setTitleName('MARABU NALAM');
    }
    else if (localStorage.getItem('access') == 2) {
      var urldata = baseUrl + "trainer/profile"
      setTitleName('MARABU NALAM');
    }
    else if (localStorage.getItem('access') == 0) {
      var urldata = baseUrl + "admin/profile"
      setTitleName('MARABU NALAM - Admin');
    }

    var usertoken = localStorage.getItem('user');

    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;
    if (localStorage.getItem('access')) {
      axios({
        method: "get",
        url: urldata,
        headers: {
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
          'Authorization': usertoken
        }
      })
        .then(function (response) {

          //handle success
          if (response.status == '200') {
            var result = response.data.data
            window.localStorage.setItem('Uname', result.name);
            getData(result)


          }
          console.log(response.status);
        })
        .catch(function (error) {

          console.log(error.response);
          if (error.response.status === 401) {

            if (localStorage.getItem('access') == '1') {
              localStorage.removeItem('access');
              history.push("/e", true);
            }
            else if (localStorage.getItem('access') == '2') {
              history.push("/trainerLogin");
            }
            else {
              history.push("/trainerLogin");
            }
          }

        });
    } else {
      history.push("/trainerLogin");
    }
  };

  const reachProfile = () => {

    if (localStorage.getItem('access') == '1') {
      history.push("/app/student/Profile");
    }
    else if (localStorage.getItem('access') == '2') {
      history.push("/app/trainer/Profile");
    } else if (localStorage.getItem('access') == '0') {
      history.push("/app/admin/Profile");
    }
  };
  const handleClickHrf = (event) => {
  }

  return (
    <>

      <AppBar style={{ color: '#000000', boxShadow:"none" }} position="fixed" className={classes.appBar} id='zooHeader'>

        <StyledToolbar {...props} style={{ backgroundColor: '#ffffff' }} className={classes.appBar} >
          <IconButton
            color="inherit"
            onClick={() => toggleSidebar(layoutDispatch)}
            className={classNames(
              classes.headerMenuButtonSandwich,
              classes.headerMenuButtonCollapse,
            )}
          >
            {layoutState.isSidebarOpened ? (
              <MenuIcon style={{ color: "#3b5fb7", border: "1px solid #3b5fb7", }}
                classes={{
                  root: classNames(
                    classes.headerIcon,
                    classes.headerIconCollapse,
                  ),
                }}
              />
            ) : (<ArrowBackIcon
              style={{ color: "#3b5fb7", border: "1px solid #3b5fb7", }}
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
            )}
          </IconButton>
          <Avatar alt="" src={hlogo} className={classes.small} />
          {/* <Typography variant="h6" weight="medium" className={classes.logotype}>
          
          {TitleName}
        
        </Typography> */}
          {!isMobile && (<img src={logo} style={{ width: "150px" }}></img>)}
          <div className={classes.grow} />

          <div
            className={classNames(classes.search, {
              [classes.searchFocused]: isSearchOpen,
            })}
          >

          </div>

          <IconButton
            aria-haspopup="true"
            color="inherit"
            className={classes.headerMenuButton}
            aria-controls="profile-menu"
            onClick={e => setProfileMenu(e.currentTarget)}
          >
            <Typography variant="h6" weight="medium">
              {getdata ? getdata.name : "Trainer"}


            </Typography>
            <ArrowDropDownIcon
            // style ={{color: "#3b5fb7", border: "1px solid #3b5fb7",}}
            />

          </IconButton>

          <IconButton
            aria-haspopup="true"

            className={classes.headerMenuButton}

            aria-controls="profile-menu1"
            //onClick={e => setProfileMenu(e.currentTarget)}
            onClick={() => signOut(userDispatch, props.history)}
          >

            <ExitToAppIcon style={{ color: "#3b5fb7", border: "1px solid #3b5fb7", }} classes={{ root: classes.headerIcon }} />

          </IconButton>
          {/* <Menu
          id="mail-menu"
          open={Boolean(mailMenu)}
          anchorEl={mailMenu}
          onClose={() => setMailMenu(null)}
          MenuListProps={{ className: classes.headerMenuList }}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              New Messages
            </Typography>
            <Typography
              className={classes.profileMenuLink}
              component="a"
              color="secondary"
            >
              {messages.length} New Messages
            </Typography>
          </div>
          {messages.map(message => (
            <MenuItem key={message.id} className={classes.messageNotification}>
              <div className={classes.messageNotificationSide}>
                <UserAvatar color={message.variant} name={message.name} />
                <Typography size="sm" color="text" colorBrightness="secondary">
                  {message.time}
                </Typography>
              </div>
              <div
                className={classNames(
                  classes.messageNotificationSide,
                  classes.messageNotificationBodySide,
                )}
              >
                <Typography weight="medium" gutterBottom>
                  {message.name}
                </Typography>
                <Typography color="text" colorBrightness="secondary">
                  {message.message}
                </Typography>
              </div>
            </MenuItem>
          ))}
          <Fab
            variant="extended"
            color="primary"
            aria-label="Add"
            className={classes.sendMessageButton}
          >
            Send New Message
            <SendIcon className={classes.sendButtonIcon} />
          </Fab>
        </Menu> */}
          {/* <Menu
          id="notifications-menu"
          open={Boolean(notificationsMenu)}
          anchorEl={notificationsMenu}
          onClose={() => setNotificationsMenu(null)}
          className={classes.headerMenu}
          disableAutoFocusItem
        >
          {notifications.map(notification => (
            <MenuItem
              key={notification.id}
              onClick={() => setNotificationsMenu(null)}
              className={classes.headerMenuItem}
            >
              <Notification {...notification} typographyVariant="inherit" />
            </MenuItem>
          ))}
        </Menu> */}
          <Menu
            id="profile-menu"
            open={Boolean(profileMenu)}
            anchorEl={profileMenu}
            onClose={() => setProfileMenu(null)}
            className={classes.headerMenu}
            classes={{ paper: classes.profileMenu }}
            disableAutoFocusItem
          >

            <MenuItem style={{ color: "#3b5fb7", border: "1px solid #3b5fb7", }}
              onClick={e => reachProfile(e)}
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem,
              )}
            >
              <AccountIcon className={classes.profileMenuIcon}

              /> Profile-{getdata.email}
            </MenuItem>
            <MenuItem
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem,
              )}
            >
              <AccountIcon className={classes.profileMenuIcon} /> Tasks
            </MenuItem>
            <MenuItem
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem,
              )}
            >
              <AccountIcon className={classes.profileMenuIcon} /> Messages
            </MenuItem>
            {/* <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => signOut(userDispatch, props.history)}
            >
              Sign Out
            </Typography>
          </div> */}
          </Menu>



        </StyledToolbar>

        <Card style={{ boxShadow:"none", padding: "6px 0px 0px 2px", background: "#3b5fb7",height: "38px", borderBottom:"1px solid #9daed5" }}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb"
            className={classes.Breadcrumbs} >
            <Link color="inherit" href="/"
            // onClick={handleClick}
            >
              Home
            </Link>
            {/* <Link color="inherit" 
        // href="/getting-started/installation/" 
         onClick={(e) => handleClickHrf(e)}
        >
          Dashboard
        </Link>*/}
            <Typography color="textPrimary" id='crumbTitle'> </Typography>
          </Breadcrumbs>
        </Card>
      </AppBar>


    </>
  );
}
