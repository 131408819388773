import React, { useState, useEffect } from "react";
import axios from "axios";
import configurl from "../../../config1.json";
import { useTheme } from "@material-ui/styles";
//import { ZoomMtg } from 'zoomus-jssdk';
//import { ZoomMtg } from "@zoomus/websdk";
import Zoom from '../../../components/zoomNew';
import Button from '@material-ui/core/Button';
import { Grid, CardContent, Card, TextField, Avatar, FormControl, Select, MenuItem, InputLabel, Typography, Divider } from "@material-ui/core";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ShopTwoIcon from '@material-ui/icons/ShopTwo';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ShareIcon from '@material-ui/icons/Share';
import SearchIcon from '@material-ui/icons/Search';
import CardMedia from '@material-ui/core/CardMedia';
import ReactPlayer from 'react-player'
import ModalVideo from 'react-modal-video'
// import YouTubePlayer from 'youtube-player';
// import  YoutubePlayer   from "reactjs-media";
// import  YouTubePlayer  from "reactjs-media/audio";
// styles
import useStyles from "./styles";
import "../../zstyle.css";
import logo from "./user_new.png";

// img
import image from "../image/images.jfif"

const mainChartData = getMainChartData();
const PieChartData = [
  { name: "Group A", value: 400, color: "primary" },
  { name: "Group B", value: 300, color: "secondary" },
  { name: "Group C", value: 300, color: "warning" },
  { name: "Group D", value: 200, color: "success" },
];



export default function Gallery(props) {
  var classes = useStyles();
  var theme = useTheme();
  const [showFPTYPE, setShowFPTYPE] = useState(false);
  const [joinMeeting, setjoinMeeting] = useState(false);
  const [vidio, setVidio] = useState('');
  const [topic, setTopic] = useState('');
  const [duration, setDuration] = useState('');
  const [meeting, setMeeting] = useState('');
  const [isOpen, setOpen] = useState(false);
  const [CurUrl, setCurUrl] = useState('');
  // local
  var [mainChartState, setMainChartState] = useState("monthly");
  const baseUrl = configurl.baseUrl[0];

  useEffect(() => {

    var div = document.getElementById('pageTitle');
    div.innerHTML = "Marabu Nalam - Gallery";

    var div = document.getElementById('crumbTitle');
    div.innerHTML = "Gallery";
    getprofile()

  }, []);
  const getprofile = () => {

    var usertoken = localStorage.getItem('user');

    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;

    axios({
      method: "get",
      url: baseUrl + "student/gallery/free",

      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }

    })
      .then(function (response) {

        //handle success
        if (response.status == '200') {
          setMeeting(response.data.data.values)
          console.log(response.data.data.values);
        }

      })
      .catch(function (error) {
        //handle error
      });
  };

  const FunPlayEnb = (video) => {
    setCurUrl(video.url);
    setOpen(true);
  };

  return (

    <>

      <Grid class='box'>
        <SearchIcon style={{ fontSize: "18px", }}></SearchIcon>
        <input placeholder="Search Topic, Sub Topic,Session Title"></input>
      </Grid>
      {/* top session */}
      <Grid container spacing={1} style={{ marginTop: "10px" }}>


        <Grid item xs={12} md={12} lg={12} sm={12} lg={1}>
          Topics:

</Grid>

        <Grid item xs={12} md={12} lg={12} sm={12} lg={1}>
          <Typography class='CardButton'>All </Typography>

        </Grid>
        {meeting.length > 0 && meeting.map((item) => (
          <Grid item xs={12} md={12} lg={12} sm={12} lg={1}>
            <Typography class='gallerybutton'>{item.meetings.topic} </Typography>
          </Grid>
        ))}
        <Grid item xs={12} md={12} lg={12} sm={12} lg={1}>
          <Typography class='gallerybutton'>Surgery </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sm={12} lg={1}>
          <Typography class='gallerybutton'>General </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sm={12} lg={1}>
          <Typography class='gallerybutton'>Dermatology </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sm={12} lg={1}>
          <Typography class='gallerybutton'>Dental </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sm={12} lg={1}>
          <Typography class='gallerybutton'>Nurology </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12} sm={12} lg={1}>
          <Typography class='gallerybutton'>Root Canel </Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={12} sm={12} lg={3}>
          <form className={classes.container} noValidate>
            <TextField style={{ padding: "0px" }}
              fullWidth
              id="time"
              label="Alarm clock"
              type="time"
              defaultValue="07:30"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </form>
        </Grid>
      </Grid>


      {/* vidio session */}
      <Grid item xs={12} md={12} lg={12} sm={12} lg={12}>
        <Card className={classes.InsidebackCLR}>
          <CardContent>
            <Grid item xs={12} sm={12} lg={12}>
              <Divider />
            </Grid>

            <Grid container spacing={1} style={{ justifyContent: "flex-end", padding: "10px" }}>
              {isOpen === false && meeting.length > 0 && meeting.map((item) => (
                <Grid item xs={12} sm={12} lg={12} >
                  {item.gallery_map.map((items) => (
                    items.gallery_part.length > 0 && items.gallery_part.map((fvideo) => (
                      <><Card className={classes.image} style={{ marginTop: '5px' }}>
                        <CardContent className={classes.image1} style={{ paddingBottom: "0px" }} >
                          <Grid container >
                            <Grid item xs={6} sm={6} lg={2}>
                              <img src={"https://acharya.heptotechnologies.org/storage/" + fvideo.thumbnail}
                                onClick={() => FunPlayEnb(fvideo)} style={{ width: "150px", height: '75px' }}
                              ></img>
                            </Grid>
                            <Grid item xs={6} sm={6} lg={10} className={classes.table}  >
                              <Typography class='tabletext' style={{ marginTop: "0px" }}>
                                {item.meetings.topic + " part " + fvideo.part}
                              </Typography>
                              <Typography /*variant="h5"*/ class='tabletext1'>
                                {item.meetings.content}
                              </Typography>
                              <Grid container spacing={0}>
                                <Grid item xs={12} lg={12}>
                                  <Grid container style={{ marginTop: "2px" }} >
                                    <Grid item xs={12} lg={6}  >
                                      <Typography class='tabletext1' >
                                        {item.meetings.start_time}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={6} className={classes.tabletext2} >
                                      {item.meetings.duration} <PlayCircleFilledIcon className={classes.playicon} />
                                    </Grid>
                                  </Grid>
                                </Grid>

                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card></>
                    ))
                  ))}

                </Grid>
              ))}

              {isOpen && (<><Button variant="contained" color="secondary" onClick={() => setOpen(false)}>
                Close
                             </Button><br /><ReactPlayer
                  controls
                  height="600px"
                  width='100%'
                  url={CurUrl} />
              </>)}

            </Grid>
          </CardContent>
        </Card>
      </Grid>





    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
