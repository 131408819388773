import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from '@material-ui/core/Avatar';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {

  const [open, setOpen] = React.useState(false);
  const [userID, setUserId] =React.useState([]);
  var classes = useStyles();

   useEffect(() => {

    //setOpen(true);
   // setUserId(props.userid);

   }, []);

  const handleClickOpen = () => {
    setOpen(true);
    setUserId(props.userid);
    
  };

  const handleClose = () => {
   
    setOpen(false);
  };

  return (
    <div>
      <Button   color="primary" onClick={handleClickOpen}>
      <Tooltip title="Quick-View">
                        <VisibilityIcon className={classes.view}/>
                      </Tooltip>
      </Button> 
      <Dialog fullWidth maxWidth="xs"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle id="alert-dialog-slide-title">{"Student Profile"}</DialogTitle> */}
        <DialogContent>
        <Grid container spacing={2}>
                              {/* {MrandMsload.length > 0 && ( */}
                               
                              {/* )} */}
                              <Grid item md={8} sm={6} xs={12} lg={4}>
                              <Avatar alt="Remy Sharp" src= {  "https://acharya.heptotechnologies.org/storage/"+userID.image}  style={{width:"70px",height:"70px"}}/>
                              </Grid>
                       

                              <Grid item md={8} sm={6} xs={12} lg={7}>
                              <Typography style={{marginTop:"5px"}}
                                    className={classes.Custlablecolor}
                                    color="textSecondary"
                                  >
                                   Student Name
                                  </Typography>
                                  <Typography variant="body1">Mr.{userID.name}</Typography>
                              </Grid>

                              <Grid item md={8} sm={6} xs={12} lg={4}>
                              <Typography
                                    className={classes.Custlablecolor}
                                    color="textSecondary"
                                  >
                                    Mobile Number
                                  </Typography>
                                  <Typography variant="body1">{userID.mobile}</Typography>
                              </Grid>
                              <Grid item md={8} sm={6} xs={12} lg={5}>
                              <Typography
                                    className={classes.Custlablecolor}
                                    color="textSecondary"
                                  >
                                   email
                                  </Typography>
                                  <Typography variant="body1">{userID.email}</Typography>
                              </Grid>
                              <Grid item md={8} sm={6} xs={12} lg={4}>
                              <Typography
                                    className={classes.Custlablecolor}
                                    color="textSecondary"
                                  >
                                   age
                                  </Typography>
                                  <Typography variant="body1">{userID.age}</Typography>
                              </Grid>
                              <Grid item md={8} sm={6} xs={12} lg={5}>
                              <Typography
                                    className={classes.Custlablecolor}
                                    color="textSecondary"
                                  >
                                   Date of birth
                                  </Typography>
                                  <Typography variant="body1">{userID.dob}</Typography>
                              </Grid>

                             
                            </Grid>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose} color="primary">
            Disagree
          </Button> */}
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
