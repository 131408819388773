import React, { useState, useEffect } from "react";
import axios from "axios";
import configurl from "../../config1.json";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide() {
  const [open, setOpen] = React.useState(false);
  const [ShowList, setShowList] = React.useState(true);
  const [ShowNew, setShowNew] = React.useState(false);
  const [ShowCUpdate, setShowCUpdate] = React.useState(false);
  const [ShowCSave, setShowCSave] = React.useState(true);
  const [country, setcountry] = React.useState([]);
  const [cuid, setCUid] = React.useState([]);
  const [cname, setCName] = React.useState('');
  const baseUrl = configurl.baseUrl[0];



  const handleClickOpen = () => {
    setOpen(true);
    setShowList(true);
    setShowNew(false);
  };
  const handleNewctryOpen = () => {
    setShowList(false);
    setShowNew(true);
  };
  const handleNewctryClose = () => {
    setShowList(true);
    setShowNew(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#3B5FB7',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow)


  useEffect(() => {
    getcountry();
  }, []);


  const editbutton = (row) => {
    setShowCUpdate(true);
    setShowCSave(false);
    handleNewctryOpen();
    setCName(row.name);
    setCUid(row.id);

  };
  const hidebtn = () => {
    setShowCUpdate(false);
    setShowCSave(true);
  };
  const onclick = () => {
    handleNewctryOpen();
    hidebtn();
  };
  const getcountry = () => {

    var usertoken = localStorage.getItem('user');
    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;

    axios({
      method: "get",
      url: baseUrl + "api/list/countries",

      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }

    })
      .then(function (response) {
        //handle success
        if (response.status == '200') {
          setcountry(response.data.data)
        }
      })
      .catch(function (error) {
      });
  };

  const createCountry = () => {

    var usertoken = localStorage.getItem('user');
    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;
    var bodyFormData = new FormData();
    bodyFormData.append('name', cname);
    bodyFormData.append('guard', 'country');

    axios({
      method: "post",
      url: baseUrl + "admin/csc",
      data: bodyFormData,
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }
    })
      .then(function (response) {

        //handle success
        if (response.status == '200') {
          setCName('');
          handleNewctryClose();
          getcountry();
        }
      })
      .catch(function (error) {
      });
  };

  const cdelete = (row) => {
    var usertoken = localStorage.getItem('user');
    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;
    var bodyFormData = new FormData();
    bodyFormData.append('guard', 'country');
    bodyFormData.append('_method', 'delete');
    bodyFormData.append('id', parseInt(row.id));

    axios({
      method: "post",
      url: baseUrl + "admin/csc",
      data: bodyFormData,
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }

    })
      .then(function (response) {
        //handle success
        if (response.status == '200') {
          //alert("123")
        }
      })
      .catch(function (error) {
      });
  };

  const cupdate = () => {
    var usertoken = localStorage.getItem('user');
    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;
    var bodyFormData = new FormData();
    bodyFormData.append('guard', 'country');
    bodyFormData.append('_method', 'put');
    bodyFormData.append('name', cname);
    bodyFormData.append('id', parseInt(cuid));
    axios({
      method: "post",
      url: baseUrl + "admin/csc",
      data: bodyFormData,
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }

    })
      .then(function (response) {
        //handle success
        if (response.status == '200') {
          //alert("123")
          setShowNew(false);
          setShowList(true)
        }
      })
      .catch(function (error) {
      });
  };
  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Country
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <Typography variant="h6" gutterBottom>
                Country
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Button variant="contained"
                color="secondary" onClick={onclick}  >
                New&nbsp;Country
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {ShowList === true && <DialogContentText id="alert-dialog-slide-description">
            <TableContainer >
              <Table aria-label="customized table">
                <TableHead >
                  <TableRow style={{ backgroundColor: "blue" }}>
                    <StyledTableCell size="small">Sl no</StyledTableCell>
                    <StyledTableCell size="small" align="right">Country</StyledTableCell>
                    <StyledTableCell size="small" align="right">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {country.map((row, index) => (
                    <StyledTableRow key={row.index}>
                      <StyledTableCell component="th" scope="row">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Button    >
                          <EditIcon

                            onClick={() => editbutton(row)}
                          />
                        </Button>
                        {/* <Button onClick={()=>cdelete(row)}>
                <DeleteForeverIcon/>
                </Button> */}

                      </StyledTableCell>

                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>}

          {ShowNew === true && <DialogContentText id="alert-dialog-slide-description">
            <Grid item xs={12} md={12} sm={12} lg={12} style={{ textAlign: "end" }}>
              <TextField

                margin="normal"
                inputProps={{ style: { borderColor: '#F75740 !important' } }}
                multiline
                value={cname}
                fullWidth
                label="Country Name"
                type="text"
                onChange={(e) => setCName(e.target.value)}
              />
              {ShowCSave === true &&
                <Button variant="outlined"
                  color="primary"
                  onClick={createCountry}
                >
                  Save
                </Button>
              }
              {ShowCUpdate === true &&
                <Button variant="outlined"
                  color="primary"
                  onClick={cupdate}
                >
                  Update
                </Button>}
            </Grid>

          </DialogContentText>}

        </DialogContent>
        <DialogActions>

          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
