import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import swal from 'sweetalert';
import axios from "axios";
import configurl from "../../config1.json";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
  
});

export default function AlertDialogSlide(props) {

  const [open, setOpen] = React.useState(false);
  const [topic, setTopic] =React.useState([]);
  const [id, setID] =React.useState([]);
  const [curSelId,setcurSelId]=useState([]);
  const [meetingList, setmeetingList]=useState([]);
  const [type, setType]=useState([]);
  const baseUrl = configurl.baseUrl[0];
  var classes = useStyles();



   useEffect(() => {

    //setOpen(true);
   // setUserId(props.userid);

   }, []);

  const handleClickOpen = () => {
    setOpen(true);
    setTopic(props.userid.topic);
    setID(props.userid.id);
    setType(props.userid.type)
  };

  const handleClose = () => {
   
    setOpen(false);
  };
  const selectdata =(prop, event, index, elem) =>{
   
    
    let newArr = [...meetingList];

    
    let newStateArray = [];
    newArr.map(function (item, index) {           
      var x = {
        Status: false,
        agenda: item.agenda,
        cduration: item.duration,
        Meeting_time: item.start_time,
        topic: item.topic,
    
      };
      newStateArray.push(x);
    });
    if (newStateArray[index].Status == false) {
      newStateArray[index].Status = true;

      setcurSelId(elem);
    
    } else if (newStateArray[index].Status == true) {
      newStateArray[index].Status = false;
    }
    setmeetingList(newStateArray);
  };
  const deletemeeting = () =>{
   

    setOpen(true);
  
    var usertoken = localStorage.getItem('user');
    var test =  usertoken.replace(/[#[$\]\\"]/g,'');
    usertoken = 'Bearer '+test;
    var bodyFormData = new FormData();

  
    bodyFormData.append('id',parseInt(id));

    bodyFormData.append('_method',"delete");

  
    axios({
      method: "post",
      url: baseUrl+"admin/meeting",
      data: bodyFormData,
    
      headers: { 
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*' ,
                'Access-Control-Allow-Methods':'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                'Authorization': usertoken}
    })
      .then(function (response) {
        //handle success
        if(response.status =='200'){
                       handleClose()
              swal("" ,"deleted sucessfully!", "success");
              setTimeout(() => {
      
          setOpen(false);
          }, 1000);
        } 
      })
      .catch(function (Error) {
        //handle error
        setOpen(false);
        debugger;
        if(Error.message){
          alert(Error.message);
        }    
      });
};
  return (
    <div>
     
      <Button   color="primary" onClick={handleClickOpen}>
    
      <Tooltip title="Quick-View">
      
                        <DeleteIcon className={classes.delete} />
                        
                      </Tooltip>
      </Button> 
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Do You want Delete "+topic}</DialogTitle>
        <DialogContent>
       
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
           No
          </Button> 
          <Button onClick={deletemeeting} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
