import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import EditIcon from '@material-ui/icons/Edit';
import Divider from "@material-ui/core/Divider";
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {

  const [open, setOpen] = React.useState(false);
  const [userID, setUserId] =React.useState([]);
  const [bstime, setBstime] =React.useState('');
  const [betime, setBetime] =React.useState('');
  const [starttime, setStarttime] =React.useState('');
  const [  endtime, setEndtime] =React.useState('');
  const [start_qa_time, setStart_qa_time] =React.useState('');
  const [  end_qa_time, setEnd_qa_time] =React.useState('');
  const [content, setContent] =React.useState('');
  const [topic, setTopic] =React.useState('');
  const [duration, setDuration] =React.useState('');

  var classes = useStyles();

   useEffect(() => {

    //setOpen(true);
   // setUserId(props.userid);

   }, []);


  const handleClickOpen = () => {
    setOpen(true);
  

    setUserId(props.userid);
    setBstime(props.userid.start_break_time)
    setBetime(props.userid.end_break_time)
    setStart_qa_time(props.userid.start_qa_time) 
    setEnd_qa_time(props.userid. end_qa_time) 
    setContent(props.userid.content) 
    setTopic(props.userid.topic) 
    setDuration(props.userid.duration) 
     setStarttime(moment(props.userid.start_time).format("hh:mm"))
        setEndtime(props.userid.end_time)
  };

  const handleClose = () => {
   
    setOpen(false);
  };

  return (
    <div>
      <Button   color="primary" onClick={handleClickOpen}>
      <Tooltip title="Quick-View">
                        <EditIcon className={classes.edit}/>
                      </Tooltip>
      </Button> 
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Meeting Details Edit "}</DialogTitle>
        <Divider />
        <DialogContent>
        <Grid container spacing={2}>
      
                                <Grid item md={4} sm={6} xs={12} lg={6}>
                                     <TextField
                                      variant="outlined"
                                      margin="normal"
                                      multiline
                                      placeholder="Topic"
                                      inputProps={{ maxLength: 500 }}
                                      fullWidth
                                      label="Topic"
                                     
                                      value={topic}
                                     
                                    />
                                </Grid>
                             
                                <Grid item md={8} sm={6} xs={12} lg={6}>
                                <TextField
                                  variant="outlined"
                                  margin="normal"
                                  multiline  
                                  placeholder=" duration"
                                  inputProps={{ maxLength: 50 }}
                                  fullWidth
                                  label=" duration"
                                  name="cdesignation"
                                  value={duration}
                                  
                                />
                              </Grid>
                              <Grid item md={8} sm={6} xs={12} lg={6}>
                                <TextField
                                  variant="outlined"
                                  margin="normal"
                                  multiline
                                  placeholder=" start time"
                                  inputProps={{ maxLength: 50 }}
                                  fullWidth
                                  label="start time"
                                  name="cdepartment"
                                  value={starttime}
                                 
                                />
                              </Grid>
                              <Grid item md={8} sm={6} xs={12} lg={6}>
                                <TextField
                                  variant="outlined"
                                  margin="normal"
                                  multiline  
                                  placeholder=" end time"
                                  inputProps={{ maxLength: 50 }}
                                  fullWidth
                                  label=" end time"
                                  name="cdesignation"
                                  value={endtime}
                                  
                                />
                              </Grid>
                              <Grid item md={8} sm={6} xs={12} lg={6}>
                                <TextField
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth
                                  multiline
                                  value={bstime}
                                  label="  start_break_time"
                                  placeholder="Topic"
                                  inputProps={{ maxLength: 10 }}
                                />
                              </Grid>

                              <Grid item md={8} sm={6} xs={12} lg={6}>
                                <TextField
                                  variant="outlined"
                                  margin="normal"
                                  multiline
                                  placeholder=" "
                                  inputProps={{ maxLength: 50 }}
                                  fullWidth
                                  label="end_break_time"
                                  value={betime}
                                  
                                />
                              </Grid>
                             

                              <Grid item md={8} sm={6} xs={12} lg={6}>
                                <TextField
                                  variant="outlined"
                                  margin="normal"
                                  multiline
                                  placeholder=" start_qa_time"
                                  inputProps={{ maxLength: 50 }}
                                  fullWidth
                                  label=" start_qa_time"
                                  name="cdepartment"
                                  value={start_qa_time}
                                 
                                />
                              </Grid>
                              <Grid item md={8} sm={6} xs={12} lg={6}>
                                <TextField
                                  variant="outlined"
                                  margin="normal"
                                  multiline  
                                  placeholder=" end_qa_time"
                                  inputProps={{ maxLength: 50 }}
                                  fullWidth
                                  label=" end_qa_time"
                                  name="cdesignation"
                                  value={end_qa_time}
                                  
                                />
                              </Grid>
                            

                              <Grid item md={8} sm={6} xs={12} lg={12}>
                                <TextField
                                  variant="outlined"
                                  margin="normal"
                                  multiline
                                  placeholder="content"
                                  inputProps={{ maxLength: 500 }}
                                  fullWidth
                                  label="content"
                                  name="cname"
                                 
                                  value={content}
                                 
                                />
                              </Grid>
                            </Grid>
        </DialogContent>
        <DialogActions>
           <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={handleClose} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
