import React, { useState, useEffect } from "react";
import configurl from "../../config1.json";
import axios from "axios";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";


import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide() {
  const [open, setOpen] = React.useState(false);
  const [ShowList, setShowList] = React.useState(true);
  const [ShowNew, setShowNew] = React.useState(false);
  const [ShowCUpdate, setShowCUpdate] = React.useState(false);
  const [ShowCSave, setShowCSave] = React.useState(true);
  const [mstate, setMstate] = React.useState('');
  const baseUrl = configurl.baseUrl[0];
  const [country, setcountry] = React.useState([]);
  const [cuid, setCUid] = React.useState([]);
  const [cname, setCName] = React.useState('');

  const [sState, setSState] = React.useState(1);
  const [CCountryID, setCCountryID] = React.useState('');
  const [NStateName, setNStateName] = React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
    setShowList(true);
    setShowNew(false);
  };
  const handleNewctryOpen = () => {
    setShowList(false);
    setShowNew(true);
  };
  const handleNewctryClose = () => {
    setShowList(true);
    setShowNew(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#3B5FB7',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
  });
  useEffect(() => {
    // 1 is india country code
    chooseState(1)
    getcountry()

  }, []);

  const editbutton = (row) => {
    setShowCUpdate(true);
    setShowCSave(false);

    handleNewctryOpen();
    setNStateName(row.name);
    setCUid(row.id);
    //setCCountryID(row.id);

  };
  const hidebtn = () => {
    setShowCUpdate(false);
    setShowCSave(true);
  };
  const onclickNew = () => {
    setNStateName('');
    handleNewctryOpen();
    hidebtn();
  };
  const cupdate = () => {

    var usertoken = localStorage.getItem('user');
    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;

    var bodyFormData = new FormData();
    bodyFormData.append('guard', 'state');
    bodyFormData.append('_method', 'put');
    bodyFormData.append('name', NStateName);
    bodyFormData.append('id', parseInt(cuid));
    bodyFormData.append('country_id', sState);
    axios({
      method: "post",
      url: baseUrl + "admin/csc",
      data: bodyFormData,
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }

    })
      .then(function (response) {
        //  if(response.status =='200'){
        //  alert("state update sucessfully")
        setShowNew(false);
        setShowList(true);
        setMstate(response.data.data);
        //      }

      })
      .catch(function (error) {
      });
  };

  const createState = () => {

    var usertoken = localStorage.getItem('user');
    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;
    var bodyFormData = new FormData();
    bodyFormData.append('name', NStateName);
    bodyFormData.append('country_id', CCountryID);
    bodyFormData.append('guard', 'state');

    axios({
      method: "post",
      url: baseUrl + "admin/csc",
      data: bodyFormData,
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }

    })
      .then(function (response) {
        //handle success
        if (response.status == '200') {
          setNStateName('');
          setCCountryID('');
          handleNewctryClose();
          chooseState(CCountryID);
        }

      })
      .catch(function (error) {
      });
  };

  const getcountry = () => {
    var usertoken = localStorage.getItem('user');
    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;

    axios({
      method: "get",
      url: baseUrl + "api/list/countries",
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }

    })
      .then(function (response) {
        //handle success
        if (response.status == '200') {
          setcountry(response.data.data)
        }
      })
      .catch(function (error) {
      });
  };

  const funState = (data) => {
    setCCountryID(data);
    chooseState(data);
    setSState(data);
  };

  const chooseState = (data) => {
    setSState(data);
    var usertoken = localStorage.getItem('user');
    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;

    axios({
      method: "get",
      url: baseUrl + "api/list/states?id=" + data,

      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }

    })
      .then(function (response) {

        if (response.status == '200') {
          setMstate(response.data.data);
        }
      })
      .catch(function (error) {
      });

  };
  return (

    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        State
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">

        <DialogTitle id="alert-dialog-title">
          <Grid container spacing={3}>
            <Grid item xs={7}>
              <Typography variant="h6" gutterBottom>
                State
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Button variant="contained"
                color="secondary" onClick={onclickNew}>
                New&nbsp;State
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>


          <Card >
            <CardContent>
              <Grid container alignItems="center" justify="center">
                <Grid item xs={5} md={6} sm={6} lg={12}>
                  {ShowList === true &&
                    <>

                      <FormControl style={{ minWidth: 150 }}>
                        <InputLabel id="demo-simple-select-label">Country</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          multiline
                          value={sState}
                          onClick={e => funState(e.target.value)}

                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {country.map((item) => (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          ))}

                        </Select>
                      </FormControl>


                      <DialogContentText id="alert-dialog-slide-description">

                        <TableContainer >
                          <Table aria-label="customized table">
                            <TableHead>
                              <TableRow>

                                <StyledTableCell align="right">Sl no</StyledTableCell>

                                <StyledTableCell align="right">State</StyledTableCell>
                                <StyledTableCell align="right">Action</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {mstate.length > 0 && mstate.map((row, index) => (
                                <StyledTableRow key={row.index}>
                                  <StyledTableCell component="th" scope="row">
                                    {index + 1}
                                  </StyledTableCell>

                                  <StyledTableCell align="right">{row.name}</StyledTableCell>


                                  <StyledTableCell align="right">
                                    <Button    >
                                      <EditIcon

                                        onClick={() => editbutton(row)}
                                      />
                                    </Button>
                                    {/* <Button onClick={()=>cdelete(row)}>
                <DeleteForeverIcon/>
                </Button> */}

                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>

                      </DialogContentText></>}

                  {ShowNew === true && <DialogContentText id="alert-dialog-slide-description">
                    <Grid container spacing={3}>
                      <Grid item xs={5} md={6} sm={6} lg={4}>
                        <FormControl margin="normal" required fullWidth>
                          <InputLabel id="Produc">Country</InputLabel>
                          <Select
                            fullWidth="true"
                            labelId="selProduct"
                            value={CCountryID}
                            onChange={(e) => setCCountryID(e.target.value)}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {country.map((item) => (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} md={6} sm={6} lg={6}>
                        <TextField

                          margin="normal"
                          inputProps={{ style: { borderColor: '#F75740 !important' } }}
                          multiline
                          value={NStateName}
                          fullWidth
                          label="State Name"
                          type="text"
                          onChange={(e) => setNStateName(e.target.value)}
                        />
                      </Grid>

                      <Grid item xs={12} md={12} sm={12} lg={12} style={{ textAlign: "end" }}>
                        {ShowCSave === true &&
                          <Button variant="outlined"
                            color="primary"
                            onClick={createState}
                          >
                            Save
                          </Button>
                        }
                        {ShowCUpdate === true &&
                          <Button variant="outlined"
                            color="primary"
                            onClick={cupdate}
                          >
                            Update
                          </Button>}
                      </Grid>

                    </Grid>


                  </DialogContentText>}
                </Grid>
              </Grid >
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
