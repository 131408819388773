import React, { useState, useEffect } from "react";
import Link from "@material-ui/core/Link";
import { useTheme } from "@material-ui/styles";
//import { ZoomMtg } from 'zoomus-jssdk';
import moment from "moment";
import axios from "axios";
// import Zoom from "../../../components/zoomNew";
import Button from "@material-ui/core/Button";
import {
  Grid,
  CardContent,
  Card,
  LinearProgress,
  Avatar,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  Divider,
} from "@material-ui/core";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ShopTwoIcon from "@material-ui/icons/ShopTwo";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ShareIcon from "@material-ui/icons/Share";
import DateFnsUtils from "@date-io/date-fns";
import DialogContent from "@material-ui/core/DialogContent";

// import Rupees from "rupee (1).png";
import configurl from "../../config1.json";
import ReactPlayer from "react-player";
import DateRangeIcon from "@material-ui/icons/DateRange";

import LiveTvIcon from '@material-ui/icons/LiveTv';
import queryString from 'query-string';





import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// styles
import useStyles from "./styles";
import "../zstyle.css";
// import logo from "user_new.png";

// img
// import image from "image/images.jfif";

// const mainChartData = getMainChartData();
const PieChartData = [
  { name: "Group A", value: 400, color: "primary" },
  { name: "Group B", value: 300, color: "secondary" },
  { name: "Group C", value: 300, color: "warning" },
  { name: "Group D", value: 200, color: "success" },
];

export default function AlertDialogSlide(props) {
  const baseUrl = configurl.baseUrl[0];
  const [open, setOpen] = React.useState(false);
  const [userdata, setUserdata] = React.useState([]);
  const [endtime, setEndtime] = React.useState("");
  const [time_count, setTime_count] = React.useState("");
  const [hours, setHours] = React.useState("");
  const [min, setMin] = React.useState("");
  const [seconds, setSeconds] = React.useState("");

  const [Showfollow, setShowFollow] = React.useState(true);


  // var lastsession = moment(userdata.start_time).format("LTS");

  var classes = useStyles();
  // var classes = useStyles1();

  useEffect(() => {
    //setOpen(true);
    // console.log(queryString.parse(props.location.search)) ;
    var data = queryString.parse(props.location.search)

    console.log(data.meeting_id );
 
    shareapi(data.trainer_id, data.meeting_id, data.occ_id );
// ----------------------API--------------------------------------


  }, []);

  const shareapi = (trainer_id,meeting_id,occ_id) => {
    // console.log(trainer_id, "trainer_id")
    // var usertoken = localStorage.getItem('user');
    // var test = usertoken.replace(/[#[$\]\\"]/g, '');
    // usertoken = 'Bearer ' + test;
    console.log(trainer_id);
    console.log(meeting_id);
    console.log(occ_id);
    var bodyFormData = new FormData();
    bodyFormData.append('trainer_id', trainer_id);
    bodyFormData.append('meeting_id', meeting_id);
    bodyFormData.append('occ_id', occ_id);

    axios({
      method: "get",
     // url: baseUrl + "share/meeting/student?trainer_id="trainer_id"&meeting_id="meeting_id"&occ_id="occ_id"",
     url: baseUrl + "share/meeting/student",
      params: {
        trainer_id: trainer_id,
        meeting_id: meeting_id,
        occ_id: occ_id,
      },
     // data: bodyFormData,
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        // 'Authorization': usertoken
      }
    })
      .then(function (response) {
  
        
         if (response.status == '200') {
          setUserdata(response.data.data);
          console.log("response", response.data.data)

         }
  
      })
      .catch(function (response) {
      });
  };

  // ----------------------meeting list--------------------------------------------
  const share = (trainer_id) => {
    console.log(trainer_id, "trainer_id")
    var usertoken = localStorage.getItem('user');
    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;
    var bodyFormData = new FormData();
    bodyFormData.append('trainer_id', trainer_id);
    axios({
      method: "post",
      url: baseUrl + "student/follow/trainer",
      data: bodyFormData,
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }
    })
      .then(function (response) {

        //handle success
        if (response.status == '200') {

          console.log("response", response)
        }

      })
      .catch(function (response) {
      });
  };

  return (

    <Card style={{width:"80%", margin: "130px 0px 50px 120px", height:"auto", border: "1px solid #6bbb4c"}}>
      <CardContent>
        <div className={classes.root}>



          {/* <DialogTitle id="alert-dialog-slide-title">hello</DialogTitle  > */}

          <Grid container spacing={0} Divider>
            <Grid lg={2} md={2} sm={2} xs={2}>
              <Avatar
                alt="Remy Sharp"
                src={
                  userdata.trainer
                    ? "https://acharya.heptotechnologies.org/storage/" +
                    userdata.trainer.image
                    : "/broken-image.jpg"
                }
                className={classes.large}
              />
            </Grid>
            <Grid lg={5} md={5} sm={5} xs={5} className={classes.text_color}>
               <Typography style={{ color: "#536DFE" }}> Dr. Ragu</Typography>
              <Typography style={{ color: "#536DFE" }}> Experienced trainer</Typography>
              <Typography style={{margin: "-10px 0px 0px 0px"}}>

                <LiveTvIcon style={{ fontSize: "20px", color: "#6bbb4c", margin: "0px 0px -24px 1px", }} />
                <Typography style={{ fontSize: "10px", color: "#6bbb4c", margin: "0px 0px 0px 29px" }}>120 session</Typography>

              </Typography>

            </Grid>
            <Grid lg={5} md={5} sm={5} xs={4} style={{ textAlign: "end" }}>

              {Showfollow === false && (
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.follow}
                  onClick={() => { share(userdata.trainer_id) }}
                >
                  + follow
                </Button>
              )}


              {Showfollow === true && (
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.follow}
                  onClick={() => { share(userdata.trainer_id) }}
                >
                  - unfollow
                </Button>
              )}

            </Grid>
          </Grid>

          <br />

          <DialogContent style={{ backgroundColor: "#e6e4e4" }}>
          <Grid container spacing={0}>
            <Grid style={{ fontSize: "15px", color: "#6bbb4c" }} lg={8} md={6} sm={6} xs={6} className={classes.text_color}>
              Root canel treatment Training
            </Grid>

            <Grid lg={4} md={6} sm={6} xs={6} style={{ textAlign: "end" }}>

              {userdata.enroll_student && (
                <Button
                  variant="contained"
                  component="span"
                  className={classes.entrolled}
                >
                  enrolled
                </Button>
              )}



              {userdata.enroll_student == null && (
                <Button style={{ color: "#fff", background: "#6bbb4c" }}
                  variant="contained"
                  component="span"
                  className={classes.entrolled}
                >
                  enroll
                </Button>
              )}

            </Grid>
          </Grid>
          </DialogContent> <br />

          <Grid container spacing={0}>
            <span style={{ color: "#6bbb4c" }}>Content</span>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ marginTop: "10px", textIndent: "50px" }}
            >
              content
            </Grid>
          </Grid> <br />
          <DialogContent style={{ backgroundColor: "#e6e4e4" }}>
          <Grid container spacing={0}>
            <Grid lg={6} md={6} sm={6} xs={6} className={classes.text_color}>
              <DateRangeIcon
                style={{ marginBottom: "-5px", fontSize: "20px" }}
              />{" "}
              12/12/2021
            </Grid>

            <Grid
              lg={6}
              md={6}
              sm={6}
              xs={6}
              style={{ textAlign: "end" }}
              className={classes.date}
            >
              <Typography>
                {" "}
                <DateRangeIcon
                  style={{ marginBottom: "-5px", fontSize: "20px" }}
                />{" "}
                1 to{" "}
                2
              </Typography>
            </Grid>
          </Grid>
          </DialogContent> <br />

          <Grid container spacing={0}>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ textAlign: "center" }}
            >
              <Typography>Fees:Rs 3500</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={0} className={classes.last_session}>
            <Grid lg={6} md={12} sm={12} xs={12}>
              <span>
                11:05:50
                {/* {data.start_time} */}
              </span>
            </Grid>

            <Grid lg={6} md={12} sm={12} xs={12} style={{ textAlign: "end" }}>
              <Button style={{ color: "#fff", background: "#6bbb4c" }}
                variant="contained"
                component="span"
                className={classes.entrolled}
              >
                Start the session
              </Button>
            </Grid>
          </Grid>


          {/* <Button onClick={handleClose} color="primary">
            Disagree
          </Button> */}


        </div>
      </CardContent>
    </Card>
  );
}




// #######################################################################
