import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@material-ui/icons/Edit';
import { Grid } from "@material-ui/core";
import OtpInput from 'react-otp-input';
import axios from "axios";
import configurl from "../../config1.json";
import swal from 'sweetalert';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  otpfieldstyle: {
    width: '5rem',
    height: '3rem',
    fontSize: '2rem',
    borderRadius: '4px',
    border: "1px solid rgba(0,0,0,0.3)",

  }

}));

export default function FormDialog(props) {

  const classes = useStyles();
  const baseUrl = configurl.baseUrl[0];
  const [open, setOpen] = React.useState(false);
  const [editopen, setEditpen] = React.useState(true);
  const [email, setEmail] = useState("");
  var [otp, setOTP] = useState("");
  const [method, setMethod] = useState("");
  const [Erremail, setErremail] = useState("");
  const [Errotp, setErrotp] = useState(true);

  //   useEffect(() => {

  //   })


  const handleClickOpen = () => {
    setEmail(props.id)
    setErremail("");
    setEditpen(true)
    setOpen(true);
  };

  const handleClose = () => {



    setOpen(false);
  };
  const handlenext = () => {
    if (email) {
      var pattern = /^\d{10}$/;
      if (!pattern.test(email)) {
        //  alert("123");
        setErremail("invalid phone number");
        setEditpen(true)
      }
      else {
        setEditpen(false)
      }
    }
    // var mailformat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    // if(email.match(mailformat)){
    //    
    //   }



  }
  const OTPhandleChange = (otp) => {
    setOTP(otp);
  };

  const eemailbtn = () => {
    if (method == "student") {
      var urldata = "student/auth";
    }
    if (method == "trainer") {
      var urldata = "trainer/auth";
    }
    if (method == "admin") {
      var urldata = "admin/auth";
    }


    var bodyFormData = new FormData();

    bodyFormData.append('mobile', email);
    bodyFormData.append('mcc_id', "2");
    bodyFormData.append('otp', otp);


    var usertoken = localStorage.getItem('user');
    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;

    axios({
      method: "post",
      url: baseUrl + urldata,
      data: bodyFormData,
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }
    })
      .then(function (response) {
        if (response.status == '200') {
          handleClose()

          swal("", "phone number updated sucessfully!", "success");
          setEditpen(true)

        }
      })
      .catch(function (error) {
        if (error.status == '422') {
          swal("", "same phone number!", "success");
        }
      });


  }
  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        <EditIcon></EditIcon>
      </Button>
      <Grid container>
        <Grid item xs={12} md={12} lg={12} sm={12} lg={12}>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            {editopen === true && (<DialogTitle id="form-dialog-title">Enter phone Here</DialogTitle>)}
            {editopen === false && (<DialogTitle id="form-dialog-title">Enter otp  Here</DialogTitle>)}
            <DialogContent>
              {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText> */}
              {editopen === true && (<TextField
                style={{ width: "400px", outline: "none !important" }}
                autoFocus
                margin="dense"
                id="name"
                label="Phone Number"
                // type="number"
                fullWidth
                helperText={Erremail}
                error={Erremail}
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              )}

              {editopen === false && (
                <OtpInput
                  value={otp}
                  onChange={OTPhandleChange}
                  onClick={handleClose}
                  numInputs={6}
                  isInputNum={true}
                  shouldAutoFocus
                  separator={<span>-</span>}

                  inputStyle={classes.otpfieldstyle}
                />)}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              {editopen === true && (<Button disabled={email ? false : true} onClick={handlenext} color="primary">
                Next
              </Button>)}
              {editopen === false && (<Button disabled={otp.length == 6 ? false : true} onClose={handleClose} onClick={eemailbtn} color="primary">
                Save
              </Button>)}

            </DialogActions>

          </Dialog>
        </Grid>
      </Grid>
    </div>
  );
}