import React, { useState, useEffect } from "react";
import configurl from "../../config1.json";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import EditIcon from '@material-ui/icons/Edit';
import Divider from "@material-ui/core/Divider";
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {

  const [open, setOpen] = React.useState(false);
  const [userID, setUserId] = React.useState([]);
  const [userName, setuserName] = React.useState('');
  const [userMobile, setuserMobile] = React.useState('');
  const [title, setTitle] = React.useState('');
  const baseUrl = configurl.baseUrl[0];
  var classes = useStyles();

  useEffect(() => {

    //setOpen(true);
    // setUserId(props.userid);

  }, []);

  const handleClickOpen = () => {
    setOpen(true);
    setUserId(props.userid);
    setuserName(props.userid.name);
    setuserMobile(props.userid.mobile);
    setTitle(props.userid.title);

  };

  const handleClose = () => {

    setOpen(false);
  };
  const update = () => {



    var bodyFormData = new FormData();

    bodyFormData.append('_method', 'put');
    bodyFormData.append('id', parseInt(userID.id));
    bodyFormData.append('name', userName);
    bodyFormData.append('title', title);
    bodyFormData.append('guard', 'trainer');
    var usertoken = localStorage.getItem('user');
    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;

    axios({
      method: "POST",
      url: baseUrl + "admin/update",
      data: bodyFormData,
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }
    })
      .then(function (response) {
        if (response.status == '200') {
          // setuserName(name)

          handleClose()
        }
      })
      .catch(function (error) {

      });
  };

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        <Tooltip title="Quick-View">
          <EditIcon className={classes.edit} />
        </Tooltip>
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Trainer Edit Profile"}</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2}>

            <Grid item md={4} sm={6} xs={12} lg={3}>
              <TextField
                variant="outlined"
                margin="normal"
                multiline
                placeholder="Title"
                inputProps={{ maxLength: 500 }}
                fullWidth
                label="Title"

                onChange={e => setTitle(e.target.value)}
                value={title}


              />
            </Grid>

            <Grid item md={8} sm={6} xs={12} lg={9}>
              <TextField
                variant="outlined"
                margin="normal"
                multiline
                placeholder="Name"
                inputProps={{ maxLength: 500 }}
                fullWidth
                label="Name"
                name="cname"
                onChange={e => setuserName(e.target.value)}
                value={userName}

              />
            </Grid>

            <Grid item md={8} sm={6} xs={12} lg={6}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                multiline
                value={userMobile}
                label="Mobile Number"
                inputProps={{ maxLength: 10 }}
              />
            </Grid>

            <Grid item md={8} sm={6} xs={12} lg={6}>
              <TextField
                variant="outlined"
                margin="normal"
                multiline
                placeholder="Email"
                inputProps={{ maxLength: 50 }}
                fullWidth
                label="Email"

              />
            </Grid>

            <Grid item md={8} sm={6} xs={12} lg={6}>
              <TextField
                variant="outlined"
                margin="normal"
                multiline
                placeholder="Department"
                inputProps={{ maxLength: 50 }}
                fullWidth
                label="Department"
                name="cdepartment"

              />
            </Grid>
            <Grid item md={8} sm={6} xs={12} lg={6}>
              <TextField
                variant="outlined"
                margin="normal"
                multiline
                placeholder="Designation"
                inputProps={{ maxLength: 50 }}
                fullWidth
                label="Designation"
                name="cdesignation"

              />
            </Grid>


          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={update} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
