import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {

  const [open, setOpen] = React.useState(false);
  const [userID, setUserId] = React.useState([]);
  var classes = useStyles();
  useEffect(() => {

    //setOpen(true);
    // setUserId(props.userid);

  }, []);

  const handleClickOpen = () => {
    setOpen(true);
    setUserId(props.userid);

  };

  const handleClose = () => {

    setOpen(false);
  };

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        <Tooltip title="Quick-View">
          <VisibilityIcon className={classes.view} />
        </Tooltip>
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle style={{ backgroundColor: "#e6e4e4" }} id="alert-dialog-slide-title">{"Meeting Details"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>

            <Grid item md={8} sm={6} xs={12} lg={5}>
              <Typography
                className={classes.Custlablecolor}
                color="textSecondary"
              >
                topic
              </Typography>
              <Typography variant="body1">{userID.topic}</Typography>
            </Grid>
            <Grid item md={8} sm={6} xs={12} lg={5}>
              <Typography
                className={classes.Custlablecolor}
                color="textSecondary"
              >
                duration
              </Typography>
              <Typography variant="body1">{userID.duration}</Typography>
            </Grid>


            <DialogContent>
              <Grid style={{ backgroundColor: "#e6e4e4" }} item md={8} sm={6} xs={12} lg={10}>
                <span style={{ color: "#6bbb4c" }}>Content</span>
                <Grid
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "10px", textIndent: "50px" }}
                >
                  <Typography variant="body1"> {userID.content}</Typography>
                </Grid>
              </Grid>
            </DialogContent>



            <Grid style={{ backgroundColor: "#e6e4e4" }} item md={8} sm={6} xs={12} lg={10}>
              <Typography
                className={classes.Custlablecolor}
                color="textSecondary" >
                content
              </Typography>
              <Typography variant="body1"> {userID.content}</Typography>
            </Grid>

            <Grid item md={8} sm={6} xs={12} lg={5}>
              <Typography
                className={classes.Custlablecolor}
                color="textSecondary"
              >
                start_break_time
              </Typography>
              <Typography variant="body1">{userID.start_break_time}</Typography>
            </Grid>
            <Grid item md={8} sm={6} xs={12} lg={5}>
              <Typography
                className={classes.Custlablecolor}
                color="textSecondary"
              >
                end_break_time
              </Typography>
              <Typography variant="body1">{userID.end_break_time}</Typography>
            </Grid>
            <Grid item md={8} sm={6} xs={12} lg={5}>
              <Typography
                className={classes.Custlablecolor}
                color="textSecondary"
              >
                start_qa_time
              </Typography>
              <Typography variant="body1">{userID.start_qa_time}</Typography>
            </Grid>
            <Grid item md={8} sm={6} xs={12} lg={5}>
              <Typography
                className={classes.Custlablecolor}
                color="textSecondary"
              >
                end_qa_time
              </Typography>
              <Typography variant="body1">{userID.end_qa_time}</Typography>
            </Grid>
          </Grid>
        </DialogContent> 

        <DialogActions>
         
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions> */}

<DialogTitle id="alert-dialog-slide-title">{"Meeting Details"}</DialogTitle>
        <div dividers>
          <DialogContent style={{ backgroundColor: "rgb(241 237 237)" }}>
            <Grid container spacing={2}>

              <Grid item md={8} sm={6} xs={12} lg={7} >
                <Typography
                  className={classes.Custlablecolor}
                  color="textSecondary"
                >
                  topic
                </Typography>
                <Typography variant="body1">{userID.topic}</Typography>
              </Grid>

              <Grid item md={8} sm={6} xs={12} lg={5}>

                <Grid item md={8} sm={6} xs={12} lg={12}>
                  <Typography
                    className={classes.Custlablecolor}
                    color="textSecondary"
                  >
                    duration
                  </Typography>
                  <Typography variant="body1">{userID.duration}</Typography>
                </Grid>
              </Grid>
              {/* ???? */}


            </Grid>


          </DialogContent>
          <DialogContent style={{ overflow: "hidden" }}>
            <Grid item md={8} sm={6} xs={12} lg={12}>
              <Grid container spacing={1}>
                <Grid item md={8} sm={6} xs={12} lg={9}>
                  <Typography variant="body1"> Content {userID.content}</Typography>

                </Grid>

                <Grid item md={8} sm={6} xs={12} lg={3} style={{ textAlign: 'end' }}>
                  <Typography
                    className={classes.Custlablecolor}
                    color="textSecondary"
                  >
                    start_break_time
                  </Typography>
                  <Typography variant="body1">{userID.start_break_time}</Typography>

                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} sm={6} xs={12} lg={12}>
              <Typography
                className={classes.Custlablecolor}
                color="textSecondary"
              >
                end_break_time
              </Typography>
              <Typography variant="body1">{userID.end_break_time}</Typography>
            </Grid>
            <Grid item md={12} sm={6} xs={12} lg={12}>
              <Grid container spacing={1}>
                <Grid item md={6} sm={6} xs={12} lg={8}>
                  <Typography
                    className={classes.Custlablecolor}
                    color="textSecondary"
                  >
                    start_qa_time
                  </Typography>
                  <Typography variant="body1">{userID.start_qa_time}</Typography>
                </Grid>
                <Grid item md={6} sm={6} xs={12} lg={4} style={{ textAlign: 'end' }}>
                  <Typography
                    className={classes.Custlablecolor}
                    color="textSecondary"
                  >
                    end_qa_time
                  </Typography>
                  <Typography variant="body1">{userID.end_qa_time}</Typography>

                </Grid>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions style={{ backgroundColor: "#e6e4e4" }}>
            {/* <Button onClick={handleClose} color="primary">
            Disagree
          </Button> */}
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </div>

      </Dialog>
    </div>
  );
}
