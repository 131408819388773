import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import {Box, IconButton, Link} from '@material-ui/core'
import Icon from '@mdi/react'

//icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
} from '@mdi/js'

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Tables from "../../pages/tables";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";

import ADashboard from "../../pages/admin/dashboard";
import AStudentList from "../../pages/admin/studentList/studentList";
import ATrainerList from "../../pages/admin/trainerList";
import AProfile from "../../pages/admin/profile";
import AMeeting from "../../pages/admin/meetingList";
import ASetting from "../../pages/admin/setting";

import Error from "../../pages/error";
import SDashboard from "../../pages/student/dashboard";
import SProfile from "../../pages/student/profile";
import SMeetingList from "../../pages/student/meetinglist";
import SGallery from "../../pages/student/gallery";


import TDashboard from "../../pages/trainer/dashboard";
import TShedulemeeting from "../../pages/trainer/shedulemeeting";
import TProfile from "../../pages/trainer/profile";
import TGallery from "../../pages/trainer/gallery";

// context
import { useLayoutState } from "../../context/LayoutContext";
import { Grid } from "@material-ui/core";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
         
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
             
              <Route exact path="/app/admin/dashboard" component={ADashboard} />
              <Route exact path="/app/admin/StudentList" component={AStudentList} />
              <Route exact path="/app/admin/TrainerList" component={ATrainerList} />
              <Route exact path="/app/admin/Profile" component={AProfile} />
              <Route exact path="/app/admin/MeetingList" component={AMeeting} />
              <Route exact path="/app/admin/Settings" component={ASetting} />
              
              <Route exact path="/app/student/dashboard" component={SDashboard} />
              <Route exact path="/app/student/Profile" component={SProfile} />
              <Route exact path="/app/student/MeetingList" component={SMeetingList} />
              <Route exact path="/app/student/Gallery" component={SGallery} />
              
              

              <Route exact path="/app/trainer/dashboard" component={TDashboard} />
              <Route exact path="/app/trainer/Shedulemeeting" component={TShedulemeeting} />
              <Route exact path="/app/trainer/Gallery" component={TGallery} />
              <Route exact path="/app/trainer/Profile" component={TProfile} />
              <Route component={Error} />
              
             
            </Switch>
            <Box
              mt={5}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent="space-between"
            >
              {/* <div>
                <Link
                  color={'primary'}
                  href={'#'}
                  target={'_blank'}
                  className={classes.link}
                >
                  Flatlogic
                </Link>
                <Link
                  color={'primary'}
                  href={'#'}
                  target={'_blank'}
                  className={classes.link}
                >
                  About Us
                </Link>
                <Link
                  color={'primary'}
                  href={'#'}
                  target={'_blank'}
                  className={classes.link}
                >
                  Blog
                </Link>
              </div> */}
              <div>
                {/* <Link
                  href={'https://www.facebook.com/flatlogic'}
                  target={'_blank'}
                >
                  <IconButton aria-label="facebook">
                    <Icon
                      path={FacebookIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
                <Link
                  href={'https://twitter.com/flatlogic'}
                  target={'_blank'}
                >
                  <IconButton aria-label="twitter">
                    <Icon
                      path={TwitterIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
                <Link
                  href={'https://github.com/flatlogic'}
                  target={'_blank'}
                >
                  <IconButton
                    aria-label="github"
                    style={{marginRight: -12}}
                  >
                    <Icon
                      path={GithubIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link> */}
              </div>
            </Box>
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
