import React, { useState, useEffect } from "react";
import { ZoomMtg } from '@zoomus/websdk';
import { Zoom } from "@material-ui/core";
import { ZoomOutMapTwoTone } from "@material-ui/icons";
import axios from "axios";
import configurl from "../config1.json";
import { isMobile, deviceDetect } from "react-device-detect";
var base_url = window.location.origin;

var apiKey = 'dGR3YICpR4-zjcy6w0msvw';
var apiSecret = 'rDH6hydlc4U5vONxzLQPtrz0bv7ypg7u1pBJ';
var meetingNumber = '83392115317';
var role = 0;
var leaveUrl = base_url + '/';
var userName = 'WebSdk';
var userEmail = '';
var passWord = '046772';


const ZoomStudent = (props) => {


  const [meetingNum, setMeetingNUm] = useState('');
  const [meetingPWS, setMeetingPWS] = useState('');
  const baseUrl = configurl.baseUrl[0];
  useEffect(() => {


    showZoomDiv();
    //ZoomMtg.setZoomJSLib('node_modules/zoomus/websdk/dist/lib', '/av');
    //ZoomMtg.setZoomJSLib("https://jssdk.zoomus.cn/1.9.1/lib", "/av"); true
    ZoomMtg.setZoomJSLib("https://source.zoom.us/1.9.0/lib", "/av");
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareJssdk();
    //console.log('111',props.MeetingDatas['meeting_id']);
    ZoomMtg.generateSignature({
      meetingNumber: String(props.MeetingDatas['zoom_meeting_id']),
      apiKey: apiKey,
      apiSecret: apiSecret,
      role: role,
      success: function (res) {
        setTimeout(() => {
          initiateMeeting(res.result, props.MeetingDatas);
        }, 1000);
      },
    });

  }, []);
  const showZoomDiv = () => {
    document.getElementById('zmmtg-root').style.display = 'block';
    document.getElementById('zmmtg-root').style.width = '100%';
    document.getElementById('zooHeader').style.display = 'none';
    if (!isMobile) {
      document.getElementById('zooSidebar').style.display = 'none';
    }
  };

  const initiateMeeting = (signature, meetingdata) => {
    debugger;
    ZoomMtg.init({
      leaveUrl: leaveUrl,
      isSupportAV: true,
      success: (success) => {
        ZoomMtg.join({
          signature: signature,
          meetingNumber: String(meetingdata.zoom_meeting_id),
          userName: window.localStorage.getItem('Uname'),
          apiKey: apiKey,
          userEmail: userEmail,
          //userEmail: String(meetingdata.schedule_for),
          passWord: String(meetingdata.hash_password),
          success: (success) => {
          },
          error: (error) => {
          }
        })
      },
      error: (error) => {

      }
    })
  };

  return <div className="App">Zoom Testing</div>;
};

export default ZoomStudent;
