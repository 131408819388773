import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from "@material-ui/core/Typography";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import configurl from "../../config1.json";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {

  const [open, setOpen] = React.useState(false);
  const [userID, setUserId] = React.useState([]);
  const [id, setId] = React.useState([]);
  const baseUrl = configurl.baseUrl[0];
  var classes = useStyles();

  useEffect(() => {

    //setOpen(true);
    // setUserId(props.userid);

  }, []);

  const handleClickOpen = () => {
    setOpen(true);
    setUserId(props.userid);
    setId(props.userid.id);
  };

  const handleClose = () => {

    setOpen(false);
  };

  const Tdelete = (e) => {
    var bodyFormData = new FormData();

    // alert(id);
    bodyFormData.append('id', id);
    bodyFormData.append('guard', 'trainer');
    bodyFormData.append('_method', 'delete');
    var usertoken = localStorage.getItem('user');
    var test = usertoken.replace(/[#[$\]\\"]/g, '');
    usertoken = 'Bearer ' + test;
    axios({
      method: "post",
      url: baseUrl + "admin/delete",
      data: bodyFormData,
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Authorization': usertoken
      }
    })
      .then(function (response) {
        if (response.status == '200') {

          //  alert('sucessfully!');
          handleClose()
        }
      })
      .catch(function (error) {
      });
  };



  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        <Tooltip title="Quick-View">
          <DeleteIcon className={classes.delete} />
        </Tooltip>
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Do You want Delete " + userID.name + " Profile?"}</DialogTitle>
        <DialogContent>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={Tdelete} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
